import { cloneElement, useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

import Navbar from "../../component/Navbar";
import Sidebar from "../../component/Sidebar";
import useQuery from "../../hooks/useQuery";
import { Mixpanel } from "../../utils/mixpanel";
import { DashboardWrapper, Container, MainWrapper } from "./styles";

const DashboardLayout = (props: any) => {
  const query = useQuery();
  const [isChatPaneVisible, setIsChatPaneVisible] = useState(false);
  const [isEncounterFormVisible, setIsEncounterFormVisible] = useState(false);
  const consultId = query.get("consultId");

  useEffect(() => {
    setIsChatPaneVisible(consultId ? true : false);
    setIsEncounterFormVisible(consultId ? true : false);
  }, [consultId]);

  useEffect(() => {
    const profileDetails = JSON.parse(
      localStorage.getItem("pneumaCurrentUser")!
    );

    if (profileDetails) {
      Mixpanel.identify(profileDetails.id);
      Mixpanel.people.set({
        $name: `${profileDetails.first_name} ${profileDetails.last_name}`
      });
    }
  }, []);

  return (
    <DashboardWrapper>
      <div>
        <Navbar />
      </div>
      <Container>
        <Sidebar />
        <MainWrapper>
          {cloneElement(props.children, {
            isChatPaneVisible,
            setIsChatPaneVisible,
            isEncounterFormVisible,
            setIsEncounterFormVisible
          })}
        </MainWrapper>
      </Container>
    </DashboardWrapper>
  );
};

const DashboardLayoutRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  const ACCESS_TOKEN = localStorage.getItem("accessToken");
  return ACCESS_TOKEN ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <Component {...matchProps} />
        </DashboardLayout>
      )}
    />
  ) : (
    <Redirect to='/' />
  );
};

export default DashboardLayoutRoute;
