import { Col } from "antd";
import AppIcon from "../../../../assets/images/icon.svg";
import EmailSentIcon from "../../../../assets/icons/email-sent-icon.svg";
import { Link } from "react-router-dom";

const EmailLinkSent = (props: any) => {
    return (
        <>
            <Col span={24} className="align-center">
                <div className="right-pane email-links">
                    <img className="app-logo" src={AppIcon} alt="logo" />
                    <i 
                        className="close-icon lni lni-close" 
                        onClick={() => props.setIsRegistrationSuccessful(false)}
                    />
                    <div className="align-center">
                        <img src={EmailSentIcon} alt="email-sent" />
                        <h3>A link has been sent to your mail</h3>
                        <p>Check your email for a link to reset your password.</p>
                    </div>
                    <div className="bottom-text">
                        <span>If you did not get any mail</span> 
                        <Link to="/"><span>Resend confirmation mail</span></Link>
                    </div>
                </div>
            </Col>
        </>
    )
}

export default EmailLinkSent;