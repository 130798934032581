import { Col, Row } from "antd";

import { QuestionsWrapper, ContentWrapper } from "./styles";
import QuestionsTable from "./containers/QuestionsTable";

const Questions = () => {
    return (
        <QuestionsWrapper>
            <ContentWrapper>
                <Row>
                    <Col span={24}>
                        <QuestionsTable />
                    </Col>
                </Row>
            </ContentWrapper>
        </QuestionsWrapper>
    )
}

export default Questions;