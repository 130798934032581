import { Modal, Form, Row, Col } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Dragger from "antd/lib/upload/Dragger";
import DatePicker from "../../../../component/DatePicker";
import Input from "../../../../component/Input";
import { LicenseModalWrapper, UploadWrapper } from "./styles";

import UploadImg from "../../../../assets/icons/upload.svg";
import Button from "../../../../component/Button";
import { useEffect, useState } from "react";
import moment from "moment";

const LicenseModal = (props: any) => {
  const [licenseForm] = Form.useForm();
  const {
    visible,
    handleCancel,
    currentLicenseData,
    isLicenseInfoUpdateLoading,
    handleSubmitLicenseDetails,
  } = props;

  const [isExpire, setIsExpire] = useState<boolean>(false);

  const handleExpireOrNot = (e: any) => {
    setIsExpire(e.target.checked);
  };

  useEffect(() => {
    if (currentLicenseData) {
      const { id, name, organization, expires, expires_at, date_issued } = currentLicenseData;
      licenseForm.setFieldsValue({
        id,
        name,
        organization,
        expires: expires ? true : false,
        expires_at: expires_at ? moment(expires_at, "YYYY/MM") : null,
        date_issued: date_issued ? moment(date_issued, "YYYY/MM") : null,
      });
      setIsExpire(expires ? true : false);
    }
  }, [currentLicenseData, licenseForm]);

  useEffect(() => {
    if (!currentLicenseData) {
      licenseForm.resetFields();
    }
  }, [currentLicenseData, licenseForm]);

  return (
    <Modal
      title={`${currentLicenseData ? 'Update' : 'Add'} Licenses/Certifications`}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <LicenseModalWrapper>
        <Form
          name="licenseForm"
          layout="vertical"
          form={licenseForm}
          onFinish={async (values) => {
            await handleSubmitLicenseDetails(values);
            licenseForm.resetFields();
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Input
                formItem={{
                  label: "Name of certificate or licence",
                  name: "name",
                  rules: [
                    {
                      required: true,
                      message:
                        "Please input your Name of certificate or licence",
                    },
                  ],
                }}
                type="text"
                required
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Input
                formItem={{
                  label: "Issuing oganization",
                  name: "organization",
                  rules: [
                    {
                      required: true,
                      message: "Please input your Issuing oganization",
                    },
                  ],
                }}
                type="text"
                required
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <DatePicker
                formItem={{
                  label: "Date issued",
                  name: "date_issued",
                  rules: [
                    {
                      required: true,
                      message: "Please input the date issued",
                    },
                  ],
                }}
                placeholder=""
                picker="month"
                required
              />
            </Col>
            {isExpire && (
              <Col span={12}>
                <DatePicker
                  formItem={{
                    label: "Expiration Date",
                    name: "expires_at",
                    rules: [
                      {
                        required: true,
                        message: "Please input the expiration date",
                      },
                    ],
                  }}
                  placeholder=""
                  picker="month"
                  required
                />
              </Col>
            )}
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="expires" initialValue={isExpire} required>
                <Checkbox onChange={handleExpireOrNot}>
                  This credential expires
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <UploadWrapper>
                <Dragger {...props}>
                  <img src={UploadImg} alt="upload-icon" />
                  <p className="ant-upload-hint">
                    Drag and drop or browse your files to select
                  </p>
                </Dragger>
              </UploadWrapper>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "20px",
              textAlign: "right",
              paddingTop: "20px",
              borderTop: "1px solid #f2f2f2",
            }}
          >
            <Col span={24}>
              <Button htmlType="submit" key="submit" type="primary">
                {isLicenseInfoUpdateLoading ? "Saving" : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </LicenseModalWrapper>
    </Modal>
  );
};

export default LicenseModal;
