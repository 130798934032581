import styled from 'styled-components';

const NotificationSideBarWrapper = styled.aside`
    height: calc(100vh - 57px);
    margin-top: 57px;
    width: 46px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: left;
    z-index: 0;
    position: fixed;
    right: 0;
    top: 0;
    box-shadow: -1px 0px 5px #0078E240;

`;

const NotificationSideBarMenuList = styled.div`
    list-style-type: none;
    margin-top: 30px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const NotificationSideBarMenuListItem = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    text-align: left;
    color: #0078e2;
    margin: 25px 0px;
    a {
        text-decoration: none;
    }
    img {
        /* margin-right: 15px; */
    }
`;

export { NotificationSideBarWrapper, NotificationSideBarMenuList, NotificationSideBarMenuListItem }