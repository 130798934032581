import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dropdown, Menu, Grid, Space } from "antd";

import { logOutProvider } from "../../redux/reducers/auth";
import AvatarImg from "../Avatar";
import useSidebarState from "hooks/useSidebarState";
import Button from "common/Button";
import {
  NavbarWrapper,
  MenuLink,
  NavMenuList,
  NavMenuListItem
} from "./styles";

// svg
import AppIcon from "../../assets/images/icon.svg";
import { ReactComponent as NavbarMenuSvg } from "assets/navbar-menu.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as EncounterIcon } from "../../assets/icons/encounters.svg";
import { ReactComponent as CareBlogIcon } from "../../assets/icons/care-blog.svg";

const Navbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isActivePath = (path: string) => location.pathname.includes(path);

  const { toggleSidebar } = useSidebarState();
  const breakpoints = Grid.useBreakpoint();

  const profile = JSON.parse(localStorage.getItem("pneumaCurrentUser") || "");
  const { first_name, last_name } = profile || {};

  const handleLogout = () => {
    dispatch(logOutProvider());
  };

  const menu = (
    <Menu>
      <Menu.Item key='0'>
        <Link to='/profile'>My Profile</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='1' onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const navbarMenu = [
    {
      title: "Dashboard",
      route: "/dashboard",
      icon: (
        <DashboardIcon
          fill={isActivePath("/dashboard") ? "#0078e2" : "#002e46"}
        />
      )
    },
    {
      title: "Encounters",
      route: "/encounters",
      icon: (
        <EncounterIcon
          fill={isActivePath("/encounters") ? "#0078e2" : "#002e46"}
        />
      )
    },
    {
      title: "Questions",
      route: "/questions",
      icon: (
        <CareBlogIcon
          fill={isActivePath("/questions") ? "#0078e2" : "#002e46"}
        />
      )
    }
  ];

  return (
    <NavbarWrapper>
      <Space>
        {!breakpoints.lg && (
          <Button
            type='link'
            onClick={toggleSidebar}
            style={{ padding: "4px 8px" }}
          >
            <NavbarMenuSvg width={24} height={24} fill='currentColor' />
          </Button>
        )}
        <img src={AppIcon} alt='icon' />

        <NavMenuList>
          {navbarMenu.map((item) => {
            const { icon, route, title } = item;
            const isActive = isActivePath(route);

            return (
              <NavMenuListItem active={isActive}>
                <Link to={route}>
                  {icon}
                  {title}
                </Link>
              </NavMenuListItem>
            );
          })}
        </NavMenuList>
      </Space>

      <Dropdown overlay={menu} trigger={["click"]}>
        <MenuLink>
          <span style={{ marginRight: 10 }}>
            {first_name} {last_name}
          </span>
          <AvatarImg img='' fallbackText={`${first_name} ${last_name}`} />
        </MenuLink>
      </Dropdown>
    </NavbarWrapper>
  );
};

export default Navbar;
