import styled from "styled-components";

const DashboardWrapper = styled.div``;

const Container = styled.div`
  display: flex;
  z-index: 0;
`;

const MainWrapper = styled.section`
  margin-top: 80px;
  width: 100%;
`;

export { DashboardWrapper, Container, MainWrapper };
