import styled from 'styled-components';

interface CardProps {
    marginBottom: number,
    padding: number
}

const CardWrapper = styled.div<CardProps>`
    .ant-card {
        width: 100%;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 120, 226, 0.1);
        ${(props) => `margin-bottom: ${props.marginBottom}px`};
        .ant-card-body {
            ${(props) => `padding: ${props.padding}px`};
        }
    }
`;

export { CardWrapper }