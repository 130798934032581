import { Col, Empty, Grid, Pagination, Row, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// @ts-ignore
import Engage from "@engage_so/js";

import AvatarImg from "../../../../component/Avatar";
import Card from "../../../../component/Card";
import {
  acceptConsult,
  consultSelector,
  fetchAllConsults,
  rejectConsult
} from "../../../../redux/reducers/consults";
import getAge from "../../../../utils/utils";
import { PaginationWrapper } from "../../../Questions/containers/QuestionsTable/styles";
import {
  ConsultTableBody,
  ConsultTableWrapper,
  TableBody,
  TableHeader,
  TableRowItemWrapper
} from "./styles";
import { Mixpanel } from "utils/mixpanel";
import { isProd } from "../../../../utils/utils";

const ConsultTable = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const DEFAULT_PAGE_SIZE = 10;
  const [selectedConsultForAcceptance, setSelectedConsultForAcceptance] =
    useState(null);
  const [consultsData, setConsultsData] = useState<any[]>([]);
  const [
    selectedConsultPatientForAcceptance,
    setSelectedConsultPatientForAcceptance
  ] = useState(null);
  const [selectedConsultForRejection, setSelectedConsultForRejection] =
    useState(null);
  const {
    consults,
    isConsultsLoading,
    acceptConsultLoading,
    hasAcceptedConsult
  } = useSelector(consultSelector);

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    setConsultsData(consults);
  }, [consults]);

  useEffect(() => {
    const query = "?status=pending";
    dispatch(fetchAllConsults(query));
  }, [dispatch]);

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleAcceptConsult = async (
    id: any,
    patientId: any,
    created_at: Date,
    natureOfEncounter: string
  ) => {
    setSelectedConsultForAcceptance(id);
    setSelectedConsultPatientForAcceptance(patientId);
    await dispatch(acceptConsult(id));

    Mixpanel.track("accept_consult", {
      createdAt: `${moment(created_at).format("LL")} - ${moment(
        created_at
      ).format("hh:mm a")}`
    });

    // engage event tracking
    if (isProd) {
      Engage.track(patientId, {
        event: "encounter_accepted",
        properties: {
          formName: natureOfEncounter
        }
      });
    }
  };

  const handleRejectConsult = async (id: any, patientId: any) => {
    setSelectedConsultForRejection(id);
    setSelectedConsultPatientForAcceptance(patientId);
    await dispatch(rejectConsult(id));
    setSelectedConsultForRejection(null);
  };

  useEffect(() => {
    const patientId = selectedConsultPatientForAcceptance;
    const consultId = selectedConsultForAcceptance;
    if (hasAcceptedConsult) {
      history.push(`/encounters/${patientId}?consultId=${consultId}`);
      setSelectedConsultForAcceptance(null);
    }
  }, [hasAcceptedConsult]);

  const startPageCount = (pageNumber - 1) * DEFAULT_PAGE_SIZE;
  return (
    <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
      <ConsultTableWrapper>
        <ConsultTableBody>
          <TableHeader>
            <Row gutter={[16, 16]}>
              <Col md={6}>
                <p>Patient name</p>
              </Col>
              <Col md={8}>
                <p>Nature of encounter</p>
              </Col>
              <Col md={6}>
                <p>Partner</p>
              </Col>
              <Col md={4}>
                <p>Schedule</p>
              </Col>
            </Row>
          </TableHeader>
          <TableBody>
            {isConsultsLoading ? (
              <Row align='middle' justify='center' style={{ height: "300px" }}>
                <Spin size='default' />
              </Row>
            ) : consultsData?.length > 0 ? (
              consultsData
                .slice(startPageCount, startPageCount + DEFAULT_PAGE_SIZE)
                .map(
                  ({
                    id,
                    patient_record,
                    partner,
                    nature = "Virtual",
                    created_at,
                    form
                  }) => {
                    const {
                      id: patientId,
                      first_name,
                      last_name,
                      gender,
                      dob
                    } = patient_record || {};
                    const { business_name } = partner || {};

                    const maxTextLength = breakpoints.md ? 50 : 50;

                    const natureOfEncounter = form?.name as string;

                    const businessName = (business_name || "-") as string;

                    return (
                      <TableRowItemWrapper>
                        <Row gutter={[16, 16]}>
                          <Col xs={24} md={6}>
                            <div className='flex'>
                              <AvatarImg
                                img=''
                                style={{ marginRight: 10 }}
                                fallbackText={`${first_name} ${last_name}`}
                              />
                              <div>
                                <p>
                                  {first_name} {last_name}
                                </p>
                                <span className='text-capitalize'>
                                  {gender}, {getAge(dob)}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={8}>
                            {/* <span className="tag">{nature}</span> */}
                            {natureOfEncounter && (
                              <span className='tags'>
                                {natureOfEncounter.slice(0, maxTextLength)}
                                {natureOfEncounter.length > maxTextLength
                                  ? "..."
                                  : ""}
                              </span>
                            )}
                          </Col>
                          <Col xs={12} md={6}>
                            <p>
                              {businessName.slice(0, maxTextLength)}
                              {businessName.length > maxTextLength ? "..." : ""}
                            </p>
                          </Col>
                          <Col xs={24} md={4}>
                            <>
                              <p>{moment(created_at).format("LL")}</p>
                              <span>
                                {moment(created_at).format("hh:mm a")}
                              </span>
                            </>
                          </Col>
                        </Row>
                        <Row className='actions'>
                          <Col
                            span={12}
                            className='accept'
                            onClick={() =>
                              handleAcceptConsult(
                                id,
                                patientId,
                                created_at,
                                natureOfEncounter
                              )
                            }
                          >
                            {selectedConsultForAcceptance === id &&
                            acceptConsultLoading
                              ? "Accepting..."
                              : "Accept"}
                          </Col>
                          <Col
                            span={12}
                            className='decline'
                            onClick={() => handleRejectConsult(id, patientId)}
                          >
                            {selectedConsultForRejection === id &&
                            acceptConsultLoading
                              ? "Declining..."
                              : "Decline"}
                          </Col>
                        </Row>
                      </TableRowItemWrapper>
                    );
                  }
                )
            ) : (
              <Empty />
            )}
          </TableBody>
          {!isConsultsLoading && consultsData?.length > 0 && (
            <PaginationWrapper>
              <Pagination
                defaultCurrent={1}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                onChange={handlePageChange}
                total={consultsData.length || 0}
              />
            </PaginationWrapper>
          )}
        </ConsultTableBody>
      </ConsultTableWrapper>
    </Card>
  );
};

export default ConsultTable;
