import {
  Col,
  Row,
  Skeleton,
  Spin,
  Switch,
  Form,
  DatePicker,
  Divider,
  Popconfirm,
  Grid,
  Space
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { MessageOutlined, CheckOutlined } from "@ant-design/icons";
import { EditOutlined, CalendarOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";

// @ts-ignore
import Engage from "@engage_so/js";

import ExaminationsModal from "../ExaminationsModal";
import DiagnosisModal from "../DiagnosisModal";
import TreatmentsModal from "../TreatmentsModal";
import InvestigationsModal from "../InvestigationsModal";
import MedicationsModal from "../MedicationsModal";
import SymptomsModal from "../SymptomsModal";
import Tag from "component/Tag";
import Card from "component/Card";

// redux
import { deleteConsultSymptom, symptomSelector } from "redux/reducers/symptoms";
import { deleteConsultDiagnoses } from "redux/reducers/diagnoses";
import { deleteConsultInvestgations } from "redux/reducers/investigations";
import { deleteConsultMedications } from "redux/reducers/medications";
import { deleteConsultTreatments } from "redux/reducers/treatments";
import { addConsultExaminations } from "redux/reducers/examinations";
import {
  consultSelector,
  endConsult,
  fetchSingleConsult,
  updateConsult
} from "redux/reducers/consults";

import useQuery from "hooks/useQuery";

// svgs
import ExamFindingsIcon from "assets/icons/exam_findings.svg";
import MedicationIcon from "assets/icons/medicines.svg";
import InvestigationIcon from "assets/icons/clinical_a.svg";
import OtherTreatmentsIcon from "assets/icons/other_treatments.svg";
import ExaminationIcon from "assets/icons/clinical_fe.svg";
import SymptomsIcon from "assets/icons/magnifying_glass.svg";
import DiagnosisIcon from "assets/icons/diagnosis.svg";

import { Mixpanel } from "utils/mixpanel";
import {
  EncountersFormWrapper,
  EncountersFormHeaderWrapper,
  EncountersFormBodyWrapper
} from "./styles";
import { isProd } from "utils/utils";

const EncountersForm = (props: any) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const consultId = query.get("consultId");
  const [examForm] = Form.useForm();
  const [complaintsForm] = Form.useForm();

  // Modal isVisible state
  const [isExamModalVisible, setIsExamModalVisible] = useState(false);
  const [isDiagnosisModalVisible, setIsDiagnosisModalVisible] = useState(false);
  const [isInvestigationsModalVisible, setIsInvestigationsModalVisible] =
    useState(false);
  const [isTreatmentsModalVisible, setIsTreatmentsModalVisible] =
    useState(false);
  const [isMedicationsModalVisible, setIsMedicationsModalVisible] =
    useState(false);
  const [isSymptomsModalVisible, setIsSymptomsModalVisible] = useState(false);

  // Form State
  const [formExaminations, setFormExaminations] = useState([]);
  const [formDiagnosis, setFormDiagnosis] = useState([]);
  const [formSymptoms, setFormSymptoms] = useState([]);
  const [formMedications, setFormMedications] = useState([]);
  const [formInvestigations, setFormInvestigations] = useState([]);
  const [formTreatments, setFormTreatments] = useState([]);
  const [formComplaintsHistory, setFormComplaintsHistory] = useState("");
  const [formFollowUp, setFormFollowUp] = useState<Moment | null>();

  // Other State
  const [isFollowUpOpen, setIsFollowUpOpen] = useState(false);
  const [historyOfComplaintsEdit, setHistoryOfComplaintsEdit] = useState(false);
  const [isExaminationsEdit, setIsExaminationsEdit] = useState(false);
  const [
    initialValuesForExaminationFindings,
    setInitialValuesForExaminationFindings
  ] = useState<any>({});

  const {
    consult,
    isConsultLoading,
    updateConsultLoading,
    isUpdatedConsultSuccess,
    endConsultLoading
  } = useSelector(consultSelector);

  const { updateData } = props;

  const { isDeleteConsultSymptomLoading } = useSelector(symptomSelector);

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    dispatch(fetchSingleConsult(consultId));
  }, [consultId, dispatch]);

  useEffect(() => {
    const {
      medications = [],
      symptoms = [],
      investigations = [],
      examinations = [],
      diagnosis = [],
      recommendations = [],
      follow_up = null
    } = consult || {};
    setFormDiagnosis(diagnosis);
    setFormExaminations(examinations);
    setFormSymptoms(symptoms);
    setFormMedications(medications);
    setFormInvestigations(investigations);
    setFormTreatments(recommendations);
    if (follow_up) {
      setIsFollowUpOpen(true);
      setFormFollowUp(moment(follow_up));
    }
  }, [consult]);

  useEffect(() => {
    if (isUpdatedConsultSuccess) {
      setHistoryOfComplaintsEdit(false);
      setIsExaminationsEdit(false);
      dispatch(updateData());
    }
  }, [dispatch, isUpdatedConsultSuccess, updateData]);

  const handleComplaintsHistoryUpdate = (values: any) => {
    const payload = {
      complaint_history: values?.formComplaintsHistory
    };
    dispatch(updateConsult(consultId, payload));
  };

  const handleExaminationsUpdate = (values: any) => {
    const payload = {
      examinations: Object.keys(values).map((examination) => {
        return {
          examination,
          comment: values[examination]
        };
      })
    };
    dispatch(addConsultExaminations(consultId, payload));
    setIsExaminationsEdit(false);
  };

  useEffect(
    () => examForm.resetFields(),
    [examForm, initialValuesForExaminationFindings]
  );

  useEffect(
    () => complaintsForm.resetFields(),
    [complaintsForm, formComplaintsHistory]
  );

  useEffect(() => {
    const initialValuesForExaminationFindings = formExaminations.reduce(
      (acc: any, item: any) => {
        acc[item?.exam_id] = item?.comment;
        return acc;
      },
      {}
    );
    setInitialValuesForExaminationFindings(initialValuesForExaminationFindings);
  }, [formExaminations]);

  const { complaint_history, status } = consult || {};

  useEffect(() => {
    setFormComplaintsHistory(complaint_history);
  }, [complaint_history]);

  const handleFollowUpChange = (checked: boolean) => {
    setIsFollowUpOpen(checked);
  };

  const handleFollowUpUpdate = (value: any) => {
    const follow_up = moment(value).format("YYYY-MM-DD");
    const payload = { follow_up };
    dispatch(updateConsult(consultId, payload));
  };

  const handleEndConsult = async () => {
    if (!endConsultLoading) {
      await dispatch(endConsult(consultId));
      Mixpanel.track("complete_consult", { consultId: consultId! });

      // engage event tracking
      if (isProd) {
        Engage.track(consult.patient_record.id, {
          event: "encounter_completed",
          properties: {
            formName: consult?.form.name
          }
        });
      }

      props.setIsEncounterFormVisible(false);
      props.setIsChatPaneVisible(false);
    }
  };

  return (
    <>
      {props.visible && (
        <EncountersFormWrapper>
          <Card marginBottom={0} padding={0}>
            <EncountersFormHeaderWrapper>
              <Row gutter={12}>
                <Col flex={1} className='title'>
                  Encounter note {status === "ended" && <span>(Ended)</span>}
                </Col>
                <Col
                  className=''
                  style={{ textAlign: "right", cursor: "pointer" }}
                >
                  <Space>
                    {!breakpoints.lg && (
                      <MessageOutlined
                        style={{
                          fontSize: 20,
                          marginTop: 4,
                          color: "white"
                        }}
                        onClick={props.toggleViewNote}
                      />
                    )}
                    {status !== "ended" && (
                      <Popconfirm
                        title='Are you sure you want to end this consult?'
                        onConfirm={handleEndConsult}
                        okText='End'
                        cancelText='Cancel'
                      >
                        <span className='btn-action'>
                          {!breakpoints.md ? (
                            <CheckOutlined />
                          ) : endConsultLoading ? (
                            "Completing..."
                          ) : (
                            "Complete Consult"
                          )}
                        </span>
                      </Popconfirm>
                    )}
                  </Space>
                </Col>
              </Row>
            </EncountersFormHeaderWrapper>
            <EncountersFormBodyWrapper>
              <Row align='middle' className='intro-details'>
                <Col span={24}>
                  <Divider style={{ margin: "10px 0px", fontWeight: "normal" }}>
                    {consult?.updated_at
                      ? moment(consult?.updated_at).format("LL")
                      : moment().format("LL")}
                  </Divider>
                </Col>
                <Col span={12}>
                  <p className='title'>
                    <img width='20px' src={SymptomsIcon} alt='symptom-icon' />{" "}
                    Reasons for visit (Symptoms)
                  </p>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {status === "ended" ? (
                    <span className='link-disabled mt-20'>
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      Symptoms
                    </span>
                  ) : (
                    <span
                      className='link mt-20'
                      onClick={() => setIsSymptomsModalVisible(true)}
                    >
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      Symptoms
                    </span>
                  )}
                </Col>
                <Col span={24} className='tags-pane'>
                  {isConsultLoading ? (
                    [1, 2, 3].map((item) => (
                      <Skeleton.Button
                        active={true}
                        size={"small"}
                        shape={"square"}
                        style={{
                          marginRight: 5,
                          width: 100,
                          height: 27,
                          borderRadius: 10,
                          marginBottom: 10
                        }}
                      />
                    ))
                  ) : formSymptoms.length === 0 ? (
                    <p className='sub-text'>No symptom added yet</p>
                  ) : (
                    formSymptoms.map((item: any, index) => {
                      return (
                        <Tag
                          key={index}
                          onDelete={() =>
                            dispatch(deleteConsultSymptom(consultId, [item.id]))
                          }
                          onDeleteLoading={isDeleteConsultSymptomLoading}
                        >
                          {item?.symptom} of {item?.number_of_days} day(s)
                          duration
                        </Tag>
                      );
                    })
                  )}
                </Col>
              </Row>

              {/* History of Presenting Complaints */}
              <Row className='details'>
                <Form
                  layout='vertical'
                  form={complaintsForm}
                  onFinish={handleComplaintsHistoryUpdate}
                  initialValues={{ formComplaintsHistory }}
                  style={{ width: "100%" }}
                >
                  <p className='title'>
                    <img
                      width='20px'
                      src={ExaminationIcon}
                      alt='symptom-icon'
                    />{" "}
                    History of presenting complaints
                  </p>
                  <Form.Item
                    name='formComplaintsHistory'
                    style={{ marginBottom: 10 }}
                  >
                    {historyOfComplaintsEdit ? (
                      <TextArea rows={4} />
                    ) : isConsultLoading ? (
                      <Spin spinning={true} />
                    ) : (
                      <p style={{ whiteSpace: "break-spaces" }}>
                        {formComplaintsHistory || <i>No complaints yet</i>}
                      </p>
                    )}
                  </Form.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end"
                    }}
                  >
                    {historyOfComplaintsEdit ? (
                      <>
                        <button
                          type='submit'
                          className='btn-save'
                          style={{ marginRight: 20 }}
                        >
                          {updateConsultLoading ? "Updating..." : "Update"}
                        </button>
                        <span
                          className='link'
                          onClick={() => setHistoryOfComplaintsEdit(false)}
                        >
                          Cancel
                        </span>
                      </>
                    ) : status === "ended" ? (
                      <span className='link-disabled'>
                        <EditOutlined style={{ marginRight: 5 }} /> Click to
                        Edit Complaints
                      </span>
                    ) : (
                      <span
                        className='link'
                        onClick={() => setHistoryOfComplaintsEdit(true)}
                      >
                        <EditOutlined style={{ marginRight: 5 }} /> Click to
                        Edit Complaints
                      </span>
                    )}
                  </div>
                </Form>
              </Row>

              {/* Examination Findings */}
              <Form
                layout='vertical'
                form={examForm}
                onFinish={handleExaminationsUpdate}
                initialValues={initialValuesForExaminationFindings}
                style={{ width: "100%" }}
              >
                <Row className='details'>
                  <Col span={12}>
                    <p className='title'>
                      <img
                        width='20px'
                        src={ExamFindingsIcon}
                        alt='symptom-icon'
                      />{" "}
                      Examination findings
                    </p>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {status === "ended" ? (
                      <span className='link-disabled'>
                        <EditOutlined style={{ marginRight: 5 }} /> Add/Remove
                        Examination
                      </span>
                    ) : (
                      <span
                        className='link'
                        onClick={() => setIsExamModalVisible(true)}
                      >
                        <EditOutlined style={{ marginRight: 5 }} /> Add/Remove
                        Examination
                      </span>
                    )}
                  </Col>

                  <Col span={24} className='tags-pane'>
                    {isConsultLoading ? (
                      [1, 2, 3].map((item) => (
                        <Skeleton.Button
                          active={true}
                          size={"small"}
                          shape={"square"}
                          style={{
                            marginRight: 5,
                            width: 100,
                            height: 27,
                            borderRadius: 10
                          }}
                        />
                      ))
                    ) : formExaminations.length === 0 ? (
                      <p className='sub-text'>No exam findings added yet</p>
                    ) : (
                      formExaminations.map((item: any, index) => (
                        <Col
                          span={24}
                          style={{ marginTop: "10px" }}
                          key={index}
                        >
                          <Row justify='space-between'>
                            <p style={{ fontWeight: 500 }}>{item?.name}</p>

                            <div></div>
                          </Row>
                          <p style={{ whiteSpace: "break-spaces" }}>
                            {item?.comment}
                          </p>
                        </Col>
                      ))
                    )}
                  </Col>
                </Row>
              </Form>

              <Row className='details action-rows' align='middle'>
                <Col span={12}>
                  <p className='title'>
                    <img width='20px' src={DiagnosisIcon} alt='symptom-icon' />{" "}
                    Diagnosis
                  </p>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {status === "ended" ? (
                    <span className='link-disabled'>
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      Diagnosis
                    </span>
                  ) : (
                    <span
                      className='link'
                      onClick={() => setIsDiagnosisModalVisible(true)}
                    >
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      Diagnosis
                    </span>
                  )}
                </Col>
                <Col span={24} className='tags-pane'>
                  {isConsultLoading ? (
                    [1, 2, 3].map((item) => (
                      <Skeleton.Button
                        active={true}
                        size={"small"}
                        shape={"square"}
                        style={{
                          marginRight: 5,
                          width: 100,
                          height: 27,
                          borderRadius: 10
                        }}
                      />
                    ))
                  ) : formDiagnosis.length === 0 ? (
                    <p className='sub-text'>No diagnosis added yet</p>
                  ) : (
                    formDiagnosis.map((item: any, index: number) => {
                      return (
                        <Tag
                          key={index}
                          onDelete={() =>
                            dispatch(
                              deleteConsultDiagnoses(consultId, [item.id])
                            )
                          }
                          onDeleteLoading={isDeleteConsultSymptomLoading}
                        >
                          {item?.condition}
                        </Tag>
                      );
                    })
                  )}
                </Col>
              </Row>

              <Row className='details action-rows' align='middle'>
                <Col span={12}>
                  <p className='title'>
                    <img
                      width='20px'
                      src={InvestigationIcon}
                      alt='symptom-icon'
                    />{" "}
                    Investigations
                  </p>
                </Col>
                <Col span={12}>
                  {status === "ended" ? (
                    <p className='link-disabled'>
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      Investigation
                    </p>
                  ) : (
                    <p
                      className='link'
                      onClick={() => setIsInvestigationsModalVisible(true)}
                    >
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      Investigation
                    </p>
                  )}
                </Col>
                <Col span={24} className='tags-pane'>
                  {isConsultLoading ? (
                    [1, 2, 3].map((item) => (
                      <Skeleton.Button
                        active={true}
                        size={"small"}
                        shape={"square"}
                        style={{
                          marginRight: 5,
                          width: 100,
                          height: 27,
                          borderRadius: 10
                        }}
                      />
                    ))
                  ) : formInvestigations.length === 0 ? (
                    <p className='sub-text'>No investigation added yet</p>
                  ) : (
                    formInvestigations.map((item: any, index: number) => {
                      return (
                        <Tag
                          key={index}
                          onDelete={() =>
                            dispatch(
                              deleteConsultInvestgations(consultId, [item.id])
                            )
                          }
                          onDeleteLoading={isDeleteConsultSymptomLoading}
                        >
                          {item?.name} ({item?.category})
                        </Tag>
                      );
                    })
                  )}
                </Col>
              </Row>

              <Row className='details action-rows' align='middle'>
                <Col span={12}>
                  <p className='title'>
                    <img width='20px' src={MedicationIcon} alt='symptom-icon' />{" "}
                    Medications
                  </p>
                </Col>
                <Col span={12}>
                  {status === "ended" ? (
                    <p className='link-disabled'>
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      medications
                    </p>
                  ) : (
                    <p
                      className='link'
                      onClick={() => setIsMedicationsModalVisible(true)}
                    >
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      medications
                    </p>
                  )}
                </Col>
                <Col span={24} className='tags-pane'>
                  {isConsultLoading ? (
                    [1, 2, 3].map((item) => (
                      <Skeleton.Button
                        active={true}
                        size={"small"}
                        shape={"square"}
                        style={{
                          marginRight: 5,
                          width: 100,
                          height: 27,
                          borderRadius: 10,
                          marginBottom: 10
                        }}
                      />
                    ))
                  ) : formMedications.length === 0 ? (
                    <p className='sub-text'>No medication added yet</p>
                  ) : (
                    formMedications.map((item: any, index) => {
                      return (
                        <Row justify='space-between' align='middle'>
                          <Col span={24}>
                            <Tag
                              key={index}
                              onDelete={() =>
                                dispatch(
                                  deleteConsultMedications(consultId, [item.id])
                                )
                              }
                              onDeleteLoading={isDeleteConsultSymptomLoading}
                            >
                              <b>{item?.name}</b>{" "}
                              {item?.dosage && `(${item?.dosage})`} -{" "}
                              <i>{item?.signature}</i> for{" "}
                              {item?.number_of_days} day(s)
                            </Tag>
                          </Col>
                        </Row>
                      );
                    })
                  )}
                </Col>
              </Row>

              <Row className='details action-rows' align='middle'>
                <Col span={12}>
                  <p className='title'>
                    <img
                      width='20px'
                      src={OtherTreatmentsIcon}
                      alt='symptom-icon'
                    />{" "}
                    Recommendations
                  </p>
                </Col>
                <Col span={12}>
                  {status === "ended" ? (
                    <p className='link-disabled'>
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      recommendations
                    </p>
                  ) : (
                    <p
                      className='link'
                      onClick={() => setIsTreatmentsModalVisible(true)}
                    >
                      <EditOutlined style={{ marginRight: 5 }} /> Update
                      recommendations
                    </p>
                  )}
                </Col>
                <Col span={24} className='tags-pane'>
                  {isConsultLoading ? (
                    [1].map((item) => (
                      <Col span={24}>
                        <Skeleton.Button
                          active={true}
                          size={"small"}
                          shape={"square"}
                          style={{
                            marginRight: 5,
                            width: 200,
                            height: 27,
                            borderRadius: 10,
                            marginBottom: 10
                          }}
                        />
                      </Col>
                    ))
                  ) : formTreatments.length === 0 ? (
                    <p className='sub-text'>No recommendations added yet</p>
                  ) : (
                    formTreatments.map((item: any, index) => {
                      return (
                        <Tag
                          key={index}
                          onDelete={() =>
                            dispatch(
                              deleteConsultTreatments(consultId, item.id)
                            )
                          }
                          onDeleteLoading={isDeleteConsultSymptomLoading}
                        >
                          {item.plan}
                        </Tag>
                      );
                    })
                  )}
                </Col>
              </Row>

              <Row className='details' align='middle' gutter={[0, 16]}>
                {status === "ended" ? (
                  <Col xs={24} md={12}>
                    <Switch checked={isFollowUpOpen} />{" "}
                    <span>Follow up appointment</span>
                  </Col>
                ) : (
                  <Col xs={24} md={12}>
                    <Switch
                      checked={isFollowUpOpen}
                      onChange={handleFollowUpChange}
                    />{" "}
                    <span>Follow up appointment</span>
                  </Col>
                )}
                {isFollowUpOpen && (
                  <Col xs={24} md={12}>
                    <DatePicker
                      format='YYYY-MM-DD'
                      onChange={handleFollowUpUpdate}
                      value={formFollowUp}
                      disabledDate={(current) => {
                        // Can not select days before today
                        return current && current < moment().startOf("day");
                      }}
                      suffixIcon={
                        updateConsultLoading ? (
                          <Spin spinning={true} />
                        ) : (
                          <CalendarOutlined />
                        )
                      }
                    />
                  </Col>
                )}
              </Row>
            </EncountersFormBodyWrapper>
          </Card>
        </EncountersFormWrapper>
      )}
      <ExaminationsModal
        consultId={consultId}
        formExaminations={formExaminations}
        setFormExaminations={setFormExaminations}
        isExamModalVisible={isExamModalVisible}
        setIsExamModalVisible={setIsExamModalVisible}
        setIsExaminationsEdit={setIsExaminationsEdit}
      />
      <DiagnosisModal
        consultId={consultId}
        formDiagnosis={formDiagnosis}
        isDiagnosisModalVisible={isDiagnosisModalVisible}
        setIsDiagnosisModalVisible={setIsDiagnosisModalVisible}
      />
      <TreatmentsModal
        consultId={consultId}
        formTreatments={formTreatments}
        isTreatmentsModalVisible={isTreatmentsModalVisible}
        setIsTreatmentsModalVisible={setIsTreatmentsModalVisible}
      />
      <InvestigationsModal
        consultId={consultId}
        formInvestigations={formInvestigations}
        formDiagnosis={formDiagnosis}
        formSymptoms={formSymptoms}
        isInvestigationsModalVisible={isInvestigationsModalVisible}
        setIsInvestigationsModalVisible={setIsInvestigationsModalVisible}
      />
      <MedicationsModal
        consultId={consultId}
        formMedications={formMedications}
        formDiagnosis={formDiagnosis}
        formSymptoms={formSymptoms}
        formInvestigations={formInvestigations}
        isMedicationsModalVisible={isMedicationsModalVisible}
        setIsMedicationsModalVisible={setIsMedicationsModalVisible}
      />
      <SymptomsModal
        consultId={consultId}
        formSymptoms={formSymptoms}
        isSymptomsModalVisible={isSymptomsModalVisible}
        setIsSymptomsModalVisible={setIsSymptomsModalVisible}
      />
    </>
  );
};

export default EncountersForm;
