import styled from 'styled-components';

const TagWrapper = styled.div`
    font-size: 13px;
    padding: 5px 10px;
    background: #fff;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    display: inline-block;
    color: #333;
    align-items: center;
    position: relative;
    display: inline-flex;
    border: 1px solid #a3a3a3;
    &:hover {
        box-shadow: 0px 1px 3px 0px #f2f2f2;
        border: 1px solid #0078E2;
    }
    div {
        flex-grow: 1;
        b, i {
            display: inline;
        }
    }
    input {
        border: 1px solid transparent;
        outline: none;
    }
    span {
        cursor: pointer;
    }
    img {
        margin-left: 5px;
        cursor: pointer;
    }
    i {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 5px;
        font-weight: 600;
        border-radius: 50%;
        &.edit {
            color: #0078E2;
        }
        &.delete {
            color: crimson;
        }
        &.edit:hover {
            background: #fff;
            color: #0078E2;
        }
        &.delete:hover {
            background: #fff;
            color: crimson;
        }
    }
`;

export { TagWrapper }