import styled from "styled-components";

const FamilyHistoryModalWrapper = styled.div`
  border-radius: 10px;
  height: 100%;
  .ant-row {
    height: 100%;
  }
  .medications-lhs {
    padding: 35px 30px;
    border-right: 1px solid #002e4640;
    position: relative;
    span {
      text-align: left;
      font: normal normal normal 13px/14px DM Sans;
      letter-spacing: 0px;
      color: #4e667e;
      opacity: 0.65;
    }
    p {
      text-align: left;
      font: normal normal normal 13px/20px DM Sans;
      letter-spacing: 0px;
      color: #4e667e;
      opacity: 1;
      &.mb-23 {
        margin-bottom: 23px;
      }
    }
    .top {
      /* position: absolute; */
      /* top: 35px; */
      margin-bottom: 150px;
    }
    .lab-recommendations {
      position: absolute;
      padding-top: 20px;
      bottom: 60px;
      border-top: 0.800000011920929px solid #002e4640;
      p {
        text-align: left;
        font: normal normal medium 13px/14px DM Sans;
        letter-spacing: 0px;
        color: #0078e2;
        opacity: 1;
      }
    }
  }
  .medications-rhs {
    padding: 35px 30px;
    height: 100%;
    /* overflow-y: scroll; */
    .ant-select-single .ant-select-selector::after,
    .ant-select-single .ant-select-selector .ant-select-selection-item::after,
    .ant-select-single
      .ant-select-selector
      .ant-select-selection-placeholder::after {
          content: none;
    }
    .investigation-list-item {
      .investigation-list-item-header {
        display: flex;
        justify-content: space-between;
        text-align: left;
        font: normal normal normal 13px/28px DM Sans;
        letter-spacing: 0px;
        color: #002e46;
        cursor: pointer;
        opacity: 1;
        border-bottom: 1px solid #002e4620;
        padding: 13px 0px;
        /* opacity: 0.25; */
      }
      .investigation-list-item-body {
        padding: 10px 0px;
        &.show {
          display: block;
        }
        &.hidden {
          height: 0px;
          visibility: hidden;
          opacity: 0;
        }
        .ant-checkbox-wrapper {
          text-align: left;
          font: normal normal normal 13px/13px DM Sans;
          letter-spacing: 0px;
          color: #002e46;
          opacity: 0.75;
          margin-bottom: 14px;
        }
      }
    }
  }
`;

const FamilyHistoryModalHeaderWrapper = styled.div`
  border-bottom: 0.800000011920929px solid #002e4640;
  padding: 22px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  p {
    text-align: left;
    font: normal normal normal 16px/19px DM Sans;
    letter-spacing: 0px;
    color: #002e46;
    opacity: 0.75;
    margin-bottom: 0px;
  }
`;

const FamilyHistoryModalBodyWrapper = styled.div`
  height: 100%;
  background: #fff;

  p {
    text-align: left;
    font: normal normal normal 16px/19px DM Sans;
    letter-spacing: 0px;
    color: #002e46;
    opacity: 0.75;
    margin-bottom: 0px;
  }
  .btn-submit {
    padding: 0px 50px;
    margin-top: 50px;
  }

  .btn-add {
    text-align: right;
    padding: 0px 50px;
    font: normal normal medium 13px/24px DM Sans;
    letter-spacing: 0px;
    color: #0078e2;
    opacity: 1;
  }
  /* ant-select-item ant-select-item-option ant-select-item-option-active */
`;

export {
  FamilyHistoryModalWrapper,
  FamilyHistoryModalHeaderWrapper,
  FamilyHistoryModalBodyWrapper,
};
