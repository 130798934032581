import { Col, Row, Spin, Empty, Divider, Grid, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import * as linkify from "linkifyjs";
import "linkifyjs/plugins/hashtag"; // optional
import Linkify from "react-linkify";
import autosize from "autosize";
import { VideoCameraOutlined, MinusCircleOutlined } from "@ant-design/icons";
import BeatLoader from "react-spinners/BeatLoader";

import Card from "../../../../component/Card";
import {
  ChatPaneWrapper,
  ChatPaneHeaderWrapper,
  ChatPaneBodyWrapper,
  ChatBubbleWrapper,
  ChatPaneInputWrapper,
  TypingIndicatorWrapper
} from "./styles";

import {
  messageSelector,
  fetchAllMessages,
  addMessage
} from "../../../../redux/reducers/messages";
import ChatSend from "../../../../assets/icons/chat-send.svg";
import ChatAttachments from "../../../../assets/icons/chat-attachments.svg";
import useQuery from "../../../../hooks/useQuery";
import FilePreview from "../../../../component/FilePreview";
import ChatIcon from "../../../../assets/icons/forum.svg";
import Storage from "../../../../utils/firebase-storage";
import { consultSelector } from "../../../../redux/reducers/consults";
import pusher from "../../../../utils/pusher";
import { ReactComponent as EncounterNoteSvg } from "assets/encounter-note.svg";
import { Mixpanel } from "utils/mixpanel";
import { isNotificationSupported } from "utils/utils";

const ChatPane = (props: any) => {
  let query = useQuery();
  const consultId = query.get("consultId");
  const [chatText, setChatText] = useState("");
  const [chatType, setChatType] = useState("text");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [chatData, setChatData] = useState<any[]>([]);
  const chatBodyEndRef = useRef<any>(null);
  const textAreaRef = useRef<any>(null);
  const breakpoints = Grid.useBreakpoint();

  const dispatch = useDispatch();
  const {
    messages: chatMessage,
    isMessagesLoading,
    isAddMessageLoading
  } = useSelector(messageSelector);
  const { consult, isConsultLoading } = useSelector(consultSelector);
  const profile = JSON.parse(localStorage.getItem("pneumaCurrentUser") || "");

  const { consultation_room } = profile || {};

  useEffect(() => {
    const { current } = textAreaRef;
    if (current) {
      current.focus();
      autosize(current);
    }
  }, [textAreaRef]);

  const scrollToBottom = () => {
    if (chatBodyEndRef.current) {
      chatBodyEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    }
  };

  useEffect(() => {
    if (props.visible) {
      scrollToBottom();
    }
  }, [props.visible, chatData, consult, isConsultLoading, chatBodyEndRef]);

  const { patient_record, created_at, form_answers = [] } = consult || {};
  const { first_name, last_name } = patient_record || {};

  useEffect(() => {
    setChatData([...chatMessage] || []);
  }, [chatMessage]);

  useEffect(() => {
    if (consultId) {
      const channel = pusher.subscribe(consultId);
      channel.bind("messages", (data: any) => {
        setChatData((prevState) => [...prevState, data]);
      });
    }
    return () => {
      consultId && pusher.unsubscribe(consultId);
    };
  }, [consultId]);

  useEffect(() => {
    if (props.visible) {
      dispatch(fetchAllMessages(consultId));
    }
  }, [consultId, dispatch, props.visible]);

  const [isPatientTyping, setIsPatientTyping] = useState(false);
  const isTypingRef = useRef(isPatientTyping);
  isTypingRef.current = isPatientTyping;

  useEffect(() => {
    let clearTimerId: any;

    if (consultId) {
      const channel = pusher.subscribe(`private-consult-${consultId}`);

      channel.bind("client-patient_typing", (data: any) => {
        isTypingRef.current = true;
        setIsPatientTyping(() => true);

        clearTimeout(clearTimerId);
        clearTimerId = setTimeout(function () {
          // clear isTyping state
          isTypingRef.current = false;
          setIsPatientTyping(() => false);
        }, 5000);
      });
    }
    return () => {
      consultId && pusher.unsubscribe(`private-consult-${consultId}`);
      isTypingRef.current = false;
      setIsPatientTyping(() => false);
      clearTimeout(clearTimerId);
    };
  }, [consultId]);

  const handleAddMessage = async (e: any) => {
    e.preventDefault();
    const payload = {
      content: chatText,
      type: chatType
    };

    if (payload.content) {
      await dispatch(addMessage(consultId, payload));
      setChatText("");
      setChatType("text");
      textAreaRef.current.style.height = "50px";
    }
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (file == null) return;

    const uploadTask = Storage.ref(`/chats/files/${file.name}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        // console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          handleSendAttachments(url);
        });
      }
    );
  };

  const [canPublish, setCanPublish] = useState(true);
  const canPublishRef = useRef(canPublish);
  canPublishRef.current = canPublish;

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (canPublish) {
      const channel = pusher.subscribe(`private-consult-${consultId}`);

      channel.bind("pusher:subscription_succeeded", () => {
        const triggered = channel.trigger("client-provider_typing", {
          providerId: profile.id,
          providerName: `${profile.first_name}`
        });
      });

      setCanPublish(false);
      canPublishRef.current = false;

      setTimeout(() => {
        // allow new triggers after 5 seconds
        setCanPublish(true);
        canPublishRef.current = true;
      }, 5000);

      channel.bind("pusher:subscription_error", (status: number) => {
        console.log(
          "something went wrong subscribing to private channel",
          status
        );
      });
    }
  };

  const handleSendAttachments = (url: string) => {
    const payload = {
      content: url,
      type: "file"
    };

    if (payload.content) {
      dispatch(addMessage(consultId, payload));
      setChatType("file");
    }
  };

  const handleSendVideoConferenceLink = () => {
    const payload = {
      content:
        consultation_room || `https://meet.jit.si/pneumaconsults/${consultId}`,
      type: "text"
    };

    if (payload.content) {
      dispatch(addMessage(consultId, payload));
    }
  };

  const handleUpdate = (e: any) => {
    setChatText(e.target.value);
  };

  return (
    props.visible && (
      <ChatPaneWrapper>
        <Card marginBottom={0} padding={0}>
          <ChatPaneHeaderWrapper>
            <Row gutter={12} wrap={false} align='middle'>
              <Col flex={1} className='title'>
                <img width='20px' src={ChatIcon} alt='chat-icon' /> Chat -{" "}
                {first_name} {last_name}
              </Col>
              <Col className='dot-actions'>
                <Space align='center' size='middle'>
                  {!breakpoints.lg && (
                    <div style={{ cursor: "pointer" }}>
                      <EncounterNoteSvg
                        fill='#ffffff'
                        width={30}
                        height={30}
                        onClick={props.toggleViewNote}
                      />
                    </div>
                  )}
                  <VideoCameraOutlined
                    style={{ cursor: "pointer", color: "white", fontSize: 20 }}
                    onClick={handleSendVideoConferenceLink}
                  />
                  <MinusCircleOutlined
                    style={{ cursor: "pointer", color: "white", fontSize: 20 }}
                    onClick={() => {
                      props.setIsChatPaneVisible(false);
                      props.setIsEncounterFormVisible(false);
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </ChatPaneHeaderWrapper>
          <ChatPaneBodyWrapper>
            {form_answers?.responses?.length > 0 && (
              <Col span={24}>
                <Divider
                  style={{
                    margin: "10px 0px",
                    fontWeight: "normal",
                    fontSize: "13px"
                  }}
                >
                  {moment(created_at).format("LL")}
                </Divider>
              </Col>
            )}
            {isConsultLoading
              ? "Loading form response..."
              : form_answers?.responses?.map((item: any, idx: number) => {
                  const response = Array.isArray(item?.value)
                    ? item?.value.join(", ")
                    : item?.value;

                  const extras = [
                    <span className='time'>
                      {moment(item?.updated_at).format("HH:mm a")}
                    </span>
                  ];
                  const senderExtras = [...extras];
                  senderExtras.sort((a, b) => -1);
                  return (
                    <React.Fragment key={item?.id || idx}>
                      <ChatBubbleWrapper type={"provider"}>
                        <div className='author'>{extras}</div>
                        <div className='content'>{item?.question_text}</div>
                      </ChatBubbleWrapper>
                      <ChatBubbleWrapper type={"patients"}>
                        <div className='author'>{senderExtras}</div>
                        <div className='content'>{response}</div>
                      </ChatBubbleWrapper>
                    </React.Fragment>
                  );
                })}
            <div ref={chatBodyEndRef} style={{ paddingBottom: "30px" }}>
              {isMessagesLoading ? (
                <Row
                  align='middle'
                  justify='center'
                  style={{ height: "400px" }}
                >
                  <Spin size='default' />
                </Row>
              ) : chatData?.length > 0 ? (
                <>
                  {chatData
                    .sort((a: any, b: any): any =>
                      moment(a.created_at).diff(moment(b.created_at))
                    )
                    .map(
                      (
                        { content, type, sender, sender_model, created_at },
                        index
                      ) => {
                        const extras = [
                          <span className='time'>
                            {moment(created_at).format("HH:mm a : MMM DD, YY")}
                          </span>
                        ];
                        extras.sort((a, b) =>
                          sender.type === "provider" ? 1 : -1
                        );
                        const urlBeforeParams =
                          content.split("?alt=media&token")[0];
                        const isIMGFile =
                          type === "file" &&
                          /\.(jpe?g|png|webp)$/.test(urlBeforeParams);
                        const embeddedURL = linkify.find(content)[0]?.href;
                        return (
                          <ChatBubbleWrapper type={sender?.type} key={index}>
                            <div className='author'>{extras}</div>
                            {isIMGFile ? (
                              <FilePreview file={content} />
                            ) : (
                              <div className='content'>
                                {embeddedURL && (
                                  <LinkPreview
                                    margin='10px 0px 10px'
                                    height='200px'
                                    fallback={
                                      <div className='fallback'>
                                        No preview available
                                      </div>
                                    }
                                    url={embeddedURL}
                                  />
                                )}
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref: any,
                                    decoratedText: any,
                                    key: any
                                  ) => (
                                    <a
                                      target='blank'
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  {content}
                                </Linkify>
                              </div>
                            )}
                          </ChatBubbleWrapper>
                        );
                      }
                    )}

                  {isPatientTyping ? (
                    <TypingIndicatorWrapper>
                      <BeatLoader
                        color={"#08b"}
                        loading={true}
                        size={8}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                      />
                      <h6>{first_name} is typing...</h6>
                    </TypingIndicatorWrapper>
                  ) : null}
                </>
              ) : (
                <Row
                  align='middle'
                  justify='center'
                  style={{ height: "400px" }}
                >
                  <Empty description="You've not started a chat with this patient yet" />
                </Row>
              )}
            </div>
            <ChatPaneInputWrapper>
              <span className='upload-btn-wrapper'>
                <img
                  className='chat-attachments'
                  src={ChatAttachments}
                  alt='Chat Attachments'
                />
                <input type='file' id='upload' onChange={handleFileUpload} />
              </span>
              <textarea
                ref={textAreaRef}
                value={chatText}
                placeholder='Enter message here'
                disabled={isMessagesLoading}
                onChange={handleUpdate}
                onSubmit={handleAddMessage}
                onKeyUp={handleKeyUp}
              />
              {isAddMessageLoading ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <Spin />
                </span>
              ) : (
                <img
                  src={ChatSend}
                  alt='Chat send btn'
                  onClick={(e) => {
                    handleAddMessage(e);
                    if (isNotificationSupported()) {
                      Notification.requestPermission().then(() =>
                        console.log("Notification permission granted")
                      );
                    }
                    Mixpanel.track("message_sent", { type: chatType });
                  }}
                />
              )}
            </ChatPaneInputWrapper>
          </ChatPaneBodyWrapper>
        </Card>
      </ChatPaneWrapper>
    )
  );
};

export default ChatPane;
