import { ExaminationsModalWrapper, ExaminationListItem } from "./styles";
import { Modal, Form, Divider } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  addConsultExaminations,
  deleteConsultExaminations,
  examinationSelector,
  fetchAllExaminations,
  updateConsultExaminations,
} from "../../../../redux/reducers/examinations";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../../component/Loader";
import TextArea from "../../../../component/TextArea";
import NoIcon from "../../../../assets/icons/no.svg";

interface ExaminationProp {
  name: string;
  active: boolean;
}

const ExaminationsModal = (props: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const {
    consultId,
    formExaminations,
    setFormExaminations,
    isExamModalVisible,
    setIsExamModalVisible,
    setIsExaminationsEdit,
  } = props;

  const { examinations, isExaminationsLoading, examinationsError } =
    useSelector(examinationSelector);

  useEffect(() => {
    dispatch(fetchAllExaminations());
  }, [dispatch]);

  useEffect(() => {
    const previousExaminations = formExaminations.map(
      (item: any) => item.exam_id
    );
    const formattedExaminationData = examinations.map((item: any) => {
      const isActive = previousExaminations.includes(item.id);
      const activeExam = formExaminations.find(
        (exam: any) => exam.exam_id === item.id
      );
      return {
        exam_id: item.id,
        name: item.name,
        active: isActive,
        comment: activeExam?.comment || "",
      };
    });
    setData(formattedExaminationData);
  }, [examinations, formExaminations]);

  const toggleExaminationActiveStatus = (index: number) => {
    const _data = [...data];
    _data.map((item, i) => {
      if (index === item.exam_id) {
        item.active = !item.active;
      }
    });

    setData(_data);
  };

  const handleSubmit = async () => {
    const selectedExams = data.filter((item: any) => item.active);
    // setFormExaminations(selectedExams);
    const formattedPayload = selectedExams.map((item) => {
      return {
        examination: item.exam_id,
        comment: "",
      };
    });

    await dispatch(
      addConsultExaminations(consultId, { examinations: formattedPayload })
    );
    setIsExamModalVisible(false);
    setIsExaminationsEdit(true);
  };

  const handleUpdateItem = async (
    values: any,
    addID: any,
    updateID: any,
    operation: any
  ) => {
    const { [`examination${addID}`]: newExaminationComment } = values;

    if (operation === "ADD") {
      await dispatch(
        addConsultExaminations(consultId, {
          examinations: [
            {
              examination: addID,
              comment: newExaminationComment,
            },
          ],
        })
      );
    } else if (operation === "UPDATE") {
      await dispatch(
        updateConsultExaminations(consultId, updateID, {
          comment: newExaminationComment,
        })
      );
    }
    // setIsExamModalVisible(false);
    // form.resetFields();
  };

  const selectedExaminations = data.filter((item: any) => {
    const selectedIndex = formExaminations.findIndex(
      (formExam: any) => formExam.examination_id === item.exam_id
    );
    if (selectedIndex >= 0) {
      return item;
    }
  });

  const notSelectedExaminations = data.filter((item: any) => {
    const selectedIndex = formExaminations.findIndex(
      (formExam: any) => formExam.examination_id === item.exam_id
    );
    if (selectedIndex < 0) {
      return item;
    }
  });

  return (
    <Modal
      title="Examinations Findings"
      visible={isExamModalVisible}
      centered
      cancelText="Discard Changes"
      onOk={handleSubmit}
      footer={null}
      onCancel={() => setIsExamModalVisible(false)}
      className="examination-modal main-modal"
    >
      <ExaminationsModalWrapper>
        <p className="title">Update/Remove Existing Examination Findings</p>
        {
          selectedExaminations.length === 0 ? <p>No examination findings yet</p>
          : selectedExaminations.map(({ exam_id, name, active }: any, index: number) => {
            const selectedIndex = formExaminations.findIndex(
              (item: any) => item.examination_id === exam_id
            );
            const selectedExam =
              selectedIndex >= 0 ? formExaminations[selectedIndex] : {};
            return (
              <ExaminationListItem isActive={selectedIndex >= 0}>
                <div className="d-flex">
                  <span className="title">{name}</span>
                  {active ? null : (
                    <div style={{ textAlign: "right" }}>
                      <span
                        className="action"
                        style={{ marginRight: "10px" }}
                        onClick={() => toggleExaminationActiveStatus(exam_id)}
                      >
                        {selectedIndex >= 0 ? "Edit" : "Add"} Findings
                      </span>
                      <img width="15%" src={NoIcon} onClick={() => dispatch(deleteConsultExaminations(consultId, [selectedExam.id]))} alt="no-icon" />
                    </div>
                  )}
                </div>
                {active && (
                  <div className="exam_textarea">
                    <Form
                      form={form}
                      name="dynamic_form_item"
                      className="form"
                      layout="vertical"
                      onFinish={(values) =>
                        handleUpdateItem(
                          values,
                          exam_id,
                          selectedExam.id,
                          "UPDATE"
                        )
                      }
                      initialValues={{
                        [`examination${exam_id}`]: selectedExam?.comment,
                      }}
                    >
                      <TextArea
                        formItem={{
                          label: "",
                          name: `examination${exam_id}`,
                          rules: [
                            {
                              required: true,
                              message: "Please input the findings",
                            },
                          ],
                        }}
                      />
                      <div className="buttons_pane">
                        <button
                          type="submit"
                          className="btn-edit"
                          style={{ marginRight: 20 }}
                        >
                          Save Findings
                        </button>
                        <span
                          className="link"
                          onClick={() => toggleExaminationActiveStatus(exam_id)}
                        >
                          Cancel
                        </span>
                      </div>
                    </Form>
                  </div>
                )}
              </ExaminationListItem>
            );
          })
        }
        <Divider />
        <p className="title">Add New Examination Findings</p>
        {isExaminationsLoading ? (
          <Loader />
        ) : (
          notSelectedExaminations.map(({ exam_id, name, active }: any, index: number) => {
            const selectedIndex = formExaminations.findIndex(
              (item: any) => item.examination_id === exam_id
            );
            const selectedExam =
              selectedIndex >= 0 ? formExaminations[selectedIndex] : {};
            return (
              <ExaminationListItem isActive={selectedIndex >= 0}>
                <div className="d-flex">
                  <span className="title">{name}</span>
                  {active ? null : (
                    <div style={{ textAlign: "right" }}>
                      <span
                        className="action"
                        style={{ marginRight: "10px" }}
                        onClick={() => toggleExaminationActiveStatus(exam_id)}
                      >
                        {selectedIndex >= 0 ? "Edit" : "Add"} Findings
                      </span>
                      {selectedIndex >= 0 && (
                        <img width="15%" src={NoIcon} alt="no-icon" />
                      )}
                    </div>
                  )}
                </div>
                {active && (
                  <div className="exam_textarea">
                    <Form
                      form={form}
                      name="dynamic_form_item"
                      className="form"
                      layout="vertical"
                      onFinish={(values) =>
                        handleUpdateItem(
                          values,
                          exam_id,
                          selectedExam.id,
                          "ADD"
                        )
                      }
                      initialValues={{
                        [`examination${index}`]: selectedExam?.comment,
                      }}
                    >
                      <TextArea
                        formItem={{
                          label: "",
                          name: `examination${exam_id}`,
                          rules: [
                            {
                              required: true,
                              message: "Please input the findings",
                            },
                          ],
                        }}
                      />
                      <div className="buttons_pane">
                        <button
                          type="submit"
                          className="btn-edit"
                          style={{ marginRight: 20 }}
                        >
                          Save Findings
                        </button>
                        <span
                          className="link"
                          onClick={() => toggleExaminationActiveStatus(exam_id)}
                        >
                          Cancel
                        </span>
                      </div>
                    </Form>
                  </div>
                )}
              </ExaminationListItem>
            );
          })
        )}
      </ExaminationsModalWrapper>
    </Modal>
  );
};

export default ExaminationsModal;
