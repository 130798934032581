import { combineReducers } from "@reduxjs/toolkit";
import global from "./global";
import AuthReducer from "./auth";
import ExaminationReducer from "./examinations";
import EncountersReducer from "./encounters";
import SymptomReducer from "./symptoms";
import DiagnosisReducer from "./diagnoses";
import ConsultReducer from "./consults";
import MessageReducer from "./messages";
import InvestigationReducer from "./investigations";
import MedicationReducer from "./medications";
import TreatmentsReducer from "./treatments";
import QuestionReducer from "./questions";

const rootReducer = combineReducers({
  global,
  auth: AuthReducer,
  encounters: EncountersReducer,
  examinations: ExaminationReducer,
  symptoms: SymptomReducer,
  medications: MedicationReducer,
  treatments: TreatmentsReducer,
  investigations: InvestigationReducer,
  diagnoses: DiagnosisReducer,
  consults: ConsultReducer,
  messages: MessageReducer,
  questions: QuestionReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
