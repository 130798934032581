import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http from "../../utils/api";
import { message } from "antd";

export interface ConsultError {
  message: string;
}

export interface ConsultState {
  consult: any;
  consults: Array<any>;
  patientConsults: any;
  generalConsultsMetrics: any;
  providerConsultsMetrics: any;
  isConsultLoading: boolean;
  isConsultsLoading: boolean;
  isPatientConsultsLoading: boolean;
  isGeneralConsultsMetricsLoading: boolean;
  isProviderConsultsMetricsLoading: boolean;
  isUpdatedConsultSuccess: boolean;
  acceptConsultLoading: boolean;
  rejectConsultLoading: boolean;
  endConsultLoading: boolean;
  updateConsultLoading: boolean;
  updatePatientProfileLoading: boolean;
  hasAcceptedConsult: boolean;
  consultError: ConsultError;
  consultsError: ConsultError;
  patientConsultsError: ConsultError;
  generalConsultsMetricsError: ConsultError;
  providerConsultsMetricsError: ConsultError;
  acceptConsultError: ConsultError;
  rejectConsultError: ConsultError;
  endConsultError: ConsultError;
  updateConsultError: ConsultError;
  updatePatientProfileError: ConsultError;
}

export const initialState: ConsultState = {
  consult: null,
  consults: [],
  patientConsults: null,
  generalConsultsMetrics: null,
  providerConsultsMetrics: null,
  isConsultLoading: true,
  isConsultsLoading: true,
  isPatientConsultsLoading: true,
  isGeneralConsultsMetricsLoading: true,
  isProviderConsultsMetricsLoading: true,
  isUpdatedConsultSuccess: false,
  acceptConsultLoading: false,
  rejectConsultLoading: false,
  endConsultLoading: false,
  updateConsultLoading: false,
  updatePatientProfileLoading: false,
  hasAcceptedConsult: false,
  consultError: { message: "" },
  consultsError: { message: "" },
  patientConsultsError: { message: "" },
  generalConsultsMetricsError: { message: "" },
  providerConsultsMetricsError: { message: "" },
  acceptConsultError: { message: "" },
  rejectConsultError: { message: "" },
  endConsultError: { message: "" },
  updateConsultError: { message: "" },
  updatePatientProfileError: { message: "" }
};

export const consultsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // FETCH SINGLE CONSULTS
    fetchConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isConsultLoading = payload;
      // state.consult = null;
    },
    fetchConsultSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.consult = payload;
    },
    fetchConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.consultError = payload;
      state.consult = null;
    },
    // FETCH ALL CONSULTS
    fetchConsultsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isConsultsLoading = payload;
    },
    fetchConsultsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.consults = payload;
    },
    fetchConsultsFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.consultsError = payload;
    },
    // FETCH ALL PROVIDERS CONSULTS
    fetchPatientConsultsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPatientConsultsLoading = payload;
    },
    fetchPatientConsultsSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.patientConsults = payload;
    },
    fetchPatientConsultsFailed: (
      state,
      { payload }: PayloadAction<ConsultError>
    ) => {
      state.patientConsultsError = payload;
    },
    // FETCH GENERAL CONSULTS METRICS
    fetchGeneralConsultsMetricsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isGeneralConsultsMetricsLoading = payload;
    },
    fetchGeneralConsultsMetricsSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.generalConsultsMetrics = payload;
    },
    fetchGeneralConsultsMetricsFailed: (
      state,
      { payload }: PayloadAction<ConsultError>
    ) => {
      state.generalConsultsMetricsError = payload;
    },
    // FETCH PROVIDER CONSULTS METRICS
    fetchProviderConsultsMetricsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isProviderConsultsMetricsLoading = payload;
    },
    fetchProviderConsultsMetricsSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.providerConsultsMetrics = payload;
    },
    fetchProviderConsultsMetricsFailed: (
      state,
      { payload }: PayloadAction<ConsultError>
    ) => {
      state.providerConsultsMetricsError = payload;
    },
    // ACCEPT CONSULT
    acceptConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.acceptConsultLoading = payload;
      state.hasAcceptedConsult = false;
    },
    acceptConsultSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      // state.consults = payload;
      state.hasAcceptedConsult = true;
    },
    acceptConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.acceptConsultError = payload;
      state.hasAcceptedConsult = false;
    },
    // REJECT CONSULT
    rejectConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.rejectConsultLoading = payload;
    },
    rejectConsultSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      // state.consults = payload;
    },
    rejectConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.rejectConsultError = payload;
    },
    // END CONSULT
    endConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.endConsultLoading = payload;
    },
    endConsultSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      // state.consults = payload;
    },
    endConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.endConsultError = payload;
    },
    // UPDATE CONSULT
    updateConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.updateConsultLoading = payload;
      state.isUpdatedConsultSuccess = false;
    },
    updateConsultSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      state.consult = payload;
      state.isUpdatedConsultSuccess = true;
    },
    updateConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.updateConsultError = payload;
      state.isUpdatedConsultSuccess = false;
    },
    // END CONSULT
    updatePatientProfileLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.updatePatientProfileLoading = payload;
    },
    updatePatientProfileSuccess: (
      state,
      { payload }: PayloadAction<Boolean>
    ) => {
      // state.consults = payload;
    },
    updatePatientProfileFailed: (
      state,
      { payload }: PayloadAction<ConsultError>
    ) => {
      state.updatePatientProfileError = payload;
    }
  }
});

export const {
  fetchConsultLoading,
  fetchConsultSuccess,
  fetchConsultFailed,
  fetchConsultsLoading,
  fetchConsultsSuccess,
  fetchConsultsFailed,
  fetchPatientConsultsLoading,
  fetchPatientConsultsSuccess,
  fetchPatientConsultsFailed,
  fetchGeneralConsultsMetricsLoading,
  fetchGeneralConsultsMetricsSuccess,
  fetchGeneralConsultsMetricsFailed,
  fetchProviderConsultsMetricsLoading,
  fetchProviderConsultsMetricsSuccess,
  fetchProviderConsultsMetricsFailed,
  acceptConsultLoading,
  acceptConsultSuccess,
  acceptConsultFailed,
  rejectConsultLoading,
  rejectConsultSuccess,
  rejectConsultFailed,
  endConsultLoading,
  endConsultSuccess,
  endConsultFailed,
  updateConsultLoading,
  updateConsultSuccess,
  updateConsultFailed,
  updatePatientProfileLoading,
  updatePatientProfileSuccess,
  updatePatientProfileFailed
} = consultsSlice.actions;

export const consultSelector = (state: { consults: ConsultState }) =>
  state.consults;

export default consultsSlice.reducer;

/** Actions */

const environment =
  process.env.REACT_APP_ENVIRONMENT === "development" ? "sandbox" : "live";

export const fetchSingleConsult =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchConsultLoading(true));
    await http
      .get(`/consults/${id}`)
      .then((res) => {
        const consult = res?.data?.data;
        dispatch(fetchConsultSuccess(consult));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(fetchConsultFailed(message));
      });
    dispatch(fetchConsultLoading(false));
  };

export const fetchAllConsults =
  (query: string): AppThunk =>
  async (dispatch) => {
    dispatch(fetchConsultsLoading(true));
    await http
      .get(`/consults${query}&mode=${environment}`)
      .then((res) => {
        const consults = res?.data?.data;
        dispatch(fetchConsultsSuccess(consults));
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(fetchConsultsFailed(_message));
        message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(fetchConsultsLoading(false));
  };

export const fetchAllPatientConsults =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchPatientConsultsLoading(true));
    await http
      .get(`/patient/${id}`)
      .then((res) => {
        const consults = res?.data?.data;
        dispatch(fetchPatientConsultsSuccess(consults));
        // message.success("Patient Consults Fetched Successfully");
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(fetchPatientConsultsFailed(_message));
        message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(fetchPatientConsultsLoading(false));
  };

export const fetchGeneralConsultsMetrics = (): AppThunk => async (dispatch) => {
  dispatch(fetchGeneralConsultsMetricsLoading(true));
  await http
    .get(`/consults/metrics`)
    .then((res) => {
      const consults = res?.data?.data;
      dispatch(fetchGeneralConsultsMetricsSuccess(consults));
    })
    .catch((err) => {
      const _message = {
        message: err?.response?.data?.message || "An error occurred"
      };
      dispatch(fetchGeneralConsultsMetricsFailed(_message));
      message.error(err?.response?.data?.message || "An error occurred");
    });
  dispatch(fetchGeneralConsultsMetricsLoading(false));
};

export const fetchProviderConsultsMetrics =
  (): AppThunk => async (dispatch) => {
    dispatch(fetchProviderConsultsMetricsLoading(true));
    await http
      .get(`/consults/metrics?provider=true`)
      .then((res) => {
        const consults = res?.data?.data;
        dispatch(fetchProviderConsultsMetricsSuccess(consults));
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(fetchProviderConsultsMetricsFailed(_message));
        message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(fetchProviderConsultsMetricsLoading(false));
  };

export const acceptConsult =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(acceptConsultLoading(true));
    await http
      .post(`/consults/${id}/accept`)
      .then((res) => {
        dispatch(acceptConsultSuccess(true));
        dispatch(fetchAllConsults("?status=pending"));
        message.success("Consult Accepted Successfully");
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(acceptConsultFailed(_message));
        message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(acceptConsultLoading(false));
  };

export const rejectConsult =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(rejectConsultLoading(true));
    await http
      .post(`/consults/${id}/reject`)
      .then((res) => {
        dispatch(rejectConsultSuccess(true));
        dispatch(fetchAllConsults("?status=pending"));
        message.success("Consult Rejected Successfully");
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(rejectConsultFailed(_message));
        message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(rejectConsultLoading(false));
  };

export const endConsult =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(endConsultLoading(true));
    await http
      .post(`/consults/${id}/end`)
      .then((res) => {
        dispatch(endConsultSuccess(true));
        dispatch(fetchAllConsults("?status=accepted"));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(endConsultFailed(message));
      });
    dispatch(endConsultLoading(false));
  };

export const updateConsult =
  (id: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updateConsultLoading(true));
    await http
      .patch(`/consults/${id}`, payload)
      .then((res) => {
        dispatch(updateConsultSuccess(res.data?.data));
        // dispatch(fetchAllPatientConsults(id))
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(updateConsultFailed(message));
      });
    dispatch(updateConsultLoading(false));
  };

export const updatePatientProfile =
  (id: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updatePatientProfileLoading(true));
    await http
      .patch(`/patient/${id}/medical_record`, payload)
      .then((res) => {
        dispatch(updatePatientProfileSuccess(true));
        message.success("Patient's profile was updated successfully");
        dispatch(fetchAllPatientConsults(id));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(updatePatientProfileFailed(message));
      });
    dispatch(updatePatientProfileLoading(false));
  };

export const deletePatientProfile =
  (id: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updatePatientProfileLoading(true));
    await http
      .delete(`/patient/${id}/medical_record`, { data: payload })
      .then((res) => {
        dispatch(updatePatientProfileSuccess(true));
        dispatch(fetchAllPatientConsults(id));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(updatePatientProfileFailed(message));
      });
    dispatch(updatePatientProfileLoading(false));
  };
