import { BrowserRouter, Switch } from "react-router-dom";
import { useEffect } from "react";
import { capitalize } from "lodash";

// @ts-ignore
import Engage from "@engage_so/js";

// Layouts
import DashboardLayoutRoute from "./layouts/DashboardLayout";
import OnboardingLayoutRoute from "./layouts/OnboardingLayout";

// Onboarding Screens
import Login from "./screens/Onboarding/Login";
import Register from "./screens/Onboarding/Register";
import RecoverPassword from "./screens/Onboarding/RecoverPassword";
import ResetPassword from "./screens/Onboarding/ResetPassword";
import RequestOTP from "./screens/Onboarding/RequestOTP";
import EnterOTP from "./screens/Onboarding/EnterOTP";

// Dashboard Screens
import Dashboard from "./screens/Dashboard";
import Profile from "./screens/Profile";
import Encounters from "./screens/Encounters";
import Questions from "./screens/Questions";

import { ROUTES } from "./constants/routes";
import "./App.css";
import "antd/dist/antd.css";
import TermsAndConditions from "./screens/Onboarding/TermsAndConditions";
import pusher from "./utils/pusher";
import { triggerPushNotification } from "./utils/notifications";
import { Mixpanel } from "utils/mixpanel";

import ErrorBoundary from "ErrorBoundary";
import { isNotificationSupported } from "utils/utils";

const App = () => {
  // init engage_so
  Engage.init(process.env.REACT_APP_ENGAGE_PUBLIC_KEY);

  useEffect(() => {
    if (isNotificationSupported() && Notification.permission !== "granted") {
      Notification.requestPermission()
        .then(() => console.log("Notification permissions granted"))
        .catch((err) => err);
    }
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe("queries");
    channel.bind("new_query", async (data: any) => {
      await triggerPushNotification(
        "New Question",
        "A new question has been created. Click to view questions.",
        "questions"
      );
    });

    return () => {
      pusher.unsubscribe("queries");
    };
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe("consults");
    channel.bind("consult_created", async (data: any) => {
      await triggerPushNotification(
        "New Consult",
        "An unassigned consult has been created. Click to view consults.",
        "dashboard"
      );
    });

    return () => {
      pusher.unsubscribe("consults");
    };
  }, []);

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("pneumaCurrentUser")!);

    const channel = pusher.subscribe("providers");
    channel.bind("messages", async (data: any) => {
      if (profile && data.provider_id === String(profile?.id)) {
        await triggerPushNotification(
          "New Message",
          `A new consult message has been received from ${capitalize(
            data?.sender?.first_name
          )}. Click to view encounters.`,
          "encounters"
        );

        Mixpanel.track("message_received", {});
      }
    });

    return () => {
      pusher.unsubscribe("providers");
    };
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Switch>
          <OnboardingLayoutRoute
            exact
            path={ROUTES.HOMEPAGE}
            component={Login}
          />
          <OnboardingLayoutRoute exact path={ROUTES.LOGIN} component={Login} />
          <OnboardingLayoutRoute
            exact
            path={ROUTES.REGISTER}
            component={Register}
          />
          <OnboardingLayoutRoute
            exact
            path={ROUTES.TERMS_AND_CONDITIONS}
            component={TermsAndConditions}
          />
          <OnboardingLayoutRoute
            exact
            path={ROUTES.RECOVER_PASSWORD}
            component={RecoverPassword}
          />
          <OnboardingLayoutRoute
            exact
            path={ROUTES.RESET_PASSWORD}
            component={ResetPassword}
          />
          <OnboardingLayoutRoute
            exact
            path={ROUTES.REQUEST_OTP}
            component={RequestOTP}
          />
          <OnboardingLayoutRoute
            exact
            path={ROUTES.ENTER_OTP}
            component={EnterOTP}
          />

          {/* Dashboard Screens */}
          <DashboardLayoutRoute
            exact
            path={ROUTES.DASHBOARD}
            component={Dashboard}
          />
          <DashboardLayoutRoute
            exact
            path={ROUTES.PROFILE}
            component={Profile}
          />

          <DashboardLayoutRoute
            exact
            path={ROUTES.ENCOUNTERS}
            component={Encounters}
          />
          <DashboardLayoutRoute
            exact
            path={ROUTES.PATIENT_ENCOUNTERS}
            component={Encounters}
          />

          <DashboardLayoutRoute
            exact
            path={ROUTES.QUESTIONS}
            component={Questions}
          />
          <DashboardLayoutRoute component={<div>404 page</div>} />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
