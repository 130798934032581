import styled from "styled-components";

const PatientEncountersWrapper = styled.div`
  width: 100%;
  height: 100%;

  h3 {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #4e667e;
  }
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 23px 57px 23px 25px;
  background: #f3f8fb;
  height: 100%;
  overflow: auto;

  .update-profile-btn {
    margin: 50px 0px 0px 50px;
  }
`;

export { PatientEncountersWrapper, ContentWrapper };
