import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http from "../../utils/api";
import { message } from "antd";

export interface AuthError {
  message: string;
}

export interface AuthState {
  isAuth: boolean;
  currentUser: any;
  isLoading: boolean;
  isProfileLoading: boolean;
  updateProfileLoading: boolean;
  error: AuthError;
  profileError: AuthError;
  updateProfileError: AuthError;
}

export interface CurrentUser {
  id: string;
  display_name: string;
  email: string;
  photo_url: string;
}
export const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  currentUser: null,
  isProfileLoading: false,
  updateProfileLoading: false,
  error: { message: "" },
  profileError: { message: "" },
  updateProfileError: { message: "" }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setAuthSuccess: (state, payload: PayloadAction<CurrentUser>) => {
      state.currentUser = payload;
      state.isAuth = true;
    },
    setLogOut: (state) => {
      state.isAuth = false;
      state.currentUser = undefined;
    },
    setAuthFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.error = payload;
      state.isAuth = false;
    },
    fetchProfileLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isProfileLoading = payload;
    },
    fetchProfileSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
    },
    fetchProfileFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.profileError = payload;
    },
    updateProfileLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.updateProfileLoading = payload;
    },
    updateProfileSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
    },
    updateProfileFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.updateProfileError = payload;
    }
  }
});

export const {
  setAuthSuccess,
  setLogOut,
  setLoading,
  setAuthFailed,
  fetchProfileLoading,
  fetchProfileSuccess,
  fetchProfileFailed,
  updateProfileLoading,
  updateProfileSuccess,
  updateProfileFailed
} = authSlice.actions;
export const authSelector = (state: { auth: AuthState }) => state.auth;
export default authSlice.reducer;

/** Actions */

export const loginProvider =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    await http
      .post("/login", payload)
      .then((res) => {
        const { token: accessToken, user: currentUser } = res?.data?.data || {};
        dispatch(setAuthSuccess(currentUser));
        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem(
            "pneumaCurrentUser",
            JSON.stringify(currentUser)
          );
          window.open("/dashboard", "_self");
        }
      })
      .catch((err) => {
        // console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(setAuthFailed(message));
      });
    dispatch(setLoading(false));
  };

export const registerProvider =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    await http
      .post("/register", payload)
      .then((res) => {
        message.success("Registration Successful!");
        window.open("/login", "_self");
      })
      .catch((err) => {
        // console.log("err..", err?.response?.data);
        const message = { message: err?.response?.data?.message };
        dispatch(setAuthFailed(message));
      });
    dispatch(setLoading(false));
  };

export const fetchProfile = (): AppThunk => async (dispatch) => {
  dispatch(fetchProfileLoading(true));
  await http
    .get("/profile")
    .then((res) => {
      const currentUser = res?.data?.data;
      dispatch(fetchProfileSuccess(currentUser));
      localStorage.setItem("pneumaCurrentUser", JSON.stringify(currentUser));
    })
    .catch((err) => {
      // console.log("err..", err?.response?.data);
      const message = {
        message: err?.response?.data?.message || err?.response?.data?.error
      };
      dispatch(fetchProfileFailed(message));
    });
  dispatch(fetchProfileLoading(false));
};

export const updateProviderProfile =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updateProfileLoading(true));
    await http
      .patch("/profile", payload)
      .then((res) => {
        const currentUser = res?.data?.data || {};
        dispatch(updateProfileSuccess(currentUser));
        dispatch(fetchProfile());
        message.success("Profile Updated");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(updateProfileFailed(message));
      });
    dispatch(updateProfileLoading(false));
  };

export const updateProviderProfileRecords =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updateProfileLoading(true));
    await http
      .patch("/profile/records", payload)
      .then((res) => {
        const currentUser = res?.data?.data || {};
        dispatch(updateProfileSuccess(currentUser));
        dispatch(fetchProfile());
        message.success("Profile Updated");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(updateProfileFailed(message));
      });
    dispatch(updateProfileLoading(false));
  };

export const updateProviderProfileDeletion =
  (payload: any, shouldReload?: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(updateProfileLoading(true));
    await http
      .delete("/profile", {
        data: payload
      })
      .then((res) => {
        if (shouldReload) {
          message.success("Profile Updated");
          dispatch(fetchProfile());
        }
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(updateProfileFailed(message));
      });
    dispatch(updateProfileLoading(false));
  };

export const logOutProvider = (): AppThunk => async (dispatch) => {
  dispatch(setLogOut());
  localStorage.removeItem("accessToken");
  localStorage.removeItem("pneumaCurrentUser");
  window.location.replace("/");
};
