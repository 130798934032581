import { useEffect, useState } from "react";
import { Spin, Input, Pagination } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "redux/store";
import { consultSelector, fetchAllConsults } from "redux/reducers/consults";
import AvatarImg from "component/Avatar";

import { ReactComponent as SearchIcon } from "assets/icons/search-icon.svg";
import { LinksWrapper, Sidebar, PaginationWrapper } from "./styles";

type Props = {
  showConsultDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

function EncountersList({ showConsultDetails }: Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { consults, isConsultsLoading } = useAppSelector(consultSelector);

  const [filteredConsults, setFilteredConsults] = useState<any>();
  const [pageNumber, setPageNumber] = useState(1);
  const DEFAULT_PAGE_SIZE = 10;
  const startPageCount = (pageNumber - 1) * DEFAULT_PAGE_SIZE;
  const [searchTerm, setSearchTerm] = useState("");

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const providerDetails = JSON.parse(
      localStorage.getItem("pneumaCurrentUser")!
    );

    if (consults) {
      setFilteredConsults(
        consults.filter(
          (consult) => consult?.provider?.id === providerDetails?.id
        )
      );
    }
  }, [consults]);

  useEffect(() => {
    const query = "/user?status=accepted";
    dispatch(fetchAllConsults(query));
  }, [dispatch]);

  function handleSearch(e: any) {
    const keyword = e.target.value;
    setSearchTerm(keyword);

    setFilteredConsults(() =>
      consults.filter((consult: any) => {
        const { first_name, last_name } = consult?.patient_record;
        const natureOfEncounter = consult?.form?.name;

        return (
          first_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          last_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          `${first_name} ${last_name}`
            ?.toLowerCase()
            .includes(keyword.toLowerCase()) ||
          `${last_name} ${first_name}`
            ?.toLowerCase()
            .includes(keyword.toLowerCase()) ||
          natureOfEncounter?.toLowerCase().includes(keyword.toLowerCase())
        );
      })
    );
  }

  const isActivePath = (path: string) => location.search.includes(path);

  return (
    <Sidebar>
      <h2>
        Ongoing encounters{" "}
        {filteredConsults ? `(${filteredConsults.length})` : null}
      </h2>

      {/* search  */}
      <div className='search-wrapper'>
        <Input
          size='large'
          placeholder='Search by patient or form name'
          onChange={handleSearch}
          value={searchTerm}
        />

        <SearchIcon />
      </div>

      {/* encounters */}
      <LinksWrapper>
        {isConsultsLoading ? (
          <div className='loading-wrapper'>
            <Spin />
          </div>
        ) : filteredConsults?.length ? (
          <div className='consults-list'>
            {filteredConsults
              ?.slice(startPageCount, startPageCount + DEFAULT_PAGE_SIZE)
              ?.map((consult: any) => {
                const { first_name, last_name, id } = consult?.patient_record;
                const businessName = consult?.partner?.business_name;
                const natureOfEncounter = consult?.form?.name;
                const threadStatus = consult?.thread?.status;

                return (
                  <div
                    key={consult.id}
                    className={`single-consult ${
                      isActivePath(`${consult?.id}`) ? "active" : ""
                    }`}
                    onClick={() => {
                      showConsultDetails(true);
                      history.push(
                        `/encounters/${id}?consultId=${consult?.id}`
                      );
                    }}
                  >
                    {threadStatus === "opened" ? (
                      <span className='thread-active-indicator' />
                    ) : null}

                    <div className='header'>
                      <div className='name'>
                        <AvatarImg
                          bg='#0FAFF0'
                          img=''
                          style={{ marginRight: 8, fontSize: "12px" }}
                          fallbackText={`${first_name} ${last_name}`}
                        />
                        <h5>
                          {first_name} {last_name}
                        </h5>
                      </div>

                      <div className='date'>
                        <h6>
                          {moment(consult.updated_at)
                            .startOf("minutes")
                            .fromNow()}
                        </h6>
                      </div>
                    </div>

                    <div className='form'>
                      <h4>{natureOfEncounter}</h4>
                      <h5>{businessName}</h5>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <h5 className='no-consults'>No consults yet</h5>
        )}
      </LinksWrapper>

      {!isConsultsLoading && filteredConsults?.length > 0 ? (
        <PaginationWrapper>
          <Pagination
            responsive
            showLessItems={true}
            showSizeChanger={false}
            defaultCurrent={1}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            onChange={handlePageChange}
            total={filteredConsults?.length}
          />
        </PaginationWrapper>
      ) : null}
    </Sidebar>
  );
}

export default EncountersList;
