import styled from "styled-components";

const EncounterWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-wrap: wrap;
  background-color: #f4f8fb;
`;

const ContentWrapper = styled.div`
  padding: 25px;
  background: #f3f8fb;
  min-height: 100%;
  min-height: calc(100vh - 57px);
  padding-bottom: 100px;
  overflow-y: scroll;
  .update-profile-btn {
    margin: 50px 0px 0px 50px;
  }
`;

const BlankContentWrapper = styled.div`
  background: #fff;
  height: 100%;
  box-shadow: 0px 3px 6px rgba(0, 120, 226, 0.1);
`;

const AddTextWrapper = styled.p`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  margin-top: 5px;
  cursor: pointer;
  color: #0078e2;
  img {
    margin-right: 7px;
  }
`;

export {
  EncounterWrapper,
  ContentWrapper,
  BlankContentWrapper,
  AddTextWrapper
};
