import styled from "styled-components";

interface StatsCardProps {
  active: boolean;
}

const DashboardWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 57px);
  h3 {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #4e667e;
  }
`;

const ContentWrapper = styled.div`
  background: #f3f8fb;
  min-height: 100%;
  padding: 40px 10px 10px;
  min-height: calc(100vh - 57px);
  position: relative;

  @media (min-width: 768px) {
    padding: 60px 20px 20px;
  }

  @media (min-width: 1024px) {
    padding: 60px 40px 40px;
  }

  @media (min-width: 1300px) {
    padding: 36px 100px 100px;
  }

  .LHS {
    @media (min-width: 1300px) {
      padding: 24px 24px 20px;
    }
  }

  .RHS {
    position: fixed;
    width: inherit;
    max-width: inherit;

    .ant-card {
      min-height: calc(100vh - 57px);
      width: 360px;
      right: 0px;
    }
  }
`;

const StatsCardWrapper = styled.div<StatsCardProps>`
  cursor: pointer;

  .ant-card {
    border-radius: 0px;
    box-shadow: 0px 0px 3px #0078e21a;
    ${(props) => props.active && `border: 1px solid #0078E2BF;`}
  }

  h3 {
    text-align: left;
    letter-spacing: 0px;
    color: #002e46;
    word-break: break-all;
    white-space: pre-line;
    margin-bottom: 8px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #475569;

    @media (min-width: 1024px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (min-width: 1200px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  h2 {
    text-align: left;
    letter-spacing: 0px;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 38px;
    color: #0d0d0d;

    @media (min-width: 1024px) {
      font-size: 40px;
      line-height: 44px;
    }

    @media (min-width: 1200px) {
      font-size: 48px;
      line-height: 62px;
    }
  }
`;

const AddTextWrapper = styled.p`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  margin-top: 5px;
  cursor: pointer;
  color: #0078e2;
  img {
    margin-right: 7px;
  }
`;

export const TableHeader = styled.div`
  display: none;
  padding: 21px 26px 17px;
  background: #f3f8fb 0% 0% no-repeat padding-box;
  border-top: 0.5px solid rgba(78, 102, 126, 0.35);
  border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
  p {
    margin-bottom: 0px;
    text-align: left;
    font: normal normal medium 13px/24px DM Sans;
    letter-spacing: 0px;
    color: #002e46;
    opacity: 0.75;
  }
  @media (min-width: 768px) {
    display: block;
  }
`;

export const WelcomeWrapper = styled.div`
  margin-bottom: 28px;

  h3 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    margin: 0;
  }

  p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
`;

export { DashboardWrapper, ContentWrapper, StatsCardWrapper, AddTextWrapper };
