import {
  FamilyHistoryModalWrapper,
  FamilyHistoryModalBodyWrapper,
} from "./styles";
import { Col, Modal, Row, Form, Select as AntdSelect, Spin } from "antd";
import { useEffect, useState } from "react";
import Button from "../../../../component/Button";
import Select from "../../../../component/Select";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import { diagnosisSelector } from "../../../../redux/reducers/diagnoses";
import {
  updatePatientProfile,
  deletePatientProfile,
} from "../../../../redux/reducers/consults";

const familyMembers = [
  "Father",
  "Mother",
  "Sister",
  "Brother",
  "Son",
  "Daughter",
  "Cousin",
  "Nephew",
  "Niece",
  "Spouse",
  "Grandfather",
  "Grandmother",
  "Uncle",
  "Aunt",
];

const FamilyHistoryModal = (props: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [currentFormObjectIds, setCurrentFormObjectIds] = useState<any[]>([]);
  const {
    patientID,
    medicalHistory,
    isFamilyHistoryModalVisible,
    setIsFamilyHistoryModalVisible,
  } = props;

  const { diagnoses, isDiagnosesLoading, diagnosesError } =
    useSelector(diagnosisSelector);

  useEffect(() => {
    if (!medicalHistory || medicalHistory?.length === 0) {
      setData([
        {
          condition: "",
          family_member: [],
        },
      ]);
    } else {
      const formattedData = medicalHistory.map((item: any) => {
        const key = Object.keys(item)[0];
        const values = item[key];
        return { condition: key, family_member: values };
      });
      setData(formattedData);
      setCurrentFormObjectIds(formattedData.map((item: any) => item.condition))
    }
  }, [medicalHistory, isFamilyHistoryModalVisible]);

  const updateFormObjects = (value: any, index: number) => {
    const _currentFormObjectIds = [...currentFormObjectIds];
    _currentFormObjectIds[index] = value;
    setCurrentFormObjectIds(_currentFormObjectIds)
  }

  const handleSubmit = async (values: any) => {
    const { fields } = values;

    const addPayload = lodash.differenceWith(fields, data, lodash.isEqual).map((item: any) => {
      return {
        [item.condition]: item.family_member,
      };
    });
    const removePayload = lodash.differenceWith(data, fields, lodash.isEqual).map((item: any) => {
      return item.condition;
    });

    // console.log(addPayload, removePayload)

    if (addPayload.length > 0) {
      await dispatch(
        updatePatientProfile(patientID, { family_medical_history: addPayload } )
      );
    }
    if (removePayload.length > 0) {
      await dispatch(deletePatientProfile(patientID, {
        family_medical_history: removePayload
      }));
    }
    setIsFamilyHistoryModalVisible(false);
  };

  return (
    <Modal
      title="Family Medical History"
      visible={isFamilyHistoryModalVisible}
      centered
      cancelText="Cancel"
      footer={null}
      width={800}
      onCancel={() => setIsFamilyHistoryModalVisible(false)}
      className="medications-modal main-modal"
    >
      <FamilyHistoryModalWrapper>
        <FamilyHistoryModalBodyWrapper>
          <Row justify="center">
            <Col span={22} className="medications-rhs">
              <Form
                name="dynamic_form_item"
                layout="vertical"
                requiredMark={false}
                initialValues={{ fields: data }}
                form={form}
                onFinish={handleSubmit}
              >
                <Form.List name="fields">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Row gutter={26} align="middle">
                            <Col span={10}>
                              <Form.Item
                                name={[index, "condition"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "condition is required",
                                  },
                                ]}
                              >
                                <Select
                                  formItem={{
                                    label: "Condition",
                                    rules: [
                                      {
                                        required: true,
                                        message: "Please input the condition",
                                      },
                                    ],
                                  }}
                                  showSearch
                                  suffixIcon={null}
                                  marginBottom={0}
                                  notFoundContent={
                                    isDiagnosesLoading ? (
                                      <Spin size="small" />
                                    ) : null
                                  }
                                  onChange={(value: any) => updateFormObjects(value, index)}
                                  optionFilterProp="children"
                                  filterOption={(input: any, option: any) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  placeholder="Select a diagnosis"
                                  label=""
                                  required
                                >
                                  {diagnoses.map((option, index) => {
                                    const isActive = currentFormObjectIds.some((datum: any) => datum === option.condition);
                                    return (
                                      <AntdSelect.Option
                                        key={index}
                                        value={option.condition}
                                        disabled={isActive}
                                      >
                                        {option.condition}
                                      </AntdSelect.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={[index, "family_member"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Family member is required",
                                  },
                                ]}
                              >
                                <Select
                                  formItem={{
                                    label: "Family member",
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          "Please input the family member",
                                      },
                                    ],
                                  }}
                                  mode="tags"
                                  showSearch
                                  suffixIcon={null}
                                  marginBottom={0}
                                  optionFilterProp="children"
                                  filterOption={(input: any, option: any) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  placeholder="Select a family member"
                                  label=""
                                  required
                                >
                                  {familyMembers.map((option, index) => {
                                    return (
                                      <AntdSelect.Option
                                        key={index}
                                        value={option}
                                      >
                                        {option}
                                      </AntdSelect.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              {fields.length > 0 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Form.Item style={{ textAlign: "center" }}>
                        <Button
                          type="link"
                          className="btn-add"
                          onClick={() => add()}
                        >
                          <PlusOutlined />
                          Add another record
                        </Button>
                        {/* <Form.ErrorList errors={errors} /> */}
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    className="btn-submit"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </FamilyHistoryModalBodyWrapper>
      </FamilyHistoryModalWrapper>
    </Modal>
  );
};

export default FamilyHistoryModal;
