import styled from 'styled-components';

const FilePreviewWrapper = styled.div`
    /* display: flex; */
    height: 200px;
    padding: 0px;
    /* padding-top: 10px; */
    object-fit: cover;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: inline-block;
    width: auto;
    border-radius: 6px;
    img {
        border: 2px solid #a3a3a3;
        height: 100%;
        border-radius: 6px;
    }
`;

export { FilePreviewWrapper }