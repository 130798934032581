import styled from 'styled-components';

const DatePickerWrapper = styled.div`
    label, .ant-form-item-label {
        font-weight: 500;
        font-size: 13px;
        text-align: left;
        color: #002e46;
        padding: 0px;
    }
    .ant-picker {
        border: none;
        padding: 5px 0px;
        border-bottom: 1px solid #BFCAD0;
        border-radius: 0px;
        width: 100%;
        &:focus, &.ant-picker-focused {
            box-shadow: none;
        }
    }
    .ant-form-item-explain, .ant-form-item-extra {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 10px;
        text-align: left;
        color: rgba(0, 46, 70, 0.75);
    }
`;

export { DatePickerWrapper }