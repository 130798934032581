import { Upload as AntdUpload } from "antd"
import Button from "../Button";
import { EditOutlined } from '@ant-design/icons';
import { UploadWrapper } from "./styles";

const Upload = (props: any) => {
    const { type, children } = props;
    return (
        <UploadWrapper>
            <AntdUpload {...props}>
                <Button type={type} icon={<EditOutlined />}>{children}</Button>
            </AntdUpload>
        </UploadWrapper>
    )
}

export default Upload;