import { Col, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../component/Card";
import CompletedConsultsTable from "./containers/CompletedConsults";
import ConsultTable from "./containers/ConsultTable";
import OngoingConsultsTable from "./containers/OngoingConsultsTable";
import {
  consultSelector,
  fetchGeneralConsultsMetrics,
  fetchProviderConsultsMetrics
} from "../../redux/reducers/consults";

import {
  DashboardWrapper,
  ContentWrapper,
  StatsCardWrapper,
  WelcomeWrapper
} from "./styles";
import CanceledConsultsTable from "./containers/CanceledConsults";

const Dashboard = () => {
  const [view, setView] = useState(1);
  const dispatch = useDispatch();
  const {
    generalConsultsMetrics,
    providerConsultsMetrics,
    isGeneralConsultsMetricsLoading,
    isProviderConsultsMetricsLoading
  } = useSelector(consultSelector);

  useEffect(() => {
    dispatch(fetchProviderConsultsMetrics());
    dispatch(fetchGeneralConsultsMetrics());
  }, [dispatch]);

  const { pending, rejected } = generalConsultsMetrics || {};
  const { ongoing, ended } = providerConsultsMetrics || {};

  const statsData = [
    {
      title: `Consult requests`,
      value: pending,
      loading: isGeneralConsultsMetricsLoading
    },
    {
      title: "Ongoing consults",
      value: ongoing,
      loading: isProviderConsultsMetricsLoading
    },
    {
      title: "Completed consults",
      value: ended,
      loading: isProviderConsultsMetricsLoading
    },
    {
      title: "Rejected consults",
      value: rejected,
      loading: isGeneralConsultsMetricsLoading
    }
  ];

  const displayedTable =
    view === 1 ? (
      <ConsultTable />
    ) : view === 2 ? (
      <OngoingConsultsTable />
    ) : view === 3 ? (
      <CompletedConsultsTable />
    ) : view === 4 ? (
      <CanceledConsultsTable />
    ) : null;

  const profile = JSON.parse(localStorage.getItem("pneumaCurrentUser") || "");
  const { first_name } = profile || {};

  return (
    <DashboardWrapper>
      <ContentWrapper>
        <Row gutter={0}>
          <Col span={24}>
            <div className='LHS'>
              <WelcomeWrapper>
                <h3>Welcome back, {first_name}</h3>
                <p>Here is a summary of all your consultations</p>
              </WelcomeWrapper>

              <Row gutter={2}>
                {statsData.map(({ title, value, loading }, index) => {
                  return (
                    <Col xs={12} md={6}>
                      <StatsCardWrapper
                        onClick={() => setView(index + 1)}
                        active={index + 1 === view}
                      >
                        <Card>
                          {loading ? (
                            <Skeleton paragraph={{ rows: 1 }} />
                          ) : (
                            <>
                              <h3>{title}</h3>
                              <h2>{value}</h2>
                            </>
                          )}
                        </Card>
                      </StatsCardWrapper>
                    </Col>
                  );
                })}
              </Row>
              {displayedTable}
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    </DashboardWrapper>
  );
};

export default Dashboard;
