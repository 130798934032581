import styled from "styled-components";

const OngoingConsultsTableWrapper = styled.div``;

const OngoingConsultsTableHeader = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  ul {
    list-style-type: none;
    list-style: none;
    margin: 0;
    padding: 0px 26px;
    li {
      display: block;
      float: left;
      padding: 20px 10px 10px 10px;
      margin-right: 24px;
      cursor: pointer;
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      text-align: left;
      color: #002e46;
      &.active {
        color: #0078e2;
        border-bottom: 3px solid #0078e2;
      }
    }
  }
`;

const OngoingConsultsTableBody = styled.div`
  padding: 16px;
  .ant-table {
    .ant-table-thead > tr > th {
      background: #f3f8fb;
      border-top: 0.5px solid rgba(78, 102, 126, 0.35);
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
    }
    .ant-table-tbody > tr > td {
      background: #fff;
    }
    tr {
      td:first-child {
        padding: 10px 28px;
      }
      td:last-child {
        padding: 10px 28px;
        span {
          font-family: "DM Sans";
          font-weight: normal;
          font-size: 13px;
          text-align: left;
          color: #fff;
          opacity: 1;
        }
      }
    }
    th.ant-table-cell {
      text-align: left;
      font: normal normal medium 13px/24px DM Sans;
      letter-spacing: 0px;
      color: #002e46;
      opacity: 0.75;
    }
    td.ant-table-cell {
      font-family: "DM Sans";
      font-weight: normal;
      font-size: 13px;
      line-height: 14px;
      text-align: left;
      color: #002e46;
      opacity: 0.75;
      .flex {
        display: flex;
        img {
          margin-right: 10px;
          border: 0.8px solid #0078e2;
          border-radius: 50%;
          height: 100%;
          padding: 2px;
        }
      }
      .tag {
        background: #c1eee4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0abb94;
        opacity: 0.75;
        border-radius: 10px;
      }
      .action {
        img {
          margin-left: 10px;
        }
      }
      p {
        margin-bottom: 5px;
      }
      span {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #0078e2;
        opacity: 0.75;
      }
    }
  }
`;

export {
  OngoingConsultsTableWrapper,
  OngoingConsultsTableHeader,
  OngoingConsultsTableBody,
};
