import { Link, useLocation } from "react-router-dom";
import { Drawer, Grid } from "antd";
import { SidebarMenuList, SidebarMenuListItem, SidebarWrapper } from "./styles";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as EncounterIcon } from "../../assets/icons/encounters.svg";
import { ReactComponent as CareBlogIcon } from "../../assets/icons/care-blog.svg";
import useSidebarState from "hooks/useSidebarState";

const Sidebar = (props: any) => {
  const location = useLocation();
  const isActivePath = (path: string) => location.pathname.includes(path);
  const { isSidebar, toggleSidebar } = useSidebarState();

  const breakpoints = Grid.useBreakpoint();

  const sidebarMenu = [
    {
      title: "Dashboard",
      route: "/dashboard",
      icon: (
        <DashboardIcon
          fill={isActivePath("/dashboard") ? "#0078e2" : "#002e46"}
        />
      )
    },
    {
      title: "Encounters",
      route: "/encounters",
      icon: (
        <EncounterIcon
          fill={isActivePath("/encounters") ? "#0078e2" : "#002e46"}
        />
      )
    },
    {
      title: "Questions",
      route: "/questions",
      icon: (
        <CareBlogIcon
          fill={isActivePath("/questions") ? "#0078e2" : "#002e46"}
        />
      )
    }
  ];

  const menu = (
    <SidebarMenuList>
      {sidebarMenu.map((item) => {
        const { title, route, icon } = item;
        const active = isActivePath(route);
        return (
          <SidebarMenuListItem active={active}>
            <Link to={route}>
              {icon}
              {title}
            </Link>
          </SidebarMenuListItem>
        );
      })}
    </SidebarMenuList>
  );
  if (breakpoints.lg) {
    return <SidebarWrapper>{menu}</SidebarWrapper>;
  }

  return (
    <Drawer
      bodyStyle={{ padding: 0, paddingTop: 32 }}
      visible={isSidebar}
      onClose={toggleSidebar}
      placement='left'
    >
      {menu}
    </Drawer>
  );
};

export default Sidebar;
