import styled from "styled-components";

export const Wrapper = styled.div`
  background: #f3f8fb;
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  @media (min-width: 768px) {
    position: relative;
    padding-top: 0;
    top: auto;
  }

  > span {
    display: inline-flex;
    margin-left: 24px;
    align-items: center;
    gap: 6px;
    justify-content: center;
    border: 1px solid gray;
    padding: 6px 18px;
    border-radius: 4px;

    @media (min-width: 768px) {
      display: none;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex: 3;
    overflow-x: scroll;
    height: calc(100vh - 80px);
  }

  .empty-state {
    height: 100%;
    width: 100%;
    display: grid;
    place-content: center;

    h3 {
      font-weight: 700;
      font-size: 36px;
      text-align: center;
      font-family: "DM Sans";
      margin-bottom: 0;
      color: rgb(78, 102, 126);
    }

    p {
      font-family: "DM Sans", sans-serif;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      text-align: center;
    }
  }
`;
