import styled from 'styled-components';

interface IProps {
    isActive: boolean;
}

const ExaminationsModalWrapper = styled.div`
    min-height: calc(100vh - 245px);
    max-height: calc(100vh - 245px);
    overflow-y: scroll;
    padding: 20px 51px 20px !important;
    p.title {
        font-size: 13px;
        margin-bottom: 10px;
    }
`;

const ExaminationListItem = styled.div<IProps>`
    background: ${(props) => props.isActive ? "#f2f2f2": "#FFFFFF" } 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px ${(props) => props.isActive ? "transparent": "#002E460D" };
    /* border: 0.800000011920929px solid #002E4640; */
    border: 0.800000011920929px solid ${(props) => props.isActive ? "#0078E2": "#002E4640" };
    border-radius: 5px;
    opacity: 1;
    padding: 18px 17px 0px;
    margin-bottom: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .d-flex {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    .exam_textarea {
        width: 100%;
        .buttons_pane {
            margin-bottom: 16px;
            text-align: right;
            .btn-edit {
                font-size: 13px;
                padding: 5px 20px;
                border: none;
                background: #0078E2;
                color: #fff;
                border-radius: 6px;
                cursor: pointer;
            }
        }
    }
    span.link, span.action {
        cursor: pointer;
        font-size: 13px;
    }
    img {
        cursor: pointer;
    }

    .title {
        text-align: left;
        font: normal normal normal 14px/17px DM Sans;
        letter-spacing: 0px;
        color: ${(props) => props.isActive ? "#4E667E": "#4E667E" };
        opacity: 1;
    }

    .action {
        text-align: right;
        font: normal normal medium 13px/24px DM Sans;
        letter-spacing: 0px;
        /* color: #0078E2; */
        color: ${(props) => props.isActive ? "#4E667E": "#4E667E" };
        opacity: 1;
        cursor: pointer;
        &.remove {
            color: #333;
        }
        .anticon {
            margin-right: 10px;
        }
    }
    &:first-child {
        margin-top: 20px;
    }
    &:last-child {
        margin-bottom: 15px;
    }
`;

export { ExaminationsModalWrapper, ExaminationListItem };