import styled from 'styled-components';

const ConsultTableWrapper = styled.div`

`;

const ConsultTableHeader = styled.div`
    height: auto;
    display: flex;
    align-items: center;
    ul {
        list-style-type: none;
        list-style: none;
        margin: 0;
        padding: 0px 26px;
        li {
            display: block;
            float: left;
            padding: 20px 10px 10px 10px;
            margin-right: 44px;
            cursor: pointer;
            font-family: "DM Sans";
            font-weight: 500;
            font-size: 13px;
            text-align: left;
            color: #002e46;
            &.active {
                color: #0078e2;
                border-bottom: 3px solid #0078e2;
            }
        }
    }
`;

const ConsultTableBody = styled.div`
    .ant-table {
        .ant-table-thead > tr > th {
            background: #f3f8fb;
            /* border-top: 0.5px solid rgba(78, 102, 126, 0.35); */
            border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
        }
        .ant-table-tbody > tr > td {
            background: #fff;
        }
        tr {
            td:first-child, th:first-child {
                padding: 10px 40px;
            }
        }
        th.ant-table-cell {
            font-family: "DM Sans";
            font-weight: bold;
            font-size: 13px;
            line-height: 24px;
            text-align: left;
            color: #002e46;
            opacity: 0.75;
        }
        td.ant-table-cell {
            font-family: "DM Sans";
            font-weight: normal;
            font-size: 13px;
            line-height: 14px;
            text-align: left;
            color: #002e46;
            opacity: 0.75;
            .flex {
                display: flex;
                img {
                    margin-right: 10px;
                    border: 0.8px solid #0078e2;
                    border-radius: 50%;
                    height: 100%;
                    padding: 2px;
                }
            }
            .tag {
                background: #C1EEE4;
                padding: 5px 10px;
                font-family: "DM Sans";
                font-weight: 500;
                font-size: 13px;
                line-height: 14px;
                text-align: center;
                color: #0ABB94;
                opacity: 0.75;
                border-radius: 10px;
            }
            .action {
                img {
                    margin-left: 10px;
                }
            }
            p {
                margin-bottom: 5px;
            }
            span {
                font-family: "DM Sans";
                font-weight: normal;
                font-size: 13px;
                text-align: left;
                color: #0078e2;
                opacity: 0.75;
            }
        }
    }
`;

const TableHeader = styled.div`
    padding: 21px 26px 17px;
    background: #f3f8fb 0% 0% no-repeat padding-box;
    border-top: 0.5px solid rgba(78, 102, 126, 0.35);
    border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
    p {
        margin-bottom: 0px;
        text-align: left;
        font: normal normal medium 13px/24px DM Sans;
        letter-spacing: 0px;
        color: #002E46;
        opacity: 0.75;
    }
    @media(max-width: 768px) {
        display: none;
    }
`;

const TableBody = styled.div`
    padding: 18px 14px;
`;

const TableRowItemWrapper = styled.div`
    padding: 24px;
    padding-bottom: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #002E4626;
    border-radius: 5px;
    margin-bottom: 13px;
    .mb-2 {
        margin-bottom: 20px;
    }
    .flex {
        display: flex;
        img {
            margin-right: 10px;
            border: 0.8px solid #0078e2;
            border-radius: 50%;
            height: 100%;
            padding: 2px;
        }
    }
    p {
        margin-bottom: 0px;
        text-align: left;
        font: normal normal normal 13px/16px DM Sans;
        letter-spacing: 0px;
        color: #002E46;
        opacity: 0.75;
    }
    span {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #002E46BF;
        /* opacity: 0.75; */
    }
    .tag {
        background: #C1EEE4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0ABB94;
        opacity: 0.75;
        border-radius: 10px;
    }
    .actions {
        text-align: center;
        font: normal normal normal 13px/13px DM Sans;
        letter-spacing: 0px;
        border-top: 0.5px solid #f2f2f2;
        margin-top: 16px;
        .accept {
            color: #0078E2;
            padding: 16px 10px;
            text-align: center;
            border-right: 0.5px solid #f2f2f2;
            cursor: pointer;
            &:hover {
                background: #f2f2f2;
            }
        }
        .decline {
            color: #FF2366;
            padding: 16px 10px;
            text-align: center;
            cursor: pointer;
            &:hover {
                background: #f2f2f2;
            }
        }
    }
`;

export { 
    ConsultTableWrapper, ConsultTableHeader, 
    ConsultTableBody, TableHeader, TableBody, TableRowItemWrapper
}