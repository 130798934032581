import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http from '../../utils/api'
import { fetchSingleConsult } from './consults'
import { message } from 'antd'

export interface TreatmentError {
    message: string
}

export interface TreatmentState {
    treatment: any
    treatments: Array<any>
    isTreatmentsLoading: boolean
    addTreatmentsLoading: boolean
    updateTreatmentsLoading: boolean
    deleteTreatmentsLoading: boolean
    treatmentsError: TreatmentError
    addTreatmentsError: TreatmentError
    updateTreatmentsError: TreatmentError
    deleteTreatmentsError: TreatmentError
}

export const initialState: TreatmentState = {
    treatment: null,
    treatments: [],
    isTreatmentsLoading: false,
    addTreatmentsLoading: false,
    updateTreatmentsLoading: false,
    deleteTreatmentsLoading: false,
    treatmentsError: { message: '' },
    addTreatmentsError: { message: '' },
    updateTreatmentsError: { message: '' },
    deleteTreatmentsError: { message: '' },
}

export const treatmentsSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        fetchTreatmentsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isTreatmentsLoading = payload
        },
        fetchTreatmentsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.treatments = payload;
        },
        fetchTreatmentsFailed: (state, { payload }: PayloadAction<TreatmentError>) => {
            state.treatmentsError = payload;
        },
        addTreatmentsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.addTreatmentsLoading = payload
        },
        addTreatmentsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.treatments = payload;
        },
        addTreatmentsFailed: (state, { payload }: PayloadAction<TreatmentError>) => {
            state.addTreatmentsError = payload;
        },
        updateTreatmentsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.updateTreatmentsLoading = payload
        },
        updateTreatmentsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.treatments = payload;
        },
        updateTreatmentsFailed: (state, { payload }: PayloadAction<TreatmentError>) => {
            state.updateTreatmentsError = payload;
        },
        deleteTreatmentsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.deleteTreatmentsLoading = payload
        },
        deleteTreatmentsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.treatments = payload;
        },
        deleteTreatmentsFailed: (state, { payload }: PayloadAction<TreatmentError>) => {
            state.deleteTreatmentsError = payload;
        },
    },
})

export const { 
    fetchTreatmentsLoading, fetchTreatmentsSuccess, fetchTreatmentsFailed, 
    addTreatmentsLoading, addTreatmentsSuccess, addTreatmentsFailed,
    updateTreatmentsLoading, updateTreatmentsSuccess, updateTreatmentsFailed,
    deleteTreatmentsLoading, deleteTreatmentsSuccess, deleteTreatmentsFailed
} = treatmentsSlice.actions;
export const treatmentSelector = (state: { treatments: TreatmentState }) => state.treatments;
export default treatmentsSlice.reducer;


/** Actions */

export const fetchAllTreatments = (): AppThunk => async (dispatch) => {
    dispatch(fetchTreatmentsLoading(true))
    await http.get('/plans')
        .then((res) => {
            const treatments = res?.data?.data;
            dispatch(fetchTreatmentsSuccess(treatments))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message };
            dispatch(fetchTreatmentsFailed(message));
        })
    dispatch(fetchTreatmentsLoading(false));
}

export const addConsultTreatments = (consultId: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(addTreatmentsLoading(true))
    await http.post(`/consults/${consultId}/recommendations`, payload)
        .then((res) => {
            const examinations = res?.data?.data;
            dispatch(addTreatmentsSuccess(examinations))
            message.success("Recommendations Added to Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(addTreatmentsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(addTreatmentsLoading(false));
}

export const updateConsultTreatments = (consultId: any, treatmentId: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(updateTreatmentsLoading(true))
    await http.patch(`/consults/${consultId}/recommendations/${treatmentId}`, payload)
        .then((res) => {
            const examinations = res?.data?.data;
            dispatch(updateTreatmentsSuccess(examinations))
            message.success("Recommendations Updated in Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(updateTreatmentsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(updateTreatmentsLoading(false));
}

export const deleteConsultTreatments = (consultId: any, treatmentId: any): AppThunk => async (dispatch) => {
    dispatch(deleteTreatmentsLoading(true))
    await http.delete(`/consults/${consultId}/recommendations/${treatmentId}`)
        .then((res) => {
            const examinations = res?.data?.data;
            dispatch(deleteTreatmentsSuccess(examinations))
            message.success("Recommendations Deleted from Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(deleteTreatmentsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(deleteTreatmentsLoading(false));
}

