import styled from "styled-components";

const PatientProfileWrapper = styled.div`
  background-color: white;
  height: 100%;
  padding: 24px 30px;
  padding-bottom: 25px;
  overflow-y: auto;
  @media (max-width: 767px) {
    height: 100%;
    min-height: calc(100vh - 80px);
    max-height: 100%;
    overflow-y: auto;
  }
  img.avatar {
    height: 100%;
    width: 100%;
  }
  p.title-text {
    text-align: left;
    font: normal normal medium 13px/16px DM Sans;
    letter-spacing: 0px;
    color: #49606d;
    margin: 0px;
  }
  p.edit-text {
    text-align: right;
    font: normal normal medium 13px/16px DM Sans;
    letter-spacing: 0px;
    color: #49606d;
    margin: 0px;
    cursor: pointer;
  }
  button.edit-btn {
    text-align: right;
    float: right;
    background: #0078e2;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 7px;
    width: auto;
    cursor: pointer;
    font: normal normal normal 13px/16px DM Sans;
    letter-spacing: 0px;
    margin: 0px;
  }
  .avatar-header {
    margin-bottom: 31px;
    margin-top: 16px;
    span {
      text-align: left;
      font: normal normal medium 13px/16px DM Sans;
      letter-spacing: 0px;
      color: #4e667e;
      opacity: 1;
    }
    p {
      margin: 0px;
      text-align: left;
      font: normal normal medium 13px/14px DM Sans;
      letter-spacing: 0px;
      color: #4e667e;
      opacity: 0.75;
    }
  }
  .ant-select-multiple .ant-select-selection-item-content {
    color: #333 !important;
  }
`;

const DataItemWrapper = styled.div`
  margin-bottom: 19px;
  span {
    text-align: left;
    text-align: left;
    font: normal normal medium 13px/24px DM Sans;
    letter-spacing: 0px;
    color: #4e667ea8;
    opacity: 0.65;
  }
  p {
    text-align: left;
    font: normal normal normal 13px/18px DM Sans;
    letter-spacing: 0px;
    color: #4e667e;
  }
`;

export { PatientProfileWrapper, DataItemWrapper };
