import { useEffect, useState } from "react";

import { EncounterWrapper } from "./styles";
import EncountersList from "./containers/EncountersList";
import NotificationSideBar from "component/NotificationSideBar";
import useQuery from "hooks/useQuery";
import EncounterDetail from "./containers/EncounterDetail";

const Encounters = () => {
  const query = useQuery();
  const [isChatPaneVisible, setIsChatPaneVisible] = useState(false);
  const [isEncounterFormVisible, setIsEncounterFormVisible] = useState(false);
  const consultId = query.get("consultId");

  const [showConsultDetailMobile, setShowConsultDetailMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setShowConsultDetailMobile(true);
    }
  }, []);

  useEffect(() => {
    setIsChatPaneVisible(consultId ? true : false);
    setIsEncounterFormVisible(consultId ? true : false);
  }, [consultId]);

  return (
    <EncounterWrapper>
      <EncountersList showConsultDetails={setShowConsultDetailMobile} />

      {showConsultDetailMobile ? (
        <EncounterDetail
          isChatPaneVisible={isChatPaneVisible}
          isEncounterFormVisible={isEncounterFormVisible}
          setIsChatPaneVisible={setIsChatPaneVisible}
          setIsEncounterFormVisible={setIsEncounterFormVisible}
          showConsultDetails={setShowConsultDetailMobile}
        />
      ) : null}

      {consultId ? (
        <NotificationSideBar
          isChatIconVisible={true}
          isChatPaneVisible={isChatPaneVisible}
          setIsChatPaneVisible={setIsChatPaneVisible}
          isEncounterFormVisible={isEncounterFormVisible}
          setIsEncounterFormVisible={setIsEncounterFormVisible}
        />
      ) : null}
    </EncounterWrapper>
  );
};

export default Encounters;
