import styled from 'styled-components';


const ProfileWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const ContentWrapper = styled.div`
    padding: 15px;
    /* background: #f3f8fb; */
    padding-bottom: 100px;
    min-height: 100%;
    overflow-y: scroll;
    .update-profile-btn {
        margin: 10px 0px 0px 0px;
    }
    .btn-add {
        padding: 0px;
        font-size: 13px;
    }
`;

const BlankContentWrapper = styled.div`
    background: #fff;
    height: 100%;
    box-shadow: 0px 3px 6px rgba(0, 120, 226, 0.1);
`;

const LicenseRowItemWrapper = styled.div`
    padding: 10px 0px;
    display: flex;
    img {
        width: 70px;
    }
    .certificate-details {
        flex-grow: 1;
        margin-left: 20px;
        .action-btns {
            float: right;
            span {
                margin-left: 10px;
                &.edit {
                    color: #0078E2;
                }
                &.delete {
                    color: crimson;
                }
            }
        }
        h3 {
            margin-bottom: 0px;
        }
        p {
            margin-bottom: 0px;
            font-size: 13px;
        }
        span {
            color: #a3a3a3;
            font-size: 13px;
        }
    }
`;

const AddTextWrapper = styled.p`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    margin-top: 5px;
    cursor: pointer;
    color: #0078e2;
    img {
        margin-right: 7px;
    }
`;

export { ProfileWrapper, ContentWrapper, BlankContentWrapper, AddTextWrapper, LicenseRowItemWrapper }