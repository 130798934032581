import Pusher from "pusher-js";

const APP_KEY = process.env.REACT_APP_PUSHER_APP_LIVE_KEY;

const pusher = new Pusher(APP_KEY!, {
  cluster: "eu",
  channelAuthorization: {
    endpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT!,
    transport: "ajax",
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_KEY}`
    }
  }
});

export default pusher;
