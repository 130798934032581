import styled from 'styled-components';


const EncountersListItemWrapper = styled.div`
    margin-bottom: 10px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 3px 6px rgb(0 120 226 / 10%);
`;

const EncountersListItemHeaderWrapper = styled.div`
    padding: 20px 24px 18px 24px;
    border-bottom: 1px solid #EAEAEA;
    p {
        margin-bottom: 0px;
        text-align: left;
        font: normal normal normal 13px/14px DM Sans;
        letter-spacing: 0px;
        /* color: #0078E2; */
        color: #333;
        opacity: 0.75;
        &.date, &.time {
            color: #333;
            font-weight: 500;
        }
        &.link {
            cursor: pointer;
        }
        &.link-disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
`;

const EncountersListItemBodyWrapper = styled.div`
    padding: 12.5px 24px 10.5px 24px;
    /* border-bottom: 1px solid #EAEAEA; */
`;

const SectionDividerWrapper = styled.div`
    margin-bottom: 15px;
    b {
        color: #0078E2;
        font-weight: 400;
    }
    p, span {
        margin-bottom: 2px;
        text-align: left;
        font: normal normal normal 13px/26px DM Sans;
        letter-spacing: 0px;
        color: #4E667E;
        opacity: 1;
    }
    .tag {
        font-size: 13px;
        padding: 5px 10px;
        background: #f2f2f2;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 10px;
        display: inline-block;
    }
`;

export { 
    EncountersListItemWrapper, 
    EncountersListItemHeaderWrapper, 
    EncountersListItemBodyWrapper, 
    SectionDividerWrapper 
};