import Card from "../../../../component/Card";
import {
  EncountersWrapper,
  EncountersHeaderWrapper,
  EncountersBodyWrapper,
  EncountersListWrapper
} from "./styles";
import { Col, Empty, Row } from "antd";
import EncountersListItem from "../EncountersListItem";
import TreatmentPlan from "../TreatmentPlan";
import Loader from "../../../../component/Loader";

const Encounters = (props: any) => {
  const { data, loading, profile } = props;

  return (
    <EncountersWrapper>
      <Card marginBottom={6} padding={0}>
        <EncountersHeaderWrapper>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={14} className='nav'>
              <p className='active'>Encounters</p>
            </Col>
            <Col xs={0} sm={0} md={0} lg={10} className='nav'>
              <p>Treatment plan</p>
            </Col>
          </Row>
        </EncountersHeaderWrapper>
      </Card>
      <EncountersBodyWrapper>
        <Row gutter={12} style={{ height: "100%" }}>
          <Col xs={24} md={24}>
            <Card
              marginBottom={15}
              padding={0}
              style={{
                height: "100%",
                boxShadow: "none",
                border: 0
              }}
            >
              <EncountersListWrapper style={{ height: "100%" }}>
                {loading ? (
                  <Loader />
                ) : data?.length > 0 ? (
                  data
                    .filter(
                      (item: any) =>
                        item.status === "accepted" || item.status === "ended"
                    )
                    .map((item: any, index: Number) => {
                      return (
                        <Row gutter={12}>
                          <Col xs={24} sm={24} md={24} lg={14}>
                            <EncountersListItem
                              profile={profile}
                              encounter={item}
                              setIsChatPaneVisible={props.setIsChatPaneVisible}
                              setIsEncounterFormVisible={
                                props.setIsEncounterFormVisible
                              }
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={10}>
                            <TreatmentPlan
                              profile={profile}
                              encounter={item}
                              setIsChatPaneVisible={props.setIsChatPaneVisible}
                              setIsEncounterFormVisible={
                                props.setIsEncounterFormVisible
                              }
                            />
                          </Col>
                        </Row>
                      );
                    })
                ) : (
                  <Row
                    align='middle'
                    justify='center'
                    style={{ height: "500px", background: "#fff" }}
                  >
                    <Empty />
                  </Row>
                )}
              </EncountersListWrapper>
            </Card>
          </Col>
          <Col xs={0} md={0}>
            <div>
              {loading ? (
                <Loader />
              ) : data?.length > 0 ? (
                data.map((item: any, index: Number) => {
                  return (
                    <TreatmentPlan
                      profile={profile}
                      encounter={item}
                      setIsChatPaneVisible={props.setIsChatPaneVisible}
                      setIsEncounterFormVisible={
                        props.setIsEncounterFormVisible
                      }
                    />
                  );
                })
              ) : (
                <Row
                  align='middle'
                  justify='center'
                  style={{ height: "500px", background: "#fff" }}
                >
                  <Empty />
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </EncountersBodyWrapper>
    </EncountersWrapper>
  );
};

export default Encounters;
