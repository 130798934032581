import {
  Col,
  Row,
  Form,
  Select as AntdSelect,
  Tooltip,
  Space,
  Grid,
} from "antd";
import Card from "../../component/Card";
import DatePicker from "../../component/DatePicker";
import Input from "../../component/Input";
import Select from "../../component/Select";
import TextArea from "../../component/TextArea";
import Upload from "../../component/Upload";
import Button from "../../component/Button";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Typography from "component/Typography";
import CommonButton from "common/Button";

import {
  ProfileWrapper,
  ContentWrapper,
  BlankContentWrapper,
  LicenseRowItemWrapper,
} from "./styles";
import PlusXSImg from "../../assets/icons/plus-xs.svg";
import { useEffect, useState } from "react";
import LicenseModal from "./containers/LicenseModal";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  authSelector,
  fetchProfile,
  updateProviderProfile,
  updateProviderProfileRecords,
  updateProviderProfileDeletion,
} from "../../redux/reducers/auth";
import moment from "moment";
import CertificateImg from "../../assets/icons/certificates.svg";
import lodash from "lodash";
import Loader from "../../component/Loader";
import Storage from "../../utils/firebase-storage";
import AvatarImg from "../../component/Avatar";
import ScreenLayout from "layouts/ScreenLayout";

const { Option } = AntdSelect;

const languages = [
  "English",
  "Yoruba",
  "Hausa",
  "French",
  "Spanish",
  "Italian",
  "Lebanese",
  "Portuguese",
];

const specialties = [
  "Anaesthesia",
  "Dermatology",
  "Dentistry",
  "Emergency medicine",
  "Cardiac Surgery",
  "Family medicine",
  "General Physician",
  "Internal medicine",
  "Neurology",
  "Obstetrics and Gynecology",
  "Optometry",
  "Ophthalmology",
  "Orthopedic surgery",
  "Otolaryngology",
  "Oral and Maxillofacial Surgery",
  "Pediatrics",
  "Podiatry",
  "Psychiatry",
  "Radiology (diagnostic)",
  "Surgery (general)",
  "Urology",
  "Neurosurgery",
  "Plastic surgery",
  "Gastroenterology",
  "Pulmonology",
];

const religions = [
  "African Traditional & Diasporic",
  "Agnostic",
  "Atheist",
  "Baha'i",
  "Buddhism",
  "Cao Dai",
  "Chinese traditional religion",
  "Christianity",
  "Hinduism",
  "Islam",
  "Jainism",
  "Juche",
  "Judaism",
  "Neo-Paganism",
  "Nonreligious",
  "Rastafarianism",
  "Secular",
  "Shinto",
  "Sikhism",
  "Spiritism",
  "Tenrikyo",
  "Unitarian-Universalism",
  "Zoroastrianism",
  "primal-indigenous",
  "Other",
];

const languageFluency = [
  "No Proficiency",
  "Elementary Proficiency",
  "Limited Working Proficiency",
  "Proficiency Working Proficiency",
  "Native Proficiency",
];

const Profile = () => {
  const [basiform] = Form.useForm();
  const [otherForm] = Form.useForm();
  const [professionalForm] = Form.useForm();
  const dispatch = useDispatch();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isBasicInfoUpdateLoading, setIsBasicInfoUpdateLoading] =
    useState(false);
  const [isOtherInfoUpdateLoading, setIsOtherInfoUpdateLoading] =
    useState(false);
  const [isProfessionalInfoUpdateLoading, setIsProfessionalInfoUpdateLoading] =
    useState(false);
  const [isLicenseInfoUpdateLoading, setIsLicenseInfoUpdateLoading] =
    useState(false);
  const [isDeletingLicenseLoading, setIsDeletingLicenseLoading] =
    useState(false);
  const [isDeletingLicenseIndex, setIsDeletingLicenseIndex] = useState<
    number | undefined
  >();
  const { currentUser, isProfileLoading } = useSelector(authSelector);
  const [isLicenseModalVisible, setIsLicenseModalVisible] = useState(false);
  const [currentLicenseData, setCurrentLicenseData] = useState<any>(null);

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  useEffect(() => {
    const {
      first_name,
      last_name,
      email,
      phone,
      address,
      dob,
      about_me,
      interests,
      certificates,
      consultation_room,
      languages,
      practice,
      religion,
      speciality,
    } = currentUser || {};
    const { street_line_one, state, city, country } = address || {};
    basiform.setFieldsValue({
      first_name,
      last_name,
      email,
      phone,
      consultation_room,
      dob: dob ? moment(dob, "dd/MM/yyyy") : null,
      street_line_one,
      state,
      city,
      country,
    });
    otherForm.setFieldsValue({
      about_me,
      interests: interests || [],
      speciality,
      religion,
    });
    professionalForm.setFieldsValue({
      certificates:
        certificates?.length > 0
          ? certificates.map((item: any) => {
              return {
                ...item,
                year_awarded: item.year_awarded
                  ? moment(item.year_awarded, "YYYY")
                  : null,
              };
            })
          : [
              {
                name: "",
                institution: "",
                year_awarded: null,
                specialization: "",
              },
            ],
      languages:
        languages?.length > 0 ? languages : [{ language: "", fluency: "" }],
    });
  }, [currentUser, basiform, otherForm, professionalForm]);

  const handleSubmitBasicDetails = async (values: any) => {
    setIsBasicInfoUpdateLoading(true);
    const {
      first_name,
      last_name,
      phone,
      dob,
      consultation_room,
      street_line_one,
      city,
      state,
      country,
    } = values || {};
    const payload = {
      first_name,
      last_name,
      phone,
      consultation_room,
      dob: dob ? moment(dob, "DD/MM/yyyy").format("DD/MM/yyyy") : null,
      // dob: null,
      address: {
        street_line_one,
        city,
        state,
        country,
      },
    };
    await dispatch(updateProviderProfile(payload));
    setIsBasicInfoUpdateLoading(false);
  };

  const handleSubmitOtherDetails = async (values: any) => {
    setIsOtherInfoUpdateLoading(true);
    const { about_me, interests, practice, speciality, religion } = values;
    const payload = {
      about_me,
      interests,
      // practice,
      speciality,
      religion,
    };
    console.log(payload);
    await dispatch(updateProviderProfile(payload));
    setIsOtherInfoUpdateLoading(false);
  };

  const handleSubmitProfessionalDetails = async (values: any) => {
    const { certificates: mainCertificates, languages: mainLanguages } =
      currentUser;
    const { certificates, languages } = values;

    const languagesToRemove = lodash
      .differenceBy(mainLanguages, languages, "id")
      .map((item: any) => item.id);
    const certificatesToRemove = lodash
      .differenceBy(mainCertificates, certificates, "id")
      .map((item: any) => item.id);

    const languagesToAdd = lodash.differenceBy(languages, mainLanguages, "id");
    const certificatesToAdd = lodash
      .differenceBy(certificates, mainCertificates, "id")
      .map((item: any) => {
        return {
          ...item,
          year_awarded: moment(item.year_awarded).format("YYYY"),
        };
      });

    setIsProfessionalInfoUpdateLoading(true);
    const addPayload = {
      certificates: certificatesToAdd,
      languages: languagesToAdd,
    };
    const removePayload = {
      certificates: certificatesToRemove,
      languages: languagesToRemove,
    };
    if (certificatesToAdd.length > 0 || languagesToAdd.length > 0) {
      await dispatch(updateProviderProfile(addPayload));
    }
    if (certificatesToRemove.length > 0 || languagesToRemove.length > 0) {
      await dispatch(updateProviderProfileDeletion(removePayload));
    }
    setIsProfessionalInfoUpdateLoading(false);
  };

  const handleProfilePictureUpload = ({ onError, onSuccess, file }: any) => {
    if (file == null) return;

    const uploadTask = Storage.ref(`/profile-pics/files/${file.name}`).put(
      file
    );

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        // console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          // dispatch(
          //   updateProviderProfile({
          //     about_me: url,
          //   })
          // );
        });
      }
    );
  };

  const generateLink = () => {
    basiform.setFieldsValue({
      consultation_room: `meet.jit.si/pneuma/${currentUser.id}`,
    });
  };

  const handleDeleteLicense = async (license: any, index: number) => {
    const licensesToRemove = [license].map((item: any) => item.id);

    setIsDeletingLicenseLoading(true);
    setIsDeletingLicenseIndex(index);
    const removePayload = {
      licenses: licensesToRemove,
    };
    if (license) {
      await dispatch(updateProviderProfileDeletion(removePayload, true));
    }
    setIsDeletingLicenseLoading(false);
    setIsDeletingLicenseIndex(undefined);
  };

  const handleSubmitLicenseDetails = async (values: any) => {
    setIsLicenseInfoUpdateLoading(true);
    const { name, organization, date_issued, expires, expires_at } =
      values || {};
    const id = currentLicenseData?.id;
    const payload = currentLicenseData
      ? {
          license: {
            id,
            name,
            organization,
            date_issued: date_issued
              ? moment(date_issued).format("yyyy-MM-DD")
              : null,
            expires,
            // expires_at: expires_at
            //   ? moment(date_issued).format("yyyy-MM-DD")
            //   : null,
          },
        }
      : {
          licenses: [
            {
              name,
              organization,
              date_issued: date_issued
                ? moment(date_issued).format("yyyy-MM-DD")
                : null,
              expires,
              // expires_at: expires_at
              //   ? moment(date_issued).format("yyyy-MM-DD")
              //   : null,
            },
          ],
        };
    if (currentLicenseData) {
      await dispatch(updateProviderProfileRecords(payload));
    } else {
      await dispatch(updateProviderProfile(payload));
    }
    setIsLicenseInfoUpdateLoading(false);
    setIsLicenseModalVisible(false);
    setCurrentLicenseData(null);
  };

  const { first_name, last_name, licenses } = currentUser || {};

  return (
    <ScreenLayout>
      <ContentWrapper>
        {isProfileLoading ? (
          <Loader background="transparent" />
        ) : (
          <>
            <Form
              name="basicForm"
              layout="vertical"
              form={basiform}
              onFinish={handleSubmitBasicDetails}
            >
              <Card marginBottom={15}>
                <Space wrap>
                  <AvatarImg
                    img=""
                    style={{
                      width: "96px",
                      height: "96px",
                      fontSize: "26px",
                    }}
                    fallbackText={`${first_name} ${last_name}`}
                  />
                  <div>
                    <Upload
                      customRequest={handleProfilePictureUpload}
                      type="secondary"
                    >
                      Add a profile photo
                    </Upload>
                    <small>Allowed JPG, GIF or PNG. Max size of 2MB</small>
                  </div>
                </Space>
              </Card>
              <Card marginBottom={15}>
                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Input
                      formItem={{
                        label: "First Name",
                        name: "first_name",
                        rules: [
                          {
                            required: true,
                            message: "Please input your first name",
                          },
                        ],
                      }}
                      type="text"
                      required
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Input
                      formItem={{
                        label: "Last Name",
                        name: "last_name",
                        rules: [
                          {
                            required: true,
                            message: "Please input your last name",
                          },
                        ],
                      }}
                      type="text"
                      required
                    />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={24}>
                    <Input
                      formItem={{
                        label: "Email address",
                        name: "email",
                        rules: [
                          {
                            required: true,
                            message: "Please input your Email address",
                          },
                          {
                            type: "email",
                            message: "Please input a valid email",
                          },
                        ],
                      }}
                      type="email"
                      label="Email address"
                      disabled
                    />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Input
                      formItem={{
                        label: "Phone number",
                        name: "phone",
                        rules: [
                          {
                            required: true,
                            message: "Please enter your phone number",
                          },
                          {
                            min: 11,
                            message:
                              "Phone number cannot be less than 11 characters",
                          },
                          {
                            max: 11,
                            message:
                              "Phone number cannot be greater than 11 characters",
                          },
                          {
                            pattern: /^\d+$/,
                            message: "Phone number can only contain digits",
                          },
                        ],
                      }}
                      type="text"
                      label="Phone number"
                      required
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <DatePicker
                      formItem={{
                        label: "Date of Birth",
                        name: "dob",
                        rules: [
                          {
                            required: true,
                            message: "Please input your Date of Birth",
                          },
                        ],
                      }}
                      format="DD/MM/yyyy"
                      type="text"
                      required
                    />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={24}>
                    <Input
                      formItem={{
                        label: "Street Address",
                        name: "street_line_one",
                        rules: [
                          {
                            required: true,
                            message: "Please input your Street Address",
                          },
                        ],
                      }}
                      type="text"
                      label="Street Address"
                      required
                    />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} md={8}>
                    <Input
                      formItem={{
                        label: "City",
                        name: "city",
                        rules: [
                          {
                            required: true,
                            message: "Please input your City",
                          },
                        ],
                      }}
                      type="text"
                      required
                    />
                  </Col>
                  <Col xs={24} md={8}>
                    <Input
                      formItem={{
                        label: "State",
                        name: "state",
                        rules: [
                          {
                            required: true,
                            message: "Please input your State",
                          },
                        ],
                      }}
                      type="text"
                      required
                    />
                  </Col>
                  <Col xs={24} md={8}>
                    <Input
                      formItem={{
                        label: "Country",
                        name: "country",
                        rules: [
                          {
                            required: true,
                            message: "Please input your Country",
                          },
                        ],
                      }}
                      type="text"
                      required
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Input
                      formItem={{
                        label: (
                          <Row
                            justify="space-between"
                            style={{ width: "100%" }}
                          >
                            <p>
                              Consultation Room
                              <Tooltip title="This is a link to a preferred video/audio channel where you can have a more conversations with your patient. We autogenerate a link for every consult but you can create a personalized and static link for yourself">
                                <span style={{ marginLeft: "10px" }}>
                                  <QuestionCircleOutlined />
                                </span>
                              </Tooltip>
                            </p>
                            <span
                              onClick={generateLink}
                              style={{
                                color: "#0078E2",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              Generate Link
                            </span>
                          </Row>
                        ),
                        name: "consultation_room",
                        rules: [
                          {
                            required: true,
                            message: "Please generate your consultation room",
                          },
                        ],
                      }}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={24} justify="end">
                  <Button
                    htmlType="submit"
                    className="update-profile-btn"
                    type="primary"
                  >
                    {isBasicInfoUpdateLoading
                      ? "Updating..."
                      : "Update Basic Info"}
                  </Button>
                </Row>
              </Card>
            </Form>

            <Form
              name="otherForm"
              layout="vertical"
              form={otherForm}
              onFinish={handleSubmitOtherDetails}
            >
              <Card marginBottom={15}>
                <Row gutter={24}>
                  <Col span={24}>
                    <TextArea
                      formItem={{
                        label: "About me",
                        name: "about_me",
                        rules: [
                          {
                            max: 500,
                            message:
                              "Phone number cannot be greater than 500 characters",
                          },
                        ],
                      }}
                      placeholder="Write a short bio about yourself"
                      maxLength={500}
                      type="text"
                      rows={4}
                      showCount
                    />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={24}>
                    <Select
                      formItem={{
                        label: "Interests",
                        name: "interests",
                        help: "You can add more than one interest by typing a comma after each one",
                      }}
                      mode="tags"
                      label="Interests"
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Input
                      formItem={{
                        label: "Practice",
                        name: "practice",
                      }}
                      type="text"
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Select
                      formItem={{
                        label: "Speciality",
                        name: "speciality",
                      }}
                      // marginBottom={10}
                      required
                    >
                      {specialties.map((item, i) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </Select>
                  </Col>
                  <Col span={24}>
                    <Select
                      formItem={{
                        label: "Religion",
                        name: "religion",
                      }}
                      marginBottom={10}
                      required
                    >
                      {religions.map((item, i) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={24} justify="end">
                  <Button
                    htmlType="submit"
                    className="update-profile-btn"
                    type="primary"
                  >
                    {isOtherInfoUpdateLoading
                      ? "Updating..."
                      : "Update Other Info"}
                  </Button>
                </Row>
              </Card>
            </Form>

            <Form
              name="professionalForm"
              layout="vertical"
              form={professionalForm}
              onFinish={handleSubmitProfessionalDetails}
            >
              <Card marginBottom={15}>
                <h3>Professional Degrees and Qualifications</h3>

                <Form.List name="certificates">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Row
                            style={{ marginBottom: "10px" }}
                            justify="space-between"
                            align="middle"
                          >
                            Degree {index + 1}
                            {fields.length > 0 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Row>
                          <Row gutter={24} className="">
                            <Col xs={24} md={12}>
                              <Form.Item
                                name={[index, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Degree Awareded is required",
                                  },
                                ]}
                              >
                                <Input
                                  formItem={{
                                    label: "Degree awarded",
                                  }}
                                  type="text"
                                  label="Name of institution"
                                  required
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                name={[index, "institution"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Institution is required",
                                  },
                                ]}
                              >
                                <Input
                                  formItem={{
                                    label: "Name of institution",
                                  }}
                                  type="text"
                                  label="Name of institution"
                                  required
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                name={[index, "specialization"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Specialization is required",
                                  },
                                ]}
                              >
                                <Input
                                  formItem={{
                                    label: "Specialization",
                                  }}
                                  marginBottom={10}
                                  required
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                name={[index, "year_awarded"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Year is required",
                                  },
                                ]}
                              >
                                <DatePicker
                                  formItem={{
                                    label: "Year of award",
                                  }}
                                  format={"YYYY"}
                                  picker="year"
                                  required
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Form.Item style={{ textAlign: "right" }}>
                        <CommonButton type="link" onClick={() => add()}
                        >
                          <PlusOutlined />
                          Add another degree
                        </CommonButton>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <Form.List name="languages">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Row key={field.key}>
                          <Col flex={1}>
                            <Row gutter={24} align="middle">
                              <Col xs={24} md={12}>
                                <Form.Item
                                  name={[index, "language"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Language is required",
                                    },
                                  ]}
                                >
                                  <Select
                                    formItem={{
                                      label: "Language",
                                    }}
                                    marginBottom={10}
                                    required
                                  >
                                    {languages.map((item, i) => {
                                      return (
                                        <Option value={item}>{item}</Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  name={[index, "fluency"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Fluency is required",
                                    },
                                  ]}
                                >
                                  <Select
                                    formItem={{
                                      label: "Level of fluency",
                                    }}
                                    marginBottom={10}
                                    required
                                  >
                                    {languageFluency.map((item, i) => {
                                      return (
                                        <Option value={item}>{item}</Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            {fields.length > 0 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      ))}
                      <Form.Item style={{ textAlign: "right" }}>
                        <CommonButton type="link" onClick={() => add()}>
                          <PlusOutlined />
                          Add another language
                        </CommonButton>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <Row gutter={24} justify="end">
                  <Button
                    htmlType="submit"
                    className="update-profile-btn"
                    type="primary"
                  >
                    {isProfessionalInfoUpdateLoading
                      ? "Updating..."
                      : "Update Professional Info"}
                  </Button>
                </Row>
              </Card>
            </Form>

            <Card marginBottom={15}>
              <Row
                align="middle"
                justify="space-between"
                wrap={false}
                style={{ marginBottom: 16 }}
              >
                <Col>
                  <Typography weight="bold">
                    Licenses and Certifications
                  </Typography>
                </Col>
                <Col>
                  <CommonButton
                    style={{ display: "inline-flex", alignItems: "center" }}
                    type="link"
                    onClick={() => setIsLicenseModalVisible(true)}
                  >
                    <PlusOutlined
                      style={breakpoints.md ? {} : { fontSize: 20 }}
                    />
                    {breakpoints.md && "Add another license"}
                  </CommonButton>
                </Col>
              </Row>
              {licenses && licenses.length > 0
                ? licenses.map((license: any, index: number) => {
                    return (
                      <Row gutter={[8, 0]} style={{ marginBottom: 16 }}>
                        <Col flex={1}>
                          <Row gutter={8} wrap={false}>
                            <Col>
                              <img
                                src={CertificateImg}
                                alt="certificate-icon"
                              />
                            </Col>
                            <Col flex={1} style={{ minWidth: 0 }}>
                              <Typography weight="bold">
                                {license?.name}
                              </Typography>
                              <Typography>{license?.organization}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                Issued on{" "}
                                {moment(license?.date_issued).format(
                                  "DD-MM-YYYY"
                                )}
                              </Typography>
                              {license?.expires_at && (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Expires by{" "}
                                  {moment(license?.expires_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Typography>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={0} />
                        <Col>
                          <Row justify="end">
                            <Col>
                              <CommonButton
                                type="link"
                                onClick={() => {
                                  setCurrentLicenseData(license);
                                  setIsLicenseModalVisible(true);
                                }}
                              >
                                Edit
                              </CommonButton>
                            </Col>
                            <Col>
                              <CommonButton
                                type="link"
                                danger
                                onClick={() =>
                                  isDeletingLicenseLoading
                                    ? null
                                    : handleDeleteLicense(license, index)
                                }
                              >
                                {isDeletingLicenseIndex === index
                                  ? "Deleting..."
                                  : "Delete"}
                              </CommonButton>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })
                : "No license added yet"}
              <LicenseModal
                visible={isLicenseModalVisible}
                currentLicenseData={currentLicenseData}
                handleCancel={() => setIsLicenseModalVisible(false)}
                isLicenseInfoUpdateLoading={isLicenseInfoUpdateLoading}
                handleSubmitLicenseDetails={handleSubmitLicenseDetails}
              />
            </Card>
          </>
        )}
      </ContentWrapper>
    </ScreenLayout>
  );
};

export default Profile;
