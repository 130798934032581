import moment from "moment";

const getAge = (dob: any) => {
  const ageInYears = dob
    ? moment().diff(moment(dob, "DD/MM/YYYY"), "years")
    : null;
  if (ageInYears || ageInYears === 0) {
    return ageInYears === 0
      ? `${moment().diff(moment(dob, "DD/MM/YYYY"), "months")} mo`
      : ageInYears > 0
      ? `${ageInYears} yrs`
      : "—";
  }
  return "—";
};

export const isNotificationSupported = () =>
  "Notification" in window &&
  "serviceWorker" in navigator &&
  "PushManager" in window;

export const isProd = process.env.REACT_APP_ENVIRONMENT === "production";

export default getAge;
