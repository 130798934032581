import { Spin } from "antd";
import { TagWrapper } from "./styles";
import { useEffect, useState } from "react";
import NoIcon from "../../assets/icons/no.svg";

type TagParamsType = {
  text?: string;
  children: any;
  onDelete?: any;
  onUpdate?: any;
  onDeleteLoading?: boolean;
  onUpdateLoading?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean
};

const Tag = ({
  text,
  children,
  onDelete,
  onUpdate,
  onUpdateLoading,
  onDeleteLoading,
  isEditable,
  isDeletable,
}: TagParamsType) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formText, setFormText] = useState("");

  useEffect(() => {
    if (text) {
      setFormText(text);
    }
  }, [text]);

  const handleTextChange = (e: any) => {
    setFormText(e.target.value);
  };

  const handleUpdateText = () => {
    const isTextChanged = text !== formText;
    if (isTextChanged) {
        onUpdate(formText);
    }
    setIsOpen(false);
  };

  return (
    <TagWrapper>
      <div>
        {isOpen ? (
          <input value={formText} onChange={handleTextChange} />
        ) : (
          children
        )}
      </div>
      {!isEditable ? null : onDeleteLoading ? (
        <Spin size="small" spinning={true} />
      ) : isOpen ? (
        <i className="edit lni lni-save" onClick={handleUpdateText} />
      ) : (
        <i
          className="edit lni lni-pencil-alt"
          onClick={() => setIsOpen(true)}
        />
      )}
      {!isDeletable ? null : isOpen ? null : onDeleteLoading ? (
        <Spin size="small" spinning={true} />
      ) : (
        <img width="20px" className="delete" src={NoIcon} onClick={onDelete} alt="chat-icon" />
      )}
    </TagWrapper>
  );
};

export default Tag;
