import { Row, Col, Spin, Empty, Pagination, Space, Grid } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

import Card from "../../../../component/Card";
import {
  consultSelector,
  fetchAllConsults
} from "../../../../redux/reducers/consults";
import getAge from "../../../../utils/utils";
import AvatarImg from "../../../../component/Avatar";
import { PaginationWrapper } from "../../../Questions/containers/QuestionsTable/styles";
import { TableHeader } from "../../styles";
import {
  CanceledConsultsTableWrapper,
  CanceledConsultsTableBody,
  TableBody,
  TableRowItemWrapper
} from "./styles";

const CanceledConsultsTable = () => {
  const dispatch = useDispatch();
  const { consults, isConsultsLoading } = useSelector(consultSelector);
  const [pageNumber, setPageNumber] = useState(1);
  const DEFAULT_PAGE_SIZE = 10;

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    const query = "?status=rejected";
    dispatch(fetchAllConsults(query));
  }, [dispatch]);

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    window.scrollTo(0, 0);
  };

  const startPageCount = (pageNumber - 1) * DEFAULT_PAGE_SIZE;
  return (
    <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
      <CanceledConsultsTableWrapper>
        <TableHeader>
          <Row gutter={[16, 12]}>
            <Col md={6}>
              <p>Patient name</p>
            </Col>
            <Col md={6}>
              <p>Nature of encounter</p>
            </Col>
            <Col md={6}>
              <p>Partner</p>
            </Col>
            <Col md={6}>
              <p>Schedule</p>
            </Col>
          </Row>
        </TableHeader>

        <CanceledConsultsTableBody>
          <TableBody>
            {isConsultsLoading ? (
              <Row align='middle' justify='center' style={{ height: "150px" }}>
                <Spin size='default' />
              </Row>
            ) : consults.length > 0 ? (
              consults
                .slice(startPageCount, startPageCount + DEFAULT_PAGE_SIZE)
                .map(({ patient_record, form, created_at, partner }) => {
                  const { first_name, last_name, gender, dob } =
                    patient_record || {};

                  const maxTextLength = breakpoints.md ? 50 : 50;
                  const natureOfEncounter = form?.name as string;
                  const businessName = (partner?.business_name ||
                    "-") as string;

                  return (
                    <TableRowItemWrapper>
                      <Row gutter={[16, 12]}>
                        <Col xs={24} md={6}>
                          <Space>
                            <AvatarImg
                              img=''
                              style={{ marginRight: 10 }}
                              fallbackText={`${first_name} ${last_name}`}
                            />
                            <div>
                              <p className='name'>
                                {first_name} {last_name}
                              </p>
                              <span
                                style={{ display: "block" }}
                                className='text-capitalize'
                              >
                                {gender}, {getAge(dob)}
                              </span>
                            </div>
                          </Space>
                        </Col>

                        <Col xs={12} md={6}>
                          {natureOfEncounter && (
                            <p className='tags'>
                              {natureOfEncounter.slice(0, maxTextLength)}
                              {natureOfEncounter.length > maxTextLength
                                ? "..."
                                : ""}
                            </p>
                          )}
                        </Col>
                        <Col xs={12} md={6}>
                          <p>
                            {businessName.slice(0, maxTextLength)}
                            {businessName.length > maxTextLength ? "..." : ""}
                          </p>
                        </Col>
                        <Col xs={24} md={6}>
                          <Row>
                            <Col>
                              <div className='schedule-text'>
                                <p>{moment(created_at).format("LL")}</p>
                                <span>
                                  {moment(created_at).format("hh:mm a")}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TableRowItemWrapper>
                  );
                })
            ) : (
              <Empty />
            )}
          </TableBody>
          {!isConsultsLoading && consults?.length > 0 && (
            <PaginationWrapper>
              <Pagination
                defaultCurrent={1}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                onChange={handlePageChange}
                total={consults.length || 0}
              />
            </PaginationWrapper>
          )}
        </CanceledConsultsTableBody>
      </CanceledConsultsTableWrapper>
    </Card>
  );
};

export default CanceledConsultsTable;
