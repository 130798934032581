import { Row, Col, Space, Grid, Spin, Pagination } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";

import Card from "../../../../component/Card";
import {
  consultSelector,
  fetchAllConsults
} from "../../../../redux/reducers/consults";
import getAge from "../../../../utils/utils";
import AvatarImg from "../../../../component/Avatar";
import Button from "../../../../component/Button";
import CommonCard from "common/Card";
import Typography from "component/Typography";
import { PaginationWrapper } from "../../../Questions/containers/QuestionsTable/styles";
import { TableHeader } from "../../styles";
import {
  OngoingConsultsTableWrapper,
  OngoingConsultsTableBody
} from "./styles";

const OngoingConsultsTable = (props: any) => {
  const dispatch = useDispatch();
  const { consults, isConsultsLoading } = useSelector(consultSelector);
  const [pageNumber, setPageNumber] = useState(1);
  const DEFAULT_PAGE_SIZE = 10;
  const startPageCount = (pageNumber - 1) * DEFAULT_PAGE_SIZE;

  const breakpoints = Grid.useBreakpoint();

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const query = "/user?status=accepted";
    dispatch(fetchAllConsults(query));
  }, [dispatch]);

  return (
    <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
      <OngoingConsultsTableWrapper>
        <TableHeader>
          <Row gutter={[16, 12]}>
            <Col md={6}>
              <p>Patient name</p>
            </Col>
            <Col md={4}>
              <p>Nature of encounter</p>
            </Col>
            <Col md={4}>
              <p>Partner</p>
            </Col>
            <Col md={4}>
              <p>Schedule</p>
            </Col>
          </Row>
        </TableHeader>

        <OngoingConsultsTableBody>
          {isConsultsLoading ? (
            <Row align='middle' justify='center' style={{ height: "300px" }}>
              <Spin size='default' />
            </Row>
          ) : consults?.length > 0 ? (
            consults
              .slice(startPageCount, startPageCount + DEFAULT_PAGE_SIZE)
              ?.map((consult) => {
                const { first_name, last_name, dob } =
                  consult?.patient_record || {};

                const maxTextLength = breakpoints.md ? 50 : 50;

                const natureOfEncounter = (consult?.form?.name ||
                  "-") as string;

                const businessName = (consult?.partner?.business_name ||
                  "-") as string;

                return (
                  <CommonCard key={consult?.id} style={{ padding: 24 }}>
                    <Row gutter={[16, 12]} align='middle'>
                      <Col xs={24} md={6}>
                        <Space>
                          <AvatarImg
                            img=''
                            style={{ marginRight: 10 }}
                            fallbackText={`${first_name} ${last_name}`}
                          />
                          <div>
                            <Typography variant='body2'>
                              {first_name} {last_name}
                            </Typography>
                            <Typography variant='caption' color='info'>
                              {getAge(dob)}
                            </Typography>
                          </div>
                        </Space>
                      </Col>
                      <Col xs={12} sm={12} md={4}>
                        <Typography variant='body2'>
                          {natureOfEncounter.slice(0, maxTextLength)}
                          {natureOfEncounter.length > maxTextLength
                            ? "..."
                            : ""}
                        </Typography>
                      </Col>
                      <Col xs={12} sm={12} md={4}>
                        <Typography variant='body2'>
                          {businessName.slice(0, maxTextLength)}
                          {businessName.length > maxTextLength ? "..." : ""}
                        </Typography>
                      </Col>
                      <Col xs={24} md={4}>
                        <Typography variant='body2'>
                          {moment(consult.created_at).format("LL")}
                        </Typography>
                        <Typography
                          variant='caption'
                          color='info'
                          align='center'
                        >
                          {moment(consult.created_at).format("hh:mm a")}
                        </Typography>
                      </Col>
                      <Col xs={24} md={6}>
                        <Row justify={breakpoints.md ? "end" : "center"}>
                          <Col>
                            <Link
                              to={`/encounters/${consult?.patient_record?.id}?consultId=${consult.id}`}
                            >
                              <Button type='primary' className='action'>
                                Resume encounter
                              </Button>
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CommonCard>
                );
              })
          ) : null}
        </OngoingConsultsTableBody>

        <Row justify='end'>
          <Col>
            {!isConsultsLoading && consults?.length > 0 && (
              <PaginationWrapper>
                <Pagination
                  responsive
                  defaultCurrent={1}
                  defaultPageSize={DEFAULT_PAGE_SIZE}
                  onChange={handlePageChange}
                  total={consults.length || 0}
                />
              </PaginationWrapper>
            )}
          </Col>
        </Row>
      </OngoingConsultsTableWrapper>
    </Card>
  );
};

export default OngoingConsultsTable;
