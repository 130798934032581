import Card from "../../../../component/Card";
import { TreatmentPlanWrapper } from "./styles";
import MedicationIcon from "../../../../assets/icons/treatment_medication.svg";
import FollowupIcon from "../../../../assets/icons/treatment_followup.svg";
import DotIcon from "../../../../assets/icons/treatment_dot.svg";
import InvestigationIcon from "../../../../assets/icons/treatment_investigation.svg";
import moment from "moment";

const TreatmentPlan = (props: any) => {
  const { encounter } = props || {};
  const { recommendations, medications, investigations, follow_up } =
    encounter || {};

  const hasNoPlan =
    medications.length === 0 &&
    investigations.length === 0 &&
    recommendations.length === 0;
  return (
    <Card marginBottom={15} padding={0}>
      <TreatmentPlanWrapper>
        {hasNoPlan && <p>No Plan Added Yet</p>}
        {medications.map((item: any, index: number) => {
          return (
            <p key={index}>
              <img width="10px" src={MedicationIcon} alt="symptom-icon" />{" "}
              {item.name}
            </p>
          );
        })}
        {investigations.map((item: any, index: number) => {
          return (
            <p key={index}>
              <img width="12px" src={InvestigationIcon} alt="symptom-icon" />{" "}
              {item.name}
            </p>
          );
        })}
        {recommendations.map((item: any, index: number) => {
          return (
            <p key={index}>
              <img width="8px" src={DotIcon} alt="symptom-icon" /> {item.plan}
            </p>
          );
        })}
        {follow_up && (
          <p>
            <img width="10px" src={FollowupIcon} alt="symptom-icon" />{" "}
            Follow up on {moment(follow_up).format("LL")}
          </p>
        )}
      </TreatmentPlanWrapper>
    </Card>
  );
};

export default TreatmentPlan;
