import { TreatmentsModalWrapper } from "./styles";
import { Col, Modal, Row, Form, Spin, Divider } from "antd";
import Select from "../../../../component/Select";
import Button from "../../../../component/Button";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useEffect, useState } from "react";
import Input from "../../../../component/Input";
import {
  addConsultTreatments,
  updateConsultTreatments,
  deleteConsultTreatments,
  treatmentSelector
} from "../../../../redux/reducers/treatments";
import { useForm } from "antd/lib/form/Form";
import Tag from "../../../../component/Tag";
import { Mixpanel } from "utils/mixpanel";

const TreatmentsModal = (props: any) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [data, setData] = useState<any[]>([]);

  const {
    addTreatmentsError,
    addTreatmentsLoading,
    deleteTreatmentsLoading,
    updateTreatmentsLoading
  } = useSelector(treatmentSelector);

  const {
    consultId,
    formTreatments,
    isTreatmentsModalVisible,
    setIsTreatmentsModalVisible
  } = props;

  useEffect(() => {
    if (formTreatments.length === 0) {
      setData([
        {
          plan: ""
        }
      ]);
    }
  }, [formTreatments, isTreatmentsModalVisible]);

  const handleSubmit = async (values: any) => {
    const { fields } = values;
    const formattedPayload = fields;

    await dispatch(
      addConsultTreatments(consultId, { plans: formattedPayload })
    );
    Mixpanel.track("recommendation_added", {});
    setIsTreatmentsModalVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      title='Treatments'
      visible={isTreatmentsModalVisible}
      centered
      cancelText='Cancel'
      onOk={handleSubmit}
      footer={null}
      onCancel={() => setIsTreatmentsModalVisible(false)}
      className='examination-modal main-modal'
    >
      <TreatmentsModalWrapper>
        <p className='title'>Existing Treatment Plans</p>
        {formTreatments.length === 0 ? (
          <p className='sub-text'>No treatment plan added yet</p>
        ) : (
          formTreatments.map((item: any, index: number) => {
            return (
              <Tag
                key={index}
                isEditable={true}
                isDeletable={true}
                text={item.plan}
                onUpdate={(text: string) =>
                  dispatch(
                    updateConsultTreatments(consultId, item.id, {
                      plan: text
                    })
                  )
                }
                onUpdateLoading={updateTreatmentsLoading}
                onDelete={() =>
                  dispatch(deleteConsultTreatments(consultId, [item.id]))
                }
                onDeleteLoading={deleteTreatmentsLoading}
              >
                {item.plan}
              </Tag>
            );
          })
        )}
        <Divider />
        <Form
          form={form}
          name='dynamic_form_item'
          className='form'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={{ fields: data }}
        >
          <p className='title'>Add New Treatment Plans</p>
          <Form.List name='fields'>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <Row gutter={26} align='bottom'>
                      <Col span={22}>
                        <Form.Item
                          name={[index, "plan"]}
                          rules={[
                            {
                              required: true,
                              message: "Treatment Instruction is required"
                            }
                          ]}
                        >
                          <Input
                            formItem={{
                              label: `Treatment Instruction`
                            }}
                            marginBottom={0}
                            placeholder='Enter Treatment Instruction'
                            label=''
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        {fields.length > 0 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ marginBottom: "25px" }}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                ))}
                <Form.Item style={{ textAlign: "right", marginTop: "40px" }}>
                  <Button className='btn-add' onClick={() => add()}>
                    <PlusOutlined />
                    Add another
                  </Button>
                  <Button htmlType='submit' type='primary'>
                    Save Changes
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <div style={{ textAlign: "center", width: "100%" }}></div>
        </Form>
      </TreatmentsModalWrapper>
    </Modal>
  );
};

export default TreatmentsModal;
