import { FilePreviewWrapper } from "./styles"

const FilePreview = (props: any) => {
    return (
        <FilePreviewWrapper>
            <img src={props.file} alt="img file" />
        </FilePreviewWrapper>
    )
}

export default FilePreview;