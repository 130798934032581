import styled from "styled-components";
import { NAV_SIDEBAR_WIDTH } from "constants/global";

interface MenuItemProps {
  active: boolean;
}

const SidebarWrapper = styled.aside`
  height: calc(100vh - 57px);
  margin-top: 57px;
  width: ${NAV_SIDEBAR_WIDTH}px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: left;
  z-index: 0;
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 1px 0px 5px rgba(0, 120, 226, 0.25);
  display: none;
`;

const SidebarMenuList = styled.ul`
  list-style-type: none;
  margin-top: 30px;
`;

const SidebarMenuListItem = styled.li<MenuItemProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  text-align: left;
  margin: 35px 0px;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${(props) => (props.active ? "#0078e2" : "#002e46")};
  }
  svg {
    margin-right: 15px;
  }
`;

export { SidebarWrapper, SidebarMenuList, SidebarMenuListItem };
