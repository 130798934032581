import styled from 'styled-components';


const InvestigationsModalWrapper = styled.div`
    /* overflow-y: scroll; */
    border-radius: 10px;
    height: 100%;
    .ant-row {
        height: 100%;
        /* overflow-y: scroll; */
    }
    .investigations-lhs {
        padding: 35px 30px;
        border-right: 1px solid #002E4640;
        span {
            text-align: left;
            font: normal normal normal 13px/14px DM Sans;
            letter-spacing: 0px;
            color: #4E667E;
            opacity: 0.65;
        }
        p {
            text-align: left;
            font: normal normal normal 13px/20px DM Sans;
            letter-spacing: 0px;
            color: #4E667E;
            opacity: 1;
            &.mb-23 {
                margin-bottom: 23px;
            }
        }
        .top {
            /* position: absolute; */
            /* top: 35px; */
            margin-bottom: 150px;
        }
        .lab-recommendations {
            position: absolute;
            padding-top: 20px;
            bottom: 60px;
            border-top: 0.800000011920929px solid #002E4640;
            p {
                text-align: left;
                font: normal normal medium 13px/14px DM Sans;
                letter-spacing: 0px;
                color: #0078E2;
                opacity: 1;
            }
        }
    }
    .investigations-rhs {
        padding: 35px 65px;
        height: 100%;
        .investigation-list-item {
            .investigation-list-item-header {
                display: flex;
                justify-content: space-between;
                text-align: left;
                font: normal normal normal 13px/28px DM Sans;
                letter-spacing: 0px;
                color: #002E46;
                cursor: pointer;
                opacity: 1;
                border-radius: 6px;
                border: 1px solid #002E4620;
                padding: 10px 15px;
                &.show {
                    background: #0078E2;
                    border-color: #0078E2;
                    color: #fff;
                }
            }
            .investigation-list-item-body {
                label {
                    font-size: 12px;
                    opacity: 0.7;
                }
                .ant-select .ant-select-selector {
                    min-height: 45px;
                    border-radius: 6px;
                    border-color: #0078E2;
                    font-size: 13px;
                }
                .ant-form-item {
                    margin-bottom: 0px;
                }
                .ant-row {
                    padding: 0px;
                }
                .ant-col {
                    display: flex;
                }
                &.show {
                    display: block;
                    margin-top: 5px;
                    overflow-y: scroll;
                }
                &.hidden {
                    height: 0px;
                    margin-top: 10px;
                    visibility: hidden;
                    opacity: 0;
                }
                .ant-checkbox-wrapper {
                    text-align: left;
                    font: normal normal normal 13px/13px DM Sans;
                    letter-spacing: 0px;
                    color: #002E46;
                    opacity: 0.75;
                    margin-bottom: 14px;
                    display: flex;
                }
            }
        }
    }
`;

const InvestigationsModalHeaderWrapper = styled.div`
    border-bottom: 0.800000011920929px solid #002E4640;
    padding: 22px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    p {
        text-align: left;
        font: normal normal normal 16px/19px DM Sans;
        letter-spacing: 0px;
        color: #002E46;
        opacity: 0.75;
        margin-bottom: 0px;
    }
`;

const InvestigationsModalBodyWrapper = styled.div`
    height: 100%;
    background: #fff;

    p {
        text-align: left;
        font: normal normal normal 16px/19px DM Sans;
        letter-spacing: 0px;
        color: #002E46;
        opacity: 0.75;
        margin-bottom: 0px;
    }
    .btn-submit {
        padding: 0px 50px;
        margin-top: 50px;
    }
`;

export { InvestigationsModalWrapper, InvestigationsModalHeaderWrapper, InvestigationsModalBodyWrapper };