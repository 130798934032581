export const ROUTES = Object.freeze({
    HOMEPAGE: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    TERMS_AND_CONDITIONS: '/terms-conditions',
    RECOVER_PASSWORD: '/password/recover',
    RESET_PASSWORD: '/password/reset',
    REQUEST_OTP: '/otp/request',
    ENTER_OTP: '/otp/enter',
    DASHBOARD: '/dashboard',
    QUESTIONS: '/questions',
    PROFILE: '/profile',
    ENCOUNTERS: '/encounters',
    PATIENT_ENCOUNTERS: '/encounters/:patientId'
});