import styled from 'styled-components';

const CanceledConsultsTableWrapper = styled.div`

`;

const CanceledConsultsTableHeader = styled.div`
    height: auto;
    display: flex;
    align-items: center;
    padding: 21px 26px 10px;
`;

const CanceledConsultsTableBody = styled.div`
    .ant-table {
        .ant-table-thead > tr > th {
            background: #f3f8fb;
            border-top: 0.5px solid rgba(78, 102, 126, 0.35);
            border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
        }
        .ant-table-tbody > tr > td {
            background: #fff;
        }
        tr {
            td:first-child, th:first-child {
                padding: 10px 40px;
            }
        }
        th.ant-table-cell {
            font-family: "DM Sans";
            font-weight: bold;
            font-size: 13px;
            line-height: 24px;
            text-align: left;
            color: #002e46;
            opacity: 0.75;
        }
        td.ant-table-cell {
            font-family: "DM Sans";
            font-weight: normal;
            font-size: 13px;
            line-height: 14px;
            text-align: left;
            color: #002e46;
            opacity: 0.75;
            .flex {
                display: flex;
                img {
                    margin-right: 10px;
                    border: 0.8px solid #0078e2;
                    border-radius: 50%;
                    height: 100%;
                    padding: 2px;
                }
            }
            .tag {
                background: #C1EEE4;
                padding: 5px 10px;
                font-family: "DM Sans";
                font-weight: 500;
                font-size: 13px;
                line-height: 14px;
                text-align: center;
                color: #0ABB94;
                opacity: 0.75;
                border-radius: 10px;
            }
            .action {
                img {
                    margin-left: 10px;
                }
            }
            p {
                margin-bottom: 5px;
            }
            span {
                font-family: "DM Sans";
                font-weight: normal;
                font-size: 13px;
                text-align: left;
                color: #0078e2;
                opacity: 0.75;
            }
        }
    }
`;

const TableHeader = styled.div`
    padding: 21px 26px 17px;
    background: #f3f8fb 0% 0% no-repeat padding-box;
    border-top: 0.5px solid rgba(78, 102, 126, 0.35);
    border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
    p {
        margin-bottom: 0px;
        text-align: left;
        font: normal normal medium 13px/24px DM Sans;
        letter-spacing: 0px;
        color: #002E46;
        opacity: 0.75;
    }
`;

const TableBody = styled.div`
    padding: 28px 14px 18px;
    border-top: 1px solid #f2f2f2;
`;

const TableRowItemWrapper = styled.div`
    padding: 16px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #002E4626;
    border-radius: 5px;
    margin-bottom: 14px;
    .flex {
        display: flex;
        justify-content: space-between;
        img {
            margin-right: 10px;
            border: 0.8px solid #0078e2;
            border-radius: 50%;
            /* height: 100%; */
            float: left;
            padding: 2px;
        }
    }
    p {
        margin-bottom: 0px;
        font: normal normal normal 13px/24px DM Sans;
        letter-spacing: 0px;
        color: #002E46;
        opacity: 0.75;
        &.name {
            float: left;
        }
    }
    .schedule-text {
        p {
            font: normal normal normal 13px/14px DM Sans;
            letter-spacing: 0px;
            color: #002E46;
            margin-bottom: 0px;
            opacity: 0.75;
        }
        span {
            font: normal normal normal 13px/24px DM Sans;
            letter-spacing: 0px;
            color: #002E46;
            opacity: 0.75;
        }
    }
    .col-3 {
        position: relative;
    }
    .view-link {
        position: absolute;
        bottom: 0px;
        right: 17px;
        text-align: right;
        font: normal normal normal 13px/24px DM Sans;
        letter-spacing: 0px;
        color: #0078E2;
        opacity: 1;
    }
    span {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #002E46BF;
        opacity: 0.75;
    }
    .tag {
        background: #C1EEE4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0ABB94;
        opacity: 0.75;
        border-radius: 10px;
    }
    .actions {
        text-align: center;
        font: normal normal normal 13px/13px DM Sans;
        letter-spacing: 0px;
        border-top: 0.5px solid #f2f2f2;
        margin-top: 24px;
        .accept {
            color: #0078E2;
            padding: 16px 10px;
            text-align: center;
            border-right: 0.5px solid #f2f2f2;
            cursor: pointer;
            &:hover {
                background: #f2f2f2;
            }
        }
        .decline {
            color: #FF2366;
            padding: 16px 10px;
            text-align: center;
            cursor: pointer;
            &:hover {
                background: #f2f2f2;
            }
        }
    }
`;

export { 
    CanceledConsultsTableWrapper, CanceledConsultsTableHeader, 
    CanceledConsultsTableBody, TableHeader, TableBody, TableRowItemWrapper
}