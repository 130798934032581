import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http from "../../utils/api";
import { fetchSingleConsult } from "./consults";
import { message } from "antd";

export interface QuestionError {
  message: string;
}

export interface QuestionState {
  question: any;
  questions: Array<any>;
  isQuestionsLoading: boolean;
  isAnswerQuestionsLoading: boolean;
  isDeleteConsultQuestionLoading: boolean;
  questionsError: QuestionError;
  answerQuestionsError: QuestionError;
  deleteConsultQuestionError: QuestionError;
}

export const initialState: QuestionState = {
  question: null,
  questions: [],
  isQuestionsLoading: false,
  isAnswerQuestionsLoading: false,
  isDeleteConsultQuestionLoading: false,
  questionsError: { message: "" },
  answerQuestionsError: { message: "" },
  deleteConsultQuestionError: { message: "" }
};

export const questionsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    fetchQuestionsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isQuestionsLoading = payload;
    },
    fetchQuestionsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.questions = payload;
    },
    fetchQuestionsFailed: (
      state,
      { payload }: PayloadAction<QuestionError>
    ) => {
      state.questionsError = payload;
    },
    answerQuestionsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isAnswerQuestionsLoading = payload;
    },
    answerQuestionsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      // state.isAddQuestionsLoading = payload;
    },
    answerQuestionsFailed: (
      state,
      { payload }: PayloadAction<QuestionError>
    ) => {
      state.answerQuestionsError = payload;
    },
    deleteConsultQuestionLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeleteConsultQuestionLoading = payload;
    },
    deleteConsultQuestionSuccess: (
      state,
      { payload }: PayloadAction<Boolean>
    ) => {
      // state.isAddQuestionsLoading = payload;
    },
    deleteConsultQuestionFailed: (
      state,
      { payload }: PayloadAction<QuestionError>
    ) => {
      state.deleteConsultQuestionError = payload;
    }
  }
});

export const {
  fetchQuestionsLoading,
  fetchQuestionsSuccess,
  fetchQuestionsFailed,
  answerQuestionsLoading,
  answerQuestionsSuccess,
  answerQuestionsFailed,
  deleteConsultQuestionLoading,
  deleteConsultQuestionSuccess,
  deleteConsultQuestionFailed
} = questionsSlice.actions;
export const questionSelector = (state: { questions: QuestionState }) =>
  state.questions;
export default questionsSlice.reducer;

/** Actions */

const environment =
  process.env.REACT_APP_ENVIRONMENT === "development" ? "sandbox" : "live";

export const fetchAllQuestions = (): AppThunk => async (dispatch) => {
  dispatch(fetchQuestionsLoading(true));
  await http
    .get(`/telehealth/queries?mode=${environment}`)
    .then((res) => {
      const questions = res?.data?.data;
      dispatch(fetchQuestionsSuccess(questions));
    })
    .catch((err) => {
      const message = { message: err?.response?.data?.message };
      dispatch(fetchQuestionsFailed(message));
    });
  dispatch(fetchQuestionsLoading(false));
};

export const answerQuestions =
  (questionId: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(answerQuestionsLoading(true));
    await http
      .post(`/telehealth/queries/${questionId}/answer`, payload)
      .then((res) => {
        const questions = res?.data?.data;
        dispatch(answerQuestionsSuccess(questions));
        message.success("Questions Answered Successfully");
        dispatch(fetchAllQuestions());
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.error || "An error occurred"
        };
        dispatch(answerQuestionsFailed(_message));
        message.error(err?.response?.data?.error || "An error occurred");
      });
    dispatch(answerQuestionsLoading(false));
  };

export const deleteConsultQuestion =
  (consultId: any, questionIds: any): AppThunk =>
  async (dispatch) => {
    dispatch(deleteConsultQuestionLoading(true));
    const payload: any = {
      questions: questionIds
    };
    await http
      .delete(`/consults/${consultId}/questions`, { data: payload })
      .then((res) => {
        const questions = res?.data?.data;
        dispatch(deleteConsultQuestionSuccess(questions));
        message.success("Questions Deleted from Consult Successfully");
        dispatch(fetchSingleConsult(consultId));
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(deleteConsultQuestionFailed(_message));
        message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(deleteConsultQuestionLoading(false));
  };
