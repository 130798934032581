import styled from 'styled-components';


const TreatmentPlanWrapper = styled.div`
    padding: 22px 20px;
    /* min-height: calc(100vh - 142px);
    max-height: calc(100vh - 142px); */
    overflow-y: auto;
    height: 100%;
    img {
        margin-right: 5px;
    }
`;

export { TreatmentPlanWrapper };