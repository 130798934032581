import { useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Wrapper } from "./styles";

import PatientEncounters from "screens/PatientEncounters";

type Props = {
  isChatPaneVisible: boolean;
  isEncounterFormVisible: boolean;
  setIsChatPaneVisible: (val: boolean) => void;
  setIsEncounterFormVisible: (val: boolean) => void;
  showConsultDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

function EncounterDetail({
  isChatPaneVisible,
  isEncounterFormVisible,
  setIsChatPaneVisible,
  setIsEncounterFormVisible,
  showConsultDetails
}: Props) {
  const params: { patientId: string } = useParams();

  return (
    <Wrapper>
      <span onClick={() => showConsultDetails(false)}>
        <ArrowLeftOutlined />
        Back
      </span>

      {params?.patientId ? (
        <PatientEncounters
          isChatPaneVisible={isChatPaneVisible}
          isEncounterFormVisible={isEncounterFormVisible}
          setIsChatPaneVisible={setIsChatPaneVisible}
          setIsEncounterFormVisible={setIsEncounterFormVisible}
        />
      ) : (
        <div className='empty-state'>
          <h3>No consult selected</h3>
          <p>Select a consult to access it's details</p>
        </div>
      )}
    </Wrapper>
  );
}

export default EncounterDetail;
