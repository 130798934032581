import Card from "../../../../component/Card";
import {
  QuestionsTableWrapper,
  QuestionsTableBody,
  PaginationWrapper
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import AvatarImg from "../../../../component/Avatar";
import { Pagination, Row, Col, Tag, Space, Grid } from "antd";
import TextArea from "../../../../component/TextArea";
import Button from "../../../../component/Button";
import {
  fetchAllQuestions,
  questionSelector,
  answerQuestions
} from "../../../../redux/reducers/questions";
import Form from "antd/lib/form/Form";
import Loader from "../../../../component/Loader";
import Typography from "component/Typography";
import CommonCard from "common/Card";
import { Mixpanel } from "utils/mixpanel";

const QuestionsTable = (props: any) => {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const DEFAULT_PAGE_SIZE = 5;

  const { questions, isQuestionsLoading, isAnswerQuestionsLoading } =
    useSelector(questionSelector);

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    dispatch(fetchAllQuestions());
  }, [dispatch]);

  const handleAnswerQuestions = async (
    questionId: string | number,
    fields: any
  ) => {
    const payload = fields;
    setSelectedId(questionId);
    await dispatch(answerQuestions(questionId, payload));
    Mixpanel.track("answered_question", { question: questionId });
    setSelectedId(null);
  };

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    window.scrollTo(0, 0);
  };

  const paginationLabel =
    questions?.length > 0 &&
    `(Page ${pageNumber} of ${Math.ceil(
      questions.length / DEFAULT_PAGE_SIZE
    )})`;
  const startPageCount = (pageNumber - 1) * DEFAULT_PAGE_SIZE;
  return (
    <>
      <Row justify='space-between' align='middle'>
        <h3>All Questions {paginationLabel}</h3>
      </Row>
      <Card marginBottom={15} padding={16} style={{ marginTop: "20px" }}>
        <QuestionsTableWrapper>
          <QuestionsTableBody>
            {isQuestionsLoading ? (
              <Loader />
            ) : questions.length === 0 ? (
              "No questions asked yet"
            ) : (
              <Row gutter={[0, 0]}>
                {questions
                  .slice(startPageCount, startPageCount + DEFAULT_PAGE_SIZE)
                  .map(
                    (
                      { id, question, answer, patient, partner, created_at },
                      index
                    ) => {
                      const { first_name, last_name } = patient;

                      return (
                        <Col key={index} span={24}>
                          <CommonCard bodyStyle={{ padding: 16 }}>
                            <Space style={{ marginBottom: 24 }}>
                              <AvatarImg
                                img=''
                                style={{ marginRight: 10, float: "left" }}
                                fallbackText={`${first_name} ${last_name}`}
                              />
                              <div>
                                <Typography>
                                  {first_name} {last_name}
                                </Typography>
                                <Typography variant='body2'>
                                  {partner?.business_name}
                                </Typography>
                              </div>
                            </Space>
                            <Typography
                              display='block'
                              variant='caption'
                              color='textSecondary'
                              style={{ fontStyle: "italic" }}
                            >
                              Asked on {moment(created_at).format("LL")}
                            </Typography>
                            <Typography weight='bold' gutterBottom>
                              {question}
                            </Typography>
                            {!answer ? (
                              <Form
                                onFinish={(fields: any) =>
                                  handleAnswerQuestions(id, fields)
                                }
                              >
                                <TextArea
                                  formItem={{
                                    label: "",
                                    name: "answer",
                                    rules: [
                                      {
                                        required: true,
                                        message: "Please input your answer"
                                      }
                                    ]
                                  }}
                                  placeholder='Type answer here'
                                />
                                <div
                                  style={{
                                    textAlign: breakpoints.sm
                                      ? "right"
                                      : "center"
                                  }}
                                >
                                  <Button
                                    htmlType='submit'
                                    type='primary'
                                    disabled={isAnswerQuestionsLoading}
                                  >
                                    {isAnswerQuestionsLoading &&
                                    selectedId === id
                                      ? "Submitting.."
                                      : "Submit Answer"}
                                  </Button>
                                </div>
                              </Form>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "10px"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <AvatarImg
                                      img=''
                                      style={{ marginRight: 10, float: "left" }}
                                      fallbackText={"John Doe"}
                                    />
                                    <p className='name'>Kelechi Uche</p>
                                  </div>
                                  <Tag color='green'>Answer</Tag>
                                </div>
                                <p className='text'>{answer}</p>
                                <i>Answered on Sept 2nd, 2021</i>
                              </>
                            )}
                          </CommonCard>
                        </Col>
                      );
                    }
                  )}
              </Row>
            )}
          </QuestionsTableBody>
          {!isQuestionsLoading && questions?.length > 0 && (
            <PaginationWrapper>
              <Pagination
                responsive
                defaultCurrent={1}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                onChange={handlePageChange}
                total={questions.length || 0}
              />
            </PaginationWrapper>
          )}
        </QuestionsTableWrapper>
      </Card>
    </>
  );
};

export default QuestionsTable;
