import styled from 'styled-components';


const DiagnosisModalWrapper = styled.div`
    padding: 20px 50px 51px !important;

    p.title {
        font-size: 13px;
    }
    input {
        font-size: 13px;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
        font-size: 13px;
    }
    .ant-form-item-label label {
        color: #333 !important;
        /* font-weight: 400; */
        text-transform: uppercase;
        font-size: 10px;
    }
`;


export { DiagnosisModalWrapper };