import { DiagnosisModalWrapper } from "./styles";
import {
  Col,
  Modal,
  Row,
  Select as AntdSelect,
  Form,
  Spin,
  Divider,
} from "antd";
import Select from "../../../../component/Select";
import Button from "../../../../component/Button";
import {
  diagnosisSelector,
  fetchAllDiagnoses,
  addConsultDiagnoses,
  deleteConsultDiagnoses,
} from "../../../../redux/reducers/diagnoses";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useEffect, useState } from "react";
import Tag from "../../../../component/Tag";
import { useForm } from "antd/lib/form/Form";

const DiagnosisModal = (props: any) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);

  const {
    diagnoses,
    isDiagnosesLoading,
    addDiagnosesLoading,
    diagnosesError,
    deleteDiagnosesLoading,
  } = useSelector(diagnosisSelector);

  const {
    consultId,
    formDiagnosis,
    isDiagnosisModalVisible,
    setIsDiagnosisModalVisible,
  } = props;

  useEffect(() => {
    dispatch(fetchAllDiagnoses());
  }, [dispatch]);

  useEffect(() => {
    if (formDiagnosis.length === 0) {
      setData([
        {
          symptom: "",
          number_of_days: "",
        },
      ]);
    } else {
      const formattedDianosisData = formDiagnosis.map((item: any) => {
        return { id: item.id, condition: item.condition };
      });
      setData(formattedDianosisData);
    }
  }, [formDiagnosis]);

  const handleSubmit = async (values: any) => {
    const { diagnosis: newDiagnosis } = values;

    const formattedPayload = newDiagnosis.map((id: any) => {
      return {
        diagnosis: id,
      };
    });

    await dispatch(
      addConsultDiagnoses(consultId, { diagnosis: formattedPayload })
    );
    setIsDiagnosisModalVisible(false);
    form.resetFields();
  };

  const unSelectedDiagnosis = diagnoses?.filter((item) => {
    const itemExists = formDiagnosis.filter((dataItem: any) => {
      return item.id === dataItem.diagnosis_id;
    })[0];
    return !itemExists;
  });

  return (
    <Modal
      title="Diagnosis"
      visible={isDiagnosisModalVisible}
      centered
      cancelText="Cancel"
      onOk={handleSubmit}
      footer={null}
      okText="Add Diagnosis"
      onCancel={() => setIsDiagnosisModalVisible(false)}
      className="examination-modal main-modal"
    >
      <DiagnosisModalWrapper>
        <p className="title">Existing Diagnosis</p>
        {formDiagnosis.length === 0 ? (
          <p className="sub-text">No diagnosis added yet</p>
        ) : (
          formDiagnosis.map((item: any, index: number) => {
            return (
              <Tag
                key={index}
                isDeletable={true}
                text={item.condition}
                onDelete={() =>
                  dispatch(deleteConsultDiagnoses(consultId, [item.id]))
                }
                onDeleteLoading={deleteDiagnosesLoading}
              >
                {item.condition}
              </Tag>
            );
          })
        )}
        <Divider />
        <Form
          form={form}
          name="dynamic_form_item"
          className="form"
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={{ diagnosis: data.map((item) => item.id) }}
        >
          <p className="title">Add New Diagnosis</p>
          <Row>
            <Col span={24}>
              <Select
                formItem={{
                  label: "",
                  name: "diagnosis",
                  rules: [
                    { required: true, message: "Please input the diagnosis" },
                  ],
                }}
                mode="multiple"
                notFoundContent={
                  isDiagnosesLoading ? <Spin size="small" /> : null
                }
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Select a diagnosis"
                label=""
                required
              >
                {unSelectedDiagnosis.map((item, index) => {
                  return (
                    <AntdSelect.Option key={index} value={item.id}>
                      {item.condition}
                    </AntdSelect.Option>
                  );
                })}
              </Select>
            </Col>
            <div style={{ textAlign: "center", width: "100%" }}>
              {addDiagnosesLoading ? (
                <Button type="primary">Adding Diagnosis...</Button>
              ) : (
                <Button htmlType="submit" type="primary">
                  Add Diagnosis
                </Button>
              )}
            </div>
          </Row>
        </Form>
      </DiagnosisModalWrapper>
    </Modal>
  );
};

export default DiagnosisModal;
