import firebase from "firebase";

const firebaseConfigDev = {
  apiKey: "AIzaSyAAkAcEWRDyi2dD4mblv6HTh6Pz3-s87Fc",
  authDomain: "pneumaconnect-providers-5e00f.firebaseapp.com",
  projectId: "pneumaconnect-providers-5e00f",
  storageBucket: "pneumaconnect-providers-5e00f.appspot.com",
  messagingSenderId: "384151511833",
  appId: "1:384151511833:web:87754c770bdb206c8e9b88",
  measurementId: "G-41M7NCN72H"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyAYSH-LWNi54pAuaC7eX6asNIqejhhJNYY",
  authDomain: "provider-dashboard-prod.firebaseapp.com",
  projectId: "provider-dashboard-prod",
  storageBucket: "provider-dashboard-prod.appspot.com",
  messagingSenderId: "490950793000",
  appId: "1:490950793000:web:cc5b1933296623d5b4621d",
  measurementId: "G-71WW958B4P"
};

firebase.initializeApp(
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? firebaseConfigDev
    : firebaseConfigProd
);

// Get a reference to the storage service, which is used to create references in your storage bucket
const Storage = firebase.storage();

export default Storage;
