import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type GlobalSlice = {
  isSidebar: boolean;
  isLoadingSpin: boolean;
  user: {};
  isLaboratory: boolean;
};

const initialState = {
  isSidebar: false,
  isLoadingSpin: false,
  user: {},
  isLaboratory: false
} as GlobalSlice;

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    toggleSidebarAction: (state, { payload }: PayloadAction<undefined>) => {
      state.isSidebar = payload || !state.isSidebar;
    },
    toggleLoadingSpinAction: (state, { payload }) => {
      state.isLoadingSpin =
        payload !== undefined ? !!payload : !state.isLoadingSpin;
    }
  }
});

export const { toggleSidebarAction, toggleLoadingSpinAction } =
  globalSlice.actions;

export default globalSlice.reducer;
