import { message } from "antd";
import Swal from "sweetalert2";
import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";

const accessToken = localStorage.getItem("accessToken");

export const baseURL = "https://provider-api.pneumahealth.co";

const http: AxiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${accessToken}` }
});

http.defaults.headers.post["Content-Type"] = "application/json";

http.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 401 &&
        response?.data?.error === "Token expired"
      ) {
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
          text: "Your session token has expired, please login again!"
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

const logOutProvider = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("pneumaCurrentUser");
  window.location.replace("/");
};

export default http;
