import styled from "styled-components";

interface ChatBubbleProps {
  type: string;
}

const EncountersFormWrapper = styled.div`
  position: fixed;
  left: 20px;
  right: 56px;
  bottom: 0px;
  max-width: 451px;
  z-index: 2;
  .ant-card {
    border-radius: 5px;
  }
  @media (min-width: 451px) {
    left: auto;
    width: 100%;
  }
  @media (min-width: 992px) {
    left: auto;
  }
`;

const EncountersFormHeaderWrapper = styled.div`
  padding: 18px 24px;
  background: #0078e2 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  .title {
    padding-left: 24px;
    padding-right: 24px;
    color: #fff;
  }
  p {
    margin-bottom: 0px;
    text-align: left;
    font: normal normal medium 13px/14px DM Sans;
    letter-spacing: 0px;
    color: #fff;
  }
  .btn-action {
    background: #fff;
    border-radius: 3px;
    padding: 8px 13px;
    text-align: center;
    font: normal normal medium 13px/13px DM Sans;
    letter-spacing: 0px;
    color: #0078e2;
    opacity: 1;
  }
`;

const EncountersFormBodyWrapper = styled.div`
  min-height: calc(100vh - 206px);
  max-height: calc(100vh - 206px);
  background: #fff;
  padding: 0px;
  overflow-y: scroll;

  .intro-details {
    border-bottom: 1px solid #002e460d;
    padding: 10px 22px 15px;
  }

  .details {
    padding: 14px 22px 18px;
    border-bottom: 1px solid #002e460d;
  }

  .date-title {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
    letter-spacing: 0px;
    color: #4e667e;
  }

  span {
    font-size: 13px;
    background: #fff;
  }

  span.link {
    text-align: right;
    font: normal normal normal 13px/24px DM Sans;
    font-weight: 00;
    letter-spacing: 0px;
    color: #0078e2;
    opacity: 1;
    cursor: pointer;
  }

  p.link-disabled,
  span.link-disabled {
    text-align: right;
    font: normal normal normal 13px/24px DM Sans;
    font-weight: 00;
    letter-spacing: 0px;
    color: #0078e2;
    opacity: 0.5;
    cursor: not-allowed;
  }

  p {
    text-align: left;
    font: normal normal normal 13px/24px DM Sans;
    letter-spacing: 0px;
    color: #4e667e;
    margin-bottom: 0px;
    &.title {
      font-weight: 400;
      color: #0078e2;
    }
    &.link {
      text-align: right;
      font: normal normal medium 13px/24px DM Sans;
      letter-spacing: 0px;
      color: #0078e2;
      opacity: 1;
      cursor: pointer;
    }
    &.sub-text {
      font: normal normal normal 13px/18px DM Sans;
      letter-spacing: 0px;
      color: #4e667e;
      opacity: 1;
    }
    &.text-right {
      text-align: right;
    }
  }
  .action-row {
    padding: 33px 22px;
  }
  .ant-switch {
    margin-right: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  textarea.ant-input {
    border-radius: 5px;
    font-size: 13px;
  }
  .tags-pane {
    margin-top: 10px;
    .tag {
      font-size: 13px;
      padding: 5px 10px;
      background: #f2f2f2;
      margin-right: 5px;
      margin-bottom: 5px;
      border-radius: 10px;
      display: inline-block;
    }
    .medication-tag {
      font-size: 13px;
      padding: 5px 10px;
      background: #f2f2f2;
      margin-right: 5px;
      margin-bottom: 5px;
      border-radius: 10px;
      display: inline-block;
    }
    .price-tag {
      margin-bottom: 5px;
      text-align: right;
    }
  }
  .btn-save {
    font-size: 13px;
    float: right;
    padding: 5px 20px;
    border: none;
    background: #0078e2;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
  }
  .btn-edit {
    font-size: 13px;
    float: right;
    padding: 5px 20px;
    border: none;
    background: #0078e2;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
  }
`;

export {
  EncountersFormWrapper,
  EncountersFormHeaderWrapper,
  EncountersFormBodyWrapper,
};
