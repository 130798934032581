import styled from "styled-components";

interface ChatBubbleProps {
  type: string;
}

const ChatPaneWrapper = styled.div`
  position: fixed;
  left: 20px;
  right: 56px;
  bottom: 20px;
  max-width: 451px;
  z-index: 2;
  .ant-card {
    box-shadow: 0px 3px 15px #002e4640;
    border-radius: 5px;
  }
  @media (min-width: 451px) {
    left: auto;
    width: 100%;
  }
  @media (min-width: 992px) {
    right: 507px;
    left: auto;
  }
`;

const ChatPaneHeaderWrapper = styled.div`
  padding: 18px 24px;
  background: #0078e2 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  .title {
    padding-left: 24px;
    padding-right: 24px;
    color: #fff;
  }
  p {
    margin-bottom: 0px;
    text-align: left;
    font: normal normal medium 13px/14px DM Sans;
    letter-spacing: 0px;
    color: #fff;
  }
  .dot-actions {
    text-align: right;
    .dots {
      color: #fff;
      cursor: pointer;
      margin-left: 10px;
    }
    .dot {
      height: 10px;
      margin-left: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid #fff;
      background: #fff;
      &.minimize {
        background: yellow;
      }
      &.maximize {
        background: green;
      }
      &.close {
        background: crimson;
      }
    }
  }
`;

const ChatPaneBodyWrapper = styled.div`
  margin-bottom: 19px;
  min-height: calc(100vh - 245px);
  max-height: calc(100vh - 245px);
  background: #fff;
  padding: 10px 25px 10px;
  overflow-y: scroll;

  .date-title {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
    letter-spacing: 0px;
    color: #4e667e;
  }

  .date-title span {
    font-size: 13px;
    background: #fff;
    padding: 0 10px;
  }
`;

const ChatBubbleWrapper = styled.div<ChatBubbleProps>`
  text-align: ${(props) => (props.type === "provider" ? "right" : "left")};
  .author {
    span {
      text-align: ${(props) => (props.type === "provider" ? "right" : "left")};
      font: normal normal normal 13px/14px DM Sans;
      letter-spacing: 0px;
      color: #4e667e;
      padding-left: 5px;
      &.time {
        opacity: 0.75;
      }
    }
  }
  .content {
    max-width: 80%;
    text-align: end;
    display: inline-block;
    border-radius: 5px 0px 5px 5px;
    white-space: pre-line;
    width: auto;
    clear: auto;
    padding: 8px 13px;
    padding-right: 13px;
    margin-bottom: 13px;
    background: ${(props) =>
        props.type === "sender" ? "#0078E2 " : "#002E460D "}
      0% 0% no-repeat padding-box;
    color: ${(props) => (props.type === "sender" ? "#fff " : "#002E46 ")};

    text-align: left;
    position: relative;
    font: normal normal normal 13px/17px DM Sans;
    letter-spacing: 0px;

    a {
      word-break: break-all;
      white-space: pre-line;
    }

    .fallback {
      background: #fff;
      border: 1px solid #a3a3a3;
      width: 100%;
      padding: 10px;
      border-radius: 6px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .extra-options {
      position: absolute;
      top: 0px;
      right: 10px;
    }
  }
`;

const ChatPaneInputWrapper = styled.form`
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
  background: #fff;
  display: flex;
  border-top: 2px solid #f3f3f3;
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    height: auto;
    display: flex;
    cursor: pointer;

    .chat-attachments {
      display: inline-block;
      cursor: pointer;
    }
    input[type="file"] {
      cursor: pointer;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }

  img {
    cursor: pointer;
  }
  textarea {
    flex: 1;
    width: 100%;
    height: 50px;
    min-height: 50px;
    padding: 15px 10px;
    border: none;
    font-size: 13px;
    resize: none;
    max-height: 100px;
    /* overflow-y: hidden; */
    &:focus {
      outline: none;
    }
  }
`;

export const TypingIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  margin-bottom: 4px;

  h6 {
    font-family: "Figtree", sans-serif;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    font-style: italic;
    color: #64748b;
  }
`;

export {
  ChatPaneWrapper,
  ChatPaneHeaderWrapper,
  ChatPaneBodyWrapper,
  ChatBubbleWrapper,
  ChatPaneInputWrapper
};
