import Card from "../../../../component/Card";
import { PatientProfileWrapper, DataItemWrapper } from "./styles";
import PatientAvatarImg from "../../../../assets/images/patient-avatar.svg";
import { Col, Form, Row, Skeleton, Select as AntdSelect, Space } from "antd";
import { useState } from "react";
import Select from "../../../../component/Select";
import FamilyHistoryModal from "../FamilyHistoryModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePatientProfile,
  updatePatientProfile,
} from "../../../../redux/reducers/consults";
import getAge from "../../../../utils/utils";
import { medicationSelector } from "../../../../redux/reducers/medications";
import lodash from "lodash";
import AvatarImg from "../../../../component/Avatar";

const { Option } = AntdSelect;

const genotypeList = ["AA", "AS", "AC", "SS"];
const bloodGroupList = ["O-", "O+", "A-", "A+", "B-", "B+", "AB-", "AB+"];
const habits = [
  "Never",
  "everyday",
  "occasionally",
  "few times every week",
  "few times a month",
  "few times a year",
];

const PatientProfile = (props: any) => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isFamilyHistoryModalVisible, setIsFamilyHistoryModalVisible] =
    useState<boolean>(false);

  const {
    medications: medicationList,
    isMedicationsLoading,
    medicationsError,
  } = useSelector(medicationSelector);

  const renderView = (editView: any, noEditView: any) => {
    return isEditMode ? editView : noEditView;
  };

  const {
    id,
    first_name,
    last_name,
    gender,
    dob,
    occupation,
    address,
    blood_group,
    medical_record,
    genotype,
    height,
    weight,
  } = props.data || {};

  const { drinking_habit, smoking_habit, family_medical_history, medications } =
    medical_record || {};

  const { street_line_one } = address || {};

  const cleanupPayload = (obj: any) => {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === "" ||
        (Array.isArray(obj[propName]) && obj[propName].length === 0)
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const handleEdit = async (values: any) => {
    const mainMedications = medications;
    const { medications: formMedications } = values;

    const medicationsToRemove = lodash.difference(
      mainMedications,
      formMedications
    );
    const medicationsToAdd = lodash.difference(
      formMedications,
      mainMedications
    );

    const payload = { ...values, medications: medicationsToAdd };

    await dispatch(updatePatientProfile(id, payload));
    if (medicationsToRemove.length > 0) {
      await dispatch(
        deletePatientProfile(id, {
          medications: medicationsToRemove,
        })
      );
    }
    setIsEditMode(!isEditMode);
  };

  const heightInMetres = (height || 0) * 0.3048;

  return (
    <PatientProfileWrapper>
      <Form
        name="basic"
        layout="vertical"
        requiredMark={false}
        initialValues={props.data}
        onFinish={handleEdit}
      >
        <Row justify="space-between">
          <Col span={12}>
            <p className="title-text">Patient Profile</p>
          </Col>
          <Col span={12}>
            {isEditMode ? (
              <button type="submit" className="edit-btn">
                Update
              </button>
            ) : (
              <p
                className="edit-text"
                onClick={() => setIsEditMode(!isEditMode)}
              >
                Edit
              </p>
            )}
          </Col>
        </Row>
        {props.loading ? (
          <LoadingProfile />
        ) : (
          <>
            <Row gutter={12} align="middle" className="avatar-header">
              <Col span={24}>
                <Space>
                  <AvatarImg
                    img=""
                    style={{ marginRight: 5 }}
                    fallbackText={`${first_name} ${last_name}`}
                  />
                  <div>
                    <span>
                      {first_name} {last_name || "—"}
                    </span>
                    <p style={{ textTransform: "capitalize" }}>
                      {gender} {getAge(dob)}
                    </p>
                  </div>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DataItemWrapper>
                  <span>Date of Birth</span>
                  <p>{dob || "—"}</p>
                </DataItemWrapper>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DataItemWrapper>
                  <span>Occupation</span>
                  <p>{occupation || "—"}</p>
                </DataItemWrapper>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DataItemWrapper>
                  <span>Street Line One</span>
                  <p>{street_line_one || "—"}</p>
                </DataItemWrapper>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={8}>
                <DataItemWrapper>
                  <span>Weight</span>
                  <p>{weight ? `${weight}kg` : "—"}</p>
                </DataItemWrapper>
              </Col>
              <Col span={8}>
                <DataItemWrapper>
                  <span>Height</span>
                  <p>{height ? `${height}ft` : "—"}</p>
                </DataItemWrapper>
              </Col>
              <Col span={8}>
                <DataItemWrapper>
                  <span>BMI</span>
                  <p>
                    {weight && height
                      ? `${(weight / heightInMetres ** 2).toFixed(2)}`
                      : "—"}
                  </p>
                </DataItemWrapper>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DataItemWrapper>
                  <span>Blood group</span>
                  <p>{blood_group || "—"}</p>
                </DataItemWrapper>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DataItemWrapper>
                  <span>Genotype</span>
                  <p>{genotype || "—"}</p>
                </DataItemWrapper>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DataItemWrapper>
                  {!isEditMode && <span>Medication history</span>}
                  {renderView(
                    <Form.Item
                      name="medications"
                      initialValue={medications || []}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        formItem={{
                          label: "Medications",
                        }}
                        loading={isMedicationsLoading}
                        marginBottom={0}
                        mode="tags"
                        placeholder="Enter medication history"
                      >
                        {medicationList.map((item) => {
                          return <Option value={item.name}>{item.name}</Option>;
                        })}
                      </Select>
                    </Form.Item>,
                    <>
                      <p>{medications?.join("\n") || "—"}</p>
                    </>
                  )}
                </DataItemWrapper>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DataItemWrapper>
                  {!isEditMode && <span>Drinking History</span>}
                  {renderView(
                    <Form.Item
                      name="drinking_habit"
                      initialValue={drinking_habit || ""}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        formItem={{
                          label: "Drinking History",
                        }}
                        marginBottom={0}
                        placeholder="Enter drinking habit"
                        required
                      >
                        {habits.map((item) => (
                          <Option value={item}>{item}</Option>
                        ))}
                      </Select>
                    </Form.Item>,
                    <p>{drinking_habit || "—"}</p>
                  )}
                </DataItemWrapper>
              </Col>
              <Col span={24}>
                <DataItemWrapper>
                  {!isEditMode && <span>Smoking History</span>}
                  {renderView(
                    <Form.Item
                      name="smoking_habit"
                      initialValue={smoking_habit || ""}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        formItem={{
                          label: "Smoking History",
                        }}
                        marginBottom={0}
                        placeholder="Enter smoking history"
                        required
                      >
                        {habits.map((item) => (
                          <Option value={item}>{item}</Option>
                        ))}
                      </Select>
                    </Form.Item>,
                    <p>{smoking_habit || "—"}</p>
                  )}
                </DataItemWrapper>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DataItemWrapper>
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <span>Family history</span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsFamilyHistoryModalVisible(true)}
                    >
                      Edit
                    </span>
                  </Row>
                  <>
                    {family_medical_history?.map((item: any) => {
                      const [key] = Object.keys(item);
                      return (
                        <p>
                          <b>{key}</b> in {item[key].join(", ")}
                        </p>
                      );
                    }) || "—"}
                  </>
                </DataItemWrapper>
              </Col>
            </Row>
          </>
        )}
      </Form>
      <FamilyHistoryModal
        patientID={id}
        medicalHistory={family_medical_history}
        isFamilyHistoryModalVisible={isFamilyHistoryModalVisible}
        setIsFamilyHistoryModalVisible={setIsFamilyHistoryModalVisible}
      />
    </PatientProfileWrapper>
  );
};

export default PatientProfile;

const LoadingProfile = () => (
  <Row>
    <Col span={24} style={{ marginTop: "20px" }}>
      <Skeleton avatar={{ size: 60 }} paragraph={{ rows: 0 }} active={true} />
    </Col>
    <Col span={24} style={{ marginTop: "30px" }}>
      <Skeleton paragraph={{ rows: 10 }} active={true} />
    </Col>
  </Row>
);
