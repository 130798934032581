import { Grid, Drawer, Row } from "antd";
import { Container, PaneFabToggle, Content, Pane } from "./styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import useToggle from "hooks/useToggle";

export type ScreenLayoutProps = {
  pane?: any;
  children?: any;
  fabIcon?: any;
  className?: string;
  panePlacement?: "left" | "right";
  paneContentWrapperStyle?: any;
  paneBodyWrapperStyle?: any;
};

function ScreenLayout(props: ScreenLayoutProps) {
  const {
    pane,
    children,
    fabIcon,
    className,
    panePlacement,
    paneContentWrapperStyle,
    paneBodyWrapperStyle,
  } = props;

  const [isPane, togglePane] = useToggle();

  const isPaneLeft = panePlacement === "left";
  const isPaneRight = panePlacement === "right";

  const paneFabToggleIcon =
    fabIcon || (isPaneLeft ? <RightOutlined /> : <LeftOutlined />);

  const breakpoints = Grid.useBreakpoint();
  const isXl = breakpoints.xl;

  const fabStyle = isPaneLeft
    ? {
        bottom: "calc(100% - 50%)",
        left: "0px",
        borderRadius: "0px 20px 20px 0px",
      }
    : {
        bottom: "calc(100% - 50%)",
        right: "0px",
        borderRadius: "20px 0px 0px 20px",
      };

  const CONTENT = (
    <Content span={isXl && pane ? 16 : 24} className={className}>
      {children}
    </Content>
  );

  return (
    <Container>
      <Row gutter={16} style={{ height: "100%" }}>
        {isPaneRight && CONTENT}
        {isXl && pane ? (
          <Pane span={isXl && pane ? 8 : 0}>{pane}</Pane>
        ) : (
          <Drawer
            bodyStyle={{ padding: 0, ...paneBodyWrapperStyle }}
            contentWrapperStyle={{
              width: "calc(100% - 76px)",
              minWidth: 300,
              maxWidth: 400,
              ...paneContentWrapperStyle,
            }}
            visible={isPane}
            onClose={togglePane}
            placement={panePlacement}
          >
            {pane}
          </Drawer>
        )}
        {isPaneLeft && CONTENT}
        {!isXl && pane && (
          <PaneFabToggle style={fabStyle} size="large" onClick={togglePane}>
            {paneFabToggleIcon}
          </PaneFabToggle>
        )}
      </Row>
    </Container>
  );
}

ScreenLayout.defaultProps = {
  panePlacement: "right",
};

export default ScreenLayout;
