import styled from "styled-components";

const EncountersWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const EncountersHeaderWrapper = styled.div`
  padding: 18px 24px;
  height: 57px;
  .nav {
    padding-left: 24px;
    padding-right: 24px;
  }
  p {
    margin-bottom: 0px;
    text-align: left;
    font: normal normal medium 13px/14px DM Sans;
    letter-spacing: 0px;
    color: #002e46;
  }
`;

const EncountersBodyWrapper = styled.div`
  flex: 1;
  min-height: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  span {
    text-align: left;
    text-align: left;
    font: normal normal medium 13px/24px DM Sans;
    letter-spacing: 0px;
    color: #4e667ea8;
    opacity: 0.65;
  }
  p {
    text-align: left;
    font: normal normal normal 13px/18px DM Sans;
    letter-spacing: 0px;
    color: #4e667e;
    .active {
      color: #0078e2;
    }
  }
  .overflow-scroll {
    overflow-y: auto;
    min-height: calc(100vh - 142px);
    max-height: calc(100vh - 142px);
  }
`;

const EncountersListWrapper = styled.div`
  height: 100%;
  background: #f3f8fb;
  /* background: transparent !important; */
  overflow-y: auto;
  overflow-x: hidden;
`;

export {
  EncountersWrapper,
  EncountersHeaderWrapper,
  EncountersBodyWrapper,
  EncountersListWrapper
};
