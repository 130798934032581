import { SymptomsModalWrapper, SymptomsModalBodyWrapper } from "./styles";
import { Col, Modal, Row, Form, Select as AntdSelect, Spin } from "antd";
import { useEffect, useState } from "react";
import Button from "../../../../component/Button";
import Select from "../../../../component/Select";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Input from "../../../../component/Input";
import {
  fetchAllSymptoms,
  addConsultSymptoms,
  symptomSelector,
  deleteConsultSymptom,
} from "../../../../redux/reducers/symptoms";
import { useDispatch, useSelector } from "react-redux";

const SymptomsModal = (props: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [currentFormObjectIds, setCurrentFormObjectIds] = useState<any[]>([]);
  const {
    consultId,
    formSymptoms,
    isSymptomsModalVisible,
    setIsSymptomsModalVisible,
  } = props;

  const {
    symptoms,
    isSymptomsLoading,
    isAddConsultSymptomsLoading,
    isDeleteConsultSymptomLoading,
  } = useSelector(symptomSelector);

  useEffect(() => {
    dispatch(fetchAllSymptoms());
  }, [dispatch]);

  useEffect(() => {
    if (formSymptoms.length === 0) {
      setData([
        {
          id: "",
          symptom_id: "",
          number_of_days: "",
        },
      ]);
    } else {
      const formattedSymptomsData = formSymptoms.map((item: any) => {
        return {
          id: item.id,
          symptom_id: item.symptom_id,
          number_of_days: item.number_of_days,
        };
      });
      setData(formattedSymptomsData);
      setCurrentFormObjectIds(
        formattedSymptomsData.map((item: any) => item.symptom_id)
      );
    }
  }, [formSymptoms, isSymptomsModalVisible]);

  const updateFormObjects = (value: any, index: number) => {
    const _currentFormObjectIds = [...currentFormObjectIds];
    _currentFormObjectIds[index] = value;
    setCurrentFormObjectIds(_currentFormObjectIds);
  };

  const jsonEqual = (a: any, b: any) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const deepComparison = (a: any, b: any) => {
    return a.filter(
      (item1: any) => !b.some((item2: any) => jsonEqual(item1, item2))
    );
  };

  const handleSubmit = async (values: any) => {
    const { fields } = values;

    const pickedFields = fields.map(({ symptom_id, number_of_days }: any) => {
      return { symptom_id, number_of_days };
    });
    const pickedFormSymptoms = formSymptoms.map(
      ({ symptom_id, number_of_days }: any) => {
        return { symptom_id, number_of_days };
      }
    );

    const differencesA = deepComparison(pickedFormSymptoms, pickedFields);

    const symptomsToAdd = deepComparison(pickedFields, pickedFormSymptoms).map(
      (item: any) => {
        return {
          symptom: item.symptom_id,
          number_of_days: item.number_of_days,
        };
      }
    );
    const idsToRemove = differencesA.map((item: any) => {
      const foundItem = formSymptoms.find(
        (formSymptom: any) => formSymptom.symptom_id === item.symptom_id
      );
      return foundItem.id;
    });

    // console.log(pickedFormSymptoms, pickedFields, differencesA)
    // console.log(formSymptoms, fields);
    // console.log(symptomsToAdd, idsToRemove);

    if (idsToRemove.length > 0) {
      await dispatch(deleteConsultSymptom(consultId, idsToRemove));
    }
    if (symptomsToAdd.length > 0) {
      await dispatch(
        addConsultSymptoms(consultId, { symptoms: symptomsToAdd })
      );
    }
    setIsSymptomsModalVisible(false);
  };

  return (
    <Modal
      title={"Symptoms"}
      visible={isSymptomsModalVisible}
      centered
      cancelText="Cancel"
      footer={null}
      width={800}
      onCancel={() => setIsSymptomsModalVisible(false)}
      className="medications-modal main-modal"
    >
      <SymptomsModalWrapper>
        <SymptomsModalBodyWrapper>
          <Row justify="center">
            <Col span={22} className="medications-rhs">
              <Form
                name="dynamic_form_item"
                layout="vertical"
                requiredMark={false}
                initialValues={{ fields: data }}
                form={form}
                onFinish={handleSubmit}
              >
                <Form.List name="fields">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Row gutter={16} align="middle" wrap={false}>
                            <Col flex={1}>
                              <Row gutter={16} align="middle">
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    name={[index, "symptom_id"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Symptom is required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      formItem={{
                                        label: "Symptom",
                                        rules: [
                                          {
                                            required: true,
                                            message: "Please input the symptom",
                                          },
                                        ],
                                      }}
                                      showSearch
                                      suffixIcon={null}
                                      marginBottom={0}
                                      onChange={(value: any) =>
                                        updateFormObjects(value, index)
                                      }
                                      notFoundContent={
                                        isSymptomsLoading ? (
                                          <Spin size="small" />
                                        ) : null
                                      }
                                      optionFilterProp="children"
                                      filterOption={(input: any, option: any) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      placeholder="Select a symptom"
                                      label=""
                                      required
                                    >
                                      {symptoms.map((option, index) => {
                                        const isActive =
                                          currentFormObjectIds.some(
                                            (datum: any) => datum === option.id
                                          );
                                        return (
                                          <AntdSelect.Option
                                            key={index}
                                            value={option.id}
                                            disabled={isActive}
                                          >
                                            {option.symptom}
                                          </AntdSelect.Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    name={[index, "number_of_days"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "No. of days is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      formItem={{
                                        label: "Number of days",
                                      }}
                                      marginBottom={0}
                                      placeholder="Select number of days"
                                      type="number"
                                      label=""
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              {fields.length > 0 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Form.Item style={{ textAlign: "center" }}>
                        <Button
                          type="link"
                          className="btn-add"
                          onClick={() => add()}
                        >
                          <PlusOutlined />
                          Add another symptom
                        </Button>
                        {/* <Form.ErrorList errors={errors} /> */}
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    className="btn-submit"
                    htmlType="submit"
                    loading={
                      isAddConsultSymptomsLoading ||
                      isDeleteConsultSymptomLoading
                    }
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </SymptomsModalBodyWrapper>
      </SymptomsModalWrapper>
    </Modal>
  );
};

export default SymptomsModal;
