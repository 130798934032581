import UserIcon from "assets/icons/user-profile.svg";
import { Grid } from "antd";
import useToggle from "hooks/useToggle";
import ScreenLayout from "layouts/ScreenLayout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  consultSelector,
  fetchAllPatientConsults
} from "../../redux/reducers/consults";
import ChatPane from "./containers/ChatPane";
import Encounters from "./containers/Encounters";
import EncountersForm from "./containers/EncountersForm";
import PatientProfile from "./containers/PatientProfile";
import { ContentWrapper, PatientEncountersWrapper } from "./styles";

type Props = {
  isChatPaneVisible: boolean;
  isEncounterFormVisible: boolean;
  setIsChatPaneVisible: (val: boolean) => void;
  setIsEncounterFormVisible: (val: boolean) => void;
};

const PatientEncounters = ({
  isChatPaneVisible,
  setIsChatPaneVisible,
  setIsEncounterFormVisible,
  isEncounterFormVisible
}: Props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { patientId } = params as { patientId: string };
  const { patientConsults, isPatientConsultsLoading } =
    useSelector(consultSelector);

  const breakpoints = Grid.useBreakpoint();

  const [isViewNote, toggleViewNote] = useToggle();

  useEffect(() => {
    dispatch(fetchAllPatientConsults(patientId));
  }, [dispatch, patientId]);

  const patient_record = patientConsults;

  const { consults } = patientConsults || {};
  return (
    <PatientEncountersWrapper>
      <ContentWrapper>
        <ScreenLayout
          panePlacement='left'
          paneBodyWrapperStyle={{
            paddingTop: 56
          }}
          pane={
            <PatientProfile
              data={patient_record}
              loading={isPatientConsultsLoading}
            />
          }
          fabIcon={<img src={UserIcon} alt='userIcon' />}
        >
          <ChatPane
            visible={isChatPaneVisible && (breakpoints.lg || !isViewNote)}
            setIsChatPaneVisible={setIsChatPaneVisible}
            setIsEncounterFormVisible={setIsEncounterFormVisible}
            toggleViewNote={toggleViewNote}
          />
          <EncountersForm
            updateData={() => fetchAllPatientConsults(patientId)}
            visible={isEncounterFormVisible && (breakpoints.lg || isViewNote)}
            setIsEncounterFormVisible={setIsEncounterFormVisible}
            setIsChatPaneVisible={setIsChatPaneVisible}
            toggleViewNote={toggleViewNote}
          />
          <Encounters
            profile={patient_record}
            data={consults}
            loading={isPatientConsultsLoading}
            setIsChatPaneVisible={setIsChatPaneVisible}
            setIsEncounterFormVisible={setIsEncounterFormVisible}
          />
        </ScreenLayout>
      </ContentWrapper>
    </PatientEncountersWrapper>
  );
};

export default PatientEncounters;
