import styled, { css } from 'styled-components';


interface ButtonProps {
    type: string
}

const styles = (key: string) => {
    return (key === "primary")
        ? css`
            background: #0078E2;
            color: #fff;
            border-radius: 4px;
            font-size: 13px;
        `
        : (key === "secondary")
            ? css`
                height: 32px;
                border-radius: 3px;
                background: #f2f8fe;
                font-size: 10px;
                text-align: left;
                color: #0078e2;
            `
            : null
}

const ButtonWrapper = styled.div<ButtonProps>`
    display: inline-block;
    button.ant-btn {
        font-family: "DM Sans";
        font-weight: 400;
        border: none;
        line-height: 9px;
        text-align: center;
        ${(props) => props.type ? styles(props.type) : ''};
    }
`;

export { ButtonWrapper }