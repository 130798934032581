import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http from "../../utils/api";
import { message as AntdMessage } from "antd";

export interface MessageError {
  message: string;
}

export interface MessageState {
  messages: Array<any>;
  isMessagesLoading: boolean;
  isAddMessageLoading: boolean;
  isMessageSent: boolean;
  messagesError: MessageError;
  isAddMessageError: MessageError;
}

export const initialState: MessageState = {
  messages: [],
  isMessagesLoading: false,
  isAddMessageLoading: false,
  isMessageSent: false,
  messagesError: { message: "" },
  isAddMessageError: { message: "" }
};

export const messagesSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // FETCH ALL MESSAGES
    fetchMessagesLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isMessagesLoading = payload;
      state.isMessageSent = false;
    },
    fetchMessagesSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.messages = payload;
    },
    fetchMessagesFailed: (state, { payload }: PayloadAction<MessageError>) => {
      state.messagesError = payload;
      state.messages = [];
    },
    // ADD MESSAGE
    addMessageLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddMessageLoading = payload;
    },
    addMessageSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isMessageSent = payload;
    },
    addMessageFailed: (state, { payload }: PayloadAction<MessageError>) => {
      state.isAddMessageError = payload;
    }
  }
});

export const {
  fetchMessagesLoading,
  fetchMessagesSuccess,
  fetchMessagesFailed,
  addMessageLoading,
  addMessageSuccess,
  addMessageFailed
} = messagesSlice.actions;
export const messageSelector = (state: { messages: MessageState }) =>
  state.messages;
export default messagesSlice.reducer;

/** Actions */
export const fetchAllMessages =
  (consultId: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchMessagesLoading(true));
    await http
      .get(`/consults/${consultId}/messages`)
      .then((res) => {
        const allMessages = res?.data?.data;
        dispatch(fetchMessagesSuccess(allMessages));
      })
      .catch((err) => {
        const message = err?.response?.data?.message || "An error occurred";
        const _message = { message };
        dispatch(fetchMessagesFailed(_message));
        AntdMessage.error(message);
      });
    dispatch(fetchMessagesLoading(false));
  };

export const addMessage =
  (consultId: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(addMessageLoading(true));
    await http
      .post(`/consults/${consultId}/messages`, payload)
      .then((res) => {
        dispatch(addMessageSuccess(true));
        AntdMessage.success("Message Sent");
      })
      .catch((err) => {
        const _message = { message: err?.response?.data?.message };
        dispatch(addMessageFailed(_message));
        AntdMessage.error(err?.response?.data?.message);
      });
    dispatch(addMessageLoading(false));
  };
