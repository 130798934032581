import { MedicationsModalWrapper, MedicationsModalBodyWrapper } from "./styles";
import {
  Col,
  Modal,
  Row,
  Form,
  Select as AntdSelect,
  Spin,
  Divider
} from "antd";
import { useEffect, useState } from "react";
import Button from "../../../../component/Button";
import Select from "../../../../component/Select";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addConsultMedications,
  deleteConsultMedications,
  fetchAllMedications,
  medicationSelector
} from "../../../../redux/reducers/medications";
import Input from "../../../../component/Input";
import { Mixpanel } from "utils/mixpanel";

interface SignatureProp {
  code: string;
  description: string;
}

const signatures: SignatureProp[] = [
  {
    code: "OD",
    description: "Once daily/ Once a day"
  },
  {
    code: "BD / BID",
    description: "Twice Daily"
  },
  {
    code: "TDS",
    description: "Three times daily"
  },
  {
    code: "Nocte",
    description: "At night"
  },
  {
    code: "Stat",
    description: "Immediately/Now"
  },
  {
    code: "QDS/QID",
    description: "Four times a day"
  },
  {
    code: "q.d/qd",
    description: "Every day"
  },
  {
    code: "q 8 h",
    description: "Every 8 hours"
  },
  {
    code: "q.o.d. / qod",
    description: "Every other day/ alternate days"
  },
  {
    code: "PRN / p.r.n.",
    description: "As needed/ As patient needs"
  },
  {
    code: "PO",
    description: "Per oral/ Oral Medications"
  },
  {
    code: "PR",
    description: "Per rectum"
  },
  {
    code: "PV",
    description: "Per vaginum"
  },
  {
    code: "ud",
    description: "As directed/ as instructed"
  }
];

const MedicationsModal = (props: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [currentFormObjectIds, setCurrentFormObjectIds] = useState<any[]>([]);
  const {
    consultId,
    formMedications,
    formDiagnosis,
    formSymptoms,
    formInvestigations,
    isMedicationsModalVisible,
    setIsMedicationsModalVisible
  } = props;

  const {
    medications,
    isMedicationsLoading,
    isAddConsultMedicationsLoading,
    isDeleteConsultMedicationsLoading,
    medicationsError
  } = useSelector(medicationSelector);

  useEffect(() => {
    dispatch(fetchAllMedications());
  }, [dispatch]);

  useEffect(() => {
    if (formMedications.length === 0) {
      setData([
        {
          medication_id: "",
          medication: "",
          number_of_days: "",
          signature: "",
          dosage: ""
        }
      ]);
    } else {
      const formattedMedicationsData = formMedications.map((item: any) => {
        return {
          medication_id: item.medication_id,
          medication: item.name,
          number_of_days: item.number_of_days,
          signature: item.signature,
          dosage: item.dosage
        };
      });
      setData(formattedMedicationsData);
      setCurrentFormObjectIds(
        formattedMedicationsData.map((item: any) => item.medication_id)
      );
    }
  }, [formMedications]);

  const updateFormObjects = (value: any, index: number) => {
    const _currentFormObjectIds = [...currentFormObjectIds];
    _currentFormObjectIds[index] = value;
    setCurrentFormObjectIds(_currentFormObjectIds);
  };

  const jsonEqual = (a: any, b: any) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const deepComparison = (a: any, b: any) => {
    return a.filter(
      (item1: any) => !b.some((item2: any) => jsonEqual(item1, item2))
    );
  };

  const handleSubmit = async (values: any) => {
    const { fields } = values;

    const pickedFields = fields.map(
      ({ medication_id, number_of_days, signature, dosage }: any) => {
        return { medication_id, number_of_days, signature, dosage };
      }
    );
    const pickedFormMedications = formMedications.map(
      ({ medication_id, number_of_days, signature, dosage }: any) => {
        return { medication_id, number_of_days, signature, dosage };
      }
    );

    const differencesA = deepComparison(pickedFormMedications, pickedFields);
    const medicationsToAdd = deepComparison(
      pickedFields,
      pickedFormMedications
    ).map((item: any) => {
      return {
        medication: item.medication_id,
        number_of_days: item.number_of_days,
        signature: item.signature,
        dosage: item.dosage
      };
    });
    const idsToRemove = differencesA.map((item: any) => {
      const foundItem = formMedications.find(
        (formSymptom: any) => formSymptom.medication_id === item.medication_id
      );
      return foundItem.id;
    });

    if (idsToRemove.length > 0) {
      await dispatch(deleteConsultMedications(consultId, idsToRemove));
      Mixpanel.track("medications_removed", { medications: idsToRemove });
    }
    if (medicationsToAdd.length > 0) {
      await dispatch(
        addConsultMedications(consultId, { medications: medicationsToAdd })
      );
      Mixpanel.track("medications_added", { medications: medicationsToAdd });
    }

    setIsMedicationsModalVisible(false);
  };

  return (
    <Modal
      title={"Prescribe medications"}
      visible={isMedicationsModalVisible}
      centered
      cancelText='Cancel'
      onOk={handleSubmit}
      footer={null}
      width={800}
      onCancel={() => setIsMedicationsModalVisible(false)}
      className='medications-modal main-modal'
    >
      <MedicationsModalWrapper>
        <MedicationsModalBodyWrapper>
          <Row>
            <Col xs={0} sm={6} className='medications-lhs'>
              <div className='top'>
                <span>Diagnosis</span>
                {formDiagnosis.length > 0 ? (
                  formDiagnosis.map((item: any, idx: number) => {
                    return (
                      <p className='mb-23s' key={item?.id || idx}>
                        {item.condition}
                      </p>
                    );
                  })
                ) : (
                  <p>No diagnosis added yet</p>
                )}
                <div style={{ marginTop: "20px" }}></div>
                <span>Symptoms</span>
                {formSymptoms.length > 0 ? (
                  formSymptoms.map((item: any, idx: number) => {
                    return <p key={item?.id || idx}>{item.symptom}</p>;
                  })
                ) : (
                  <p>No symptom added yet</p>
                )}
                <div style={{ marginTop: "20px" }}></div>
                <span>Selected investigations</span>
                {formInvestigations.length > 0 ? (
                  formInvestigations.map((item: any, idx: number) => {
                    return <p key={item?.id || idx}>{item.name}</p>;
                  })
                ) : (
                  <p>No investigations added yet</p>
                )}
              </div>

              <div className='lab-recommendations'>
                <p>
                  <i className='lni lni-map-marker'></i> Recommend a pharmacy
                  (coming soon)
                </p>
              </div>
            </Col>
            <Col xs={24} sm={18} className='medications-rhs'>
              <Form
                name='dynamic_form_item'
                layout='vertical'
                requiredMark={false}
                initialValues={{ fields: data }}
                form={form}
                onFinish={handleSubmit}
              >
                <Form.List name='fields'>
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Row gutter={26} align='middle'>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={[index, "medication_id"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Medication is required"
                                  }
                                ]}
                              >
                                <Select
                                  formItem={{
                                    label: "Medication"
                                  }}
                                  showSearch
                                  suffixIcon={null}
                                  marginBottom={0}
                                  onChange={(value: any) =>
                                    updateFormObjects(value, index)
                                  }
                                  notFoundContent={
                                    isMedicationsLoading ? (
                                      <Spin size='small' />
                                    ) : null
                                  }
                                  optionFilterProp='children'
                                  filterOption={(input: any, option: any) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  placeholder='Select a medication'
                                  label=''
                                  required
                                >
                                  {medications.map((option, index) => {
                                    const isActive = currentFormObjectIds.some(
                                      (datum: any) => datum === option.id
                                    );
                                    return (
                                      <AntdSelect.Option
                                        key={index}
                                        value={option.id}
                                        disabled={isActive}
                                      >
                                        {option.name}
                                      </AntdSelect.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col flex={1}>
                              <Form.Item
                                name={[index, "dosage"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Dosage is required"
                                  }
                                ]}
                              >
                                <Input
                                  formItem={{
                                    label: "Dosage"
                                  }}
                                  marginBottom={0}
                                  placeholder='Enter dosage'
                                  type='text'
                                  label=''
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={[index, "signature"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Signature is required"
                                  }
                                ]}
                              >
                                <Select
                                  formItem={{
                                    label: "Signature"
                                  }}
                                  marginBottom={0}
                                  placeholder='Select a signature'
                                  label=''
                                >
                                  {signatures.map((option, index) => {
                                    return (
                                      <AntdSelect.Option
                                        key={index}
                                        value={option.description}
                                      >
                                        {option.code +
                                          ": " +
                                          option.description}
                                      </AntdSelect.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col flex={1}>
                              <Form.Item
                                name={[index, "number_of_days"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "No. of days is required"
                                  }
                                ]}
                              >
                                <Input
                                  formItem={{
                                    label: "No. of days"
                                  }}
                                  marginBottom={0}
                                  placeholder='Enter number of days'
                                  type='number'
                                  label=''
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              {fields.length > 0 ? (
                                <MinusCircleOutlined
                                  className='dynamic-delete-button'
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Col>
                          </Row>
                          <Divider />
                        </div>
                      ))}
                      <Form.Item style={{ textAlign: "center" }}>
                        <Button
                          type='link'
                          className='btn-add'
                          onClick={() => add()}
                        >
                          <PlusOutlined />
                          Add another medication
                        </Button>
                        {/* <Form.ErrorList errors={errors} /> */}
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type='primary'
                    className='btn-submit'
                    htmlType='submit'
                    loading={
                      isAddConsultMedicationsLoading ||
                      isDeleteConsultMedicationsLoading
                    }
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </MedicationsModalBodyWrapper>
      </MedicationsModalWrapper>
    </Modal>
  );
};

export default MedicationsModal;
