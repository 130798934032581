import { Row, Col, Spin, Empty, Pagination, Space, Grid } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

import Card from "../../../../component/Card";
import {
  consultSelector,
  fetchAllConsults
} from "../../../../redux/reducers/consults";
import AvatarImg from "../../../../component/Avatar";
import getAge from "../../../../utils/utils";
import Button from "../../../../component/Button";
import { PaginationWrapper } from "../../../Questions/containers/QuestionsTable/styles";
import { TableHeader } from "../../styles";
import {
  CompletedConsultsTableWrapper,
  CompletedConsultsTableBody,
  TableBody,
  TableRowItemWrapper
} from "./styles";

const CompletedConsultsTable = (props: any) => {
  const dispatch = useDispatch();
  const { consults, isConsultsLoading } = useSelector(consultSelector);
  const [pageNumber, setPageNumber] = useState(1);
  const DEFAULT_PAGE_SIZE = 10;

  const breakpoints = Grid.useBreakpoint();

  useEffect(() => {
    const query = "/user?status=ended";
    dispatch(fetchAllConsults(query));
  }, [dispatch]);

  const dataSource = consults;

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    window.scrollTo(0, 0);
  };

  const startPageCount = (pageNumber - 1) * DEFAULT_PAGE_SIZE;
  return (
    <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
      <CompletedConsultsTableWrapper>
        <TableHeader>
          <Row gutter={[16, 12]}>
            <Col md={6}>
              <p>Patient name</p>
            </Col>
            <Col md={4}>
              <p>Nature of encounter</p>
            </Col>
            <Col md={4}>
              <p>Partner</p>
            </Col>
            <Col md={4}>
              <p>Schedule</p>
            </Col>
          </Row>
        </TableHeader>
        <CompletedConsultsTableBody>
          <TableBody>
            {isConsultsLoading ? (
              <Row align='middle' justify='center' style={{ height: "150px" }}>
                <Spin size='default' />
              </Row>
            ) : dataSource.length > 0 ? (
              dataSource
                .slice(startPageCount, startPageCount + DEFAULT_PAGE_SIZE)
                .map(
                  ({
                    patient_record,
                    symptoms,
                    diagnosis,
                    form,
                    nature,
                    created_at,
                    partner,
                    ...record
                  }) => {
                    const { first_name, last_name, gender, dob } =
                      patient_record || {};
                    const { id } = patient_record || {};

                    const maxTextLength = breakpoints.md ? 50 : 50;

                    const natureOfEncounter = form?.name as string;

                    const businessName = (partner?.business_name ||
                      "-") as string;

                    return (
                      <TableRowItemWrapper>
                        <Row gutter={[16, 12]}>
                          <Col xs={24} md={6}>
                            <Space>
                              <AvatarImg
                                img=''
                                style={{ marginRight: 10 }}
                                fallbackText={`${first_name} ${last_name}`}
                              />
                              <div>
                                <p className='name'>
                                  {first_name} {last_name}
                                </p>
                                <span
                                  style={{ display: "block" }}
                                  className='text-capitalize'
                                >
                                  {gender}, {getAge(dob)}
                                </span>
                              </div>
                            </Space>
                          </Col>
                          <Col xs={12} md={4}>
                            {natureOfEncounter && (
                              <p className='tags'>
                                {natureOfEncounter.slice(0, maxTextLength)}
                                {natureOfEncounter.length > maxTextLength
                                  ? "..."
                                  : ""}
                              </p>
                            )}
                          </Col>
                          <Col xs={12} md={4}>
                            <p>
                              {businessName.slice(0, maxTextLength)}
                              {businessName.length > maxTextLength ? "..." : ""}
                            </p>
                          </Col>
                          <Col xs={24} md={4}>
                            <div className='schedule-text'>
                              <p>{moment(created_at).format("LL")}</p>
                              <span>
                                {moment(created_at).format("hh:mm a")}
                              </span>
                            </div>
                          </Col>
                          <Col xs={24} md={6}>
                            <Row justify={breakpoints.md ? "end" : "center"}>
                              <Col>
                                <Link
                                  to={`/encounters/${id}?consultId=${record.id}`}
                                >
                                  <Button type='primary' className='action'>
                                    <span style={{ color: "#fff", opacity: 1 }}>
                                      View encounter notes
                                    </span>
                                  </Button>
                                </Link>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </TableRowItemWrapper>
                    );
                  }
                )
            ) : (
              <Empty />
            )}
          </TableBody>
          {!isConsultsLoading && dataSource?.length > 0 && (
            <PaginationWrapper>
              <Pagination
                defaultCurrent={1}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                onChange={handlePageChange}
                total={dataSource.length || 0}
              />
            </PaginationWrapper>
          )}
        </CompletedConsultsTableBody>
      </CompletedConsultsTableWrapper>
    </Card>
  );
};

export default CompletedConsultsTable;
