import {
  NotificationSideBarMenuList,
  NotificationSideBarMenuListItem,
  NotificationSideBarWrapper
} from "./styles";
import Chats from "../../assets/icons/chat-unread.svg";

const NotificationSideBar = (props: any) => {
  const toggleChatPane = () => {
    props.setIsChatPaneVisible(!props.isChatPaneVisible);
    props.setIsEncounterFormVisible(!props.isChatPaneVisible);
  };

  const menu = [];

  props.isChatIconVisible &&
    menu.push({
      onClick: toggleChatPane,
      icon: Chats
    });

  return (
    <NotificationSideBarWrapper>
      <NotificationSideBarMenuList>
        {menu.map((item) => {
          const { onClick, icon } = item;
          return (
            <NotificationSideBarMenuListItem>
              <img onClick={onClick} src={icon} alt='icon' />
            </NotificationSideBarMenuListItem>
          );
        })}
      </NotificationSideBarMenuList>
    </NotificationSideBarWrapper>
  );
};

export default NotificationSideBar;
