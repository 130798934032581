import styled from "styled-components";
import { TOP_APPBAR_HEIGHT } from "constants/global";

const NavbarWrapper = styled.div`
  width: 100%;
  height: ${TOP_APPBAR_HEIGHT}px;
  position: fixed;
  padding: 45px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 100;
  box-shadow: 0px 1px 5px rgba(0, 120, 226, 0.25);
`;

const MenuLink = styled.div`
  display: flex;
  align-items: center;
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: right;
  color: #002e46;
  cursor: pointer;

  img {
    margin-left: 15px;
  }
`;

export const NavMenuList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 32px;
  margin-left: 80px;
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

interface MenuItemProps {
  active: boolean;
}

export const NavMenuListItem = styled.li<MenuItemProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  text-align: left;
  margin: 35px 0px;
  padding: 8px 12px;
  border-radius: 6px;
  border: ${(props) => (props.active ? "1px solid #0078E2" : 0)};

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${(props) => (props.active ? "#0078e2" : "#002e46")};
  }

  svg {
    margin-right: 6px;
  }
`;

export { NavbarWrapper, MenuLink };
