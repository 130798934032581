import { Col, Form, Modal, Row, Select } from "antd";
import Loader from "component/Loader";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../component/Button";
import {
  addConsultInvestgations,
  deleteConsultInvestgations,
  fetchAllInvestgations,
  investigationSelector
} from "../../../../redux/reducers/investigations";
import {
  InvestigationsModalBodyWrapper,
  InvestigationsModalWrapper
} from "./styles";
import { Mixpanel } from "utils/mixpanel";

const { Option } = Select;

const InvestigationsModal = (props: any) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});
  const [visibleItem, setVisibleItem] = useState("0");
  const [investigationGroups, setInvestigationGroups] = useState<any>({});
  const [investigationSelections, setInvestigationSelections] = useState<any>(
    {}
  );

  const { investigations, isInvestgationsLoading, addInvestgationsLoading } =
    useSelector(investigationSelector);

  const handleInvestigationSelections = (
    key: number,
    investigations: string
  ) => {
    const _investigationSelections = { ...investigationSelections };
    _investigationSelections[key] = investigations.split(",");
    setInvestigationSelections(_investigationSelections);
  };

  useEffect(() => {
    const _investigationSelections = { ...investigationSelections };
    Object.keys(data).map((dataKey) => {
      const key = dataKey.split("investigations")[1];
      const value = data[dataKey];
      _investigationSelections[key] = value;
    });
    setInvestigationSelections(_investigationSelections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {
    consultId,
    formDiagnosis,
    formSymptoms,
    formInvestigations,
    isInvestigationsModalVisible,
    setIsInvestigationsModalVisible
  } = props;

  useEffect(() => {
    dispatch(fetchAllInvestgations());
  }, [dispatch]);

  useEffect(() => {
    const investigationGroups = investigations.reduce((acc, item) => {
      const { id, name, category } = item;
      if (!acc.hasOwnProperty(category)) {
        acc[category] = [];
      }
      acc[category].push({ id, name });
      return acc;
    }, {});
    setInvestigationGroups(investigationGroups);
  }, [investigations]);

  useEffect(() => {
    if (Object.entries(investigationGroups).length > 0) {
      const formInvestigationsData: any = {};
      formInvestigations.forEach((item: any) => {
        const index: number = Object.keys(investigationGroups).findIndex(
          (group) => group === item.category
        );
        const correspondingFormIndex: string = `investigations${index}`;
        if (!formInvestigationsData.hasOwnProperty(correspondingFormIndex)) {
          formInvestigationsData[correspondingFormIndex] = [];
        }

        formInvestigationsData[correspondingFormIndex].push(
          item.investigation_id
        );
      });

      setData(formInvestigationsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInvestigations, investigationGroups]);

  const handleSubmit = async (values: any) => {
    const selectedInvestigations = Object.values(values).reduce(
      (acc: any[], item: any) => {
        if (item) {
          acc = acc.concat(item);
        }
        return acc;
      },
      []
    );

    const formattedPayload = selectedInvestigations.map((item) => {
      return {
        investigation: item
      };
    });

    const _formInvestigations = formInvestigations.map((item: any) => {
      return {
        investigation: item.investigation_id
      };
    });
    const differencesA = lodash.differenceBy(
      _formInvestigations,
      formattedPayload,
      "investigation"
    );
    const investigationsToAdd = lodash.differenceBy(
      formattedPayload,
      _formInvestigations,
      "investigation"
    );

    const _idsToRemove = differencesA.map((item: any) => item.investigation);
    const idsToRemove = formInvestigations
      .filter((item: any) => _idsToRemove.includes(item.investigation_id))
      .map((item: any) => item.id);

    if (investigationsToAdd.length > 0) {
      await dispatch(
        addConsultInvestgations(consultId, {
          investigations: investigationsToAdd
        })
      );
      Mixpanel.track("investigations_added", {
        investigations: JSON.stringify(investigationsToAdd)
      });
    }
    if (idsToRemove.length > 0) {
      await dispatch(deleteConsultInvestgations(consultId, idsToRemove));
      Mixpanel.track("investigations_removed", {
        investigations: JSON.stringify(idsToRemove)
      });
    }
    setIsInvestigationsModalVisible(false);
  };

  return (
    <Modal
      title={"Request Investigation"}
      visible={isInvestigationsModalVisible}
      centered
      cancelText='Cancel'
      onOk={handleSubmit}
      footer={null}
      width={800}
      onCancel={() => setIsInvestigationsModalVisible(false)}
      className='investigations-modal main-modal'
    >
      <InvestigationsModalWrapper>
        <InvestigationsModalBodyWrapper>
          <Form
            name='dynamic_form_item'
            layout='vertical'
            onFinish={handleSubmit}
            initialValues={data}
          >
            <Row>
              <Col xs={0} sm={6} className='investigations-lhs'>
                <div className='top'>
                  <span>Diagnosis</span>
                  {formDiagnosis.length > 0 ? (
                    formDiagnosis.map((item: any) => {
                      return <p className='mb-23s'>{item.condition}</p>;
                    })
                  ) : (
                    <p>No diagnosis added yet</p>
                  )}
                  <div style={{ marginTop: "20px" }}></div>
                  <span>Symptoms</span>
                  {formSymptoms.length > 0 ? (
                    formSymptoms.map((item: any) => {
                      return <p>{item.symptom}</p>;
                    })
                  ) : (
                    <p>No symptom added yet</p>
                  )}
                </div>

                <div className='lab-recommendations'>
                  <p>
                    <i className='lni lni-map-marker'></i> Recommend a lab
                    (coming soon)
                  </p>
                </div>
              </Col>
              <Col
                xs={24}
                sm={18}
                className='investigations-rhs'
                style={{ padding: 32 }}
              >
                <Row gutter={20}>
                  {isInvestgationsLoading ? (
                    <Loader />
                  ) : (
                    Object.keys(investigationGroups).map(
                      (category: any, index: number) => {
                        const values = investigationGroups[category];
                        return (
                          <Col span={24} className='investigation-list-item'>
                            <div
                              className={`investigation-list-item-header ${
                                visibleItem === index.toString()
                                  ? "show"
                                  : "hidden"
                              }`}
                              onClick={() =>
                                setVisibleItem(
                                  index.toString() === visibleItem
                                    ? ""
                                    : `${index}`
                                )
                              }
                            >
                              {category} (
                              {investigationSelections[index]?.length || 0})
                              <span>
                                <i className='lni lni-chevron-down'></i>
                              </span>
                            </div>
                            <div
                              className={`investigation-list-item-body ${
                                visibleItem === index.toString()
                                  ? "show"
                                  : "hidden"
                              }`}
                            >
                              <Form.Item name={`investigations${index}`}>
                                <Select
                                  placeholder='Select investigations from this category'
                                  style={{ width: "100%", marginBottom: 10 }}
                                  mode='multiple'
                                  onChange={(values) =>
                                    handleInvestigationSelections(
                                      index,
                                      values.toString()
                                    )
                                  }
                                  allowClear
                                >
                                  {values?.map(({ id, name }: any) => (
                                    <Option value={id}>{name}</Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                        );
                      }
                    )
                  )}
                </Row>
                <Row justify='center'>
                  {addInvestgationsLoading ? (
                    <Button type='primary' className='btn-submit'>
                      Submitting...
                    </Button>
                  ) : (
                    <Button
                      htmlType='submit'
                      type='primary'
                      className='btn-submit'
                    >
                      Submit
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          </Form>
        </InvestigationsModalBodyWrapper>
      </InvestigationsModalWrapper>
    </Modal>
  );
};

export default InvestigationsModal;
