import { Select as AntdSelect, Form } from "antd"
import { SelectWrapper } from "./styles";

const Select = (props: any) => {
    const { formItem, marginBottom, ...rest } = props;
    return (
        <SelectWrapper marginBottom={marginBottom}>
            <Form.Item {...formItem}>
                <AntdSelect {...rest}>
                    {rest.children}
                </AntdSelect>
            </Form.Item>
        </SelectWrapper>
    )
}

export default Select;