import { isNotificationSupported } from "./utils";

export async function triggerPushNotification(
  title: string,
  message: string,
  redirect: string
) {
  if ("serviceWorker" in navigator && isNotificationSupported()) {
    Notification.requestPermission()
      .then(async () => {
        try {
          const register = await navigator.serviceWorker.register(
            `${process.env.PUBLIC_URL}/service-worker.js`,
            { scope: "/" }
          );

          const subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              process.env.REACT_APP_PUBLIC_VAPID_KEY!
            )
          });

          await fetch(
            `${process.env.REACT_APP_CONNECT_API_BASE_URL}/subscribe`,
            {
              method: "POST",
              body: JSON.stringify({
                subscription,
                payload: {
                  title,
                  body: message,
                  redirect
                }
              }),
              headers: {
                "Content-Type": "application/json"
              }
            }
          );
        } catch (err) {
          console.log(err);
        }
        console.log("Notification permissions granted");
      })
      .catch((err) => err);
  } else {
    console.error("Service workers not supported in this browser");
  }
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
