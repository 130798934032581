import styled from "styled-components";

export const Sidebar = styled.aside`
  height: 100%;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 11.6615px rgba(0, 0, 0, 0.1);
  padding: 32px 0;
  width: calc(100% - 57px);

  @media (min-width: 768px) {
    max-width: 280px;
  }

  @media (min-width: 1200px) {
    min-width: 350px;
  }

  h2 {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgb(78, 102, 126);
    margin: 0 23px 12px;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (min-width: 1024px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .search-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0 23px;
    margin-bottom: 0px;

    @media (min-width: 1024px) {
      margin-bottom: 8px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 24px;
    }

    input {
      background: #ffffff;
      border-radius: 8px;
      height: 42px;
      font-size: 10px;

      @media (min-width: 768px) {
        font-size: 12px;
      }

      @media (min-width: 1200px) {
        font-size: 16px;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
    }
  }
`;

export const LinksWrapper = styled.div`
  height: calc(100vh - 300px);
  overflow-y: auto;
  padding: 16px 23px 26px;
  box-shadow: rgba(0, 120, 226, 0.25) 0px 1px 2px;

  .loading-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-consults {
    margin-top: 32px;
    text-align: center;
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
  }

  .consults-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 12px;

    .single-consult {
      background: #ffffff;
      border: 1px solid #cbd2e0;
      border-radius: 8px;
      padding: 12px;
      position: relative;
      cursor: pointer;
      transition: all 0.25s ease;

      &:hover {
        border: 1px solid #cbd2e0;
        box-shadow: rgba(0, 120, 226, 0.25) 0px 1px 5px;
      }

      &.active {
        border-color: #0faff0;
        box-shadow: rgba(0, 120, 226, 0.25) 0px 1px 5px;
      }

      .thread-active-indicator {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: rgb(242, 157, 157);
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
          display: flex;
          align-items: center;

          h5 {
            font-family: "DM Sans";
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            color: #000000;

            @media (min-width: 1024px) {
              font-size: 14px;
            }

            @media (min-width: 1200px) {
              font-size: 16px;
            }
          }
        }

        .date {
          h6 {
            font-family: "DM Sans";
            font-weight: 400;
            font-size: 10px;
            line-height: 120%;
            color: #5a6474;

            @media (min-width: 1024px) {
              font-size: 11px;
            }
          }
        }
      }

      .form {
        margin-top: 4px;
        margin-left: 40px;

        @media (min-width: 1024px) {
          margin-top: 8px;
          margin-left: 42px;
        }

        h4 {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #5a6474;
          margin-bottom: 5px;

          @media (min-width: 1024px) {
            font-size: 14px;
          }

          @media (min-width: 1200px) {
            font-size: 16px;
          }
        }

        h5 {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 120%;
          color: #5a6474;
          margin: 0;

          @media (min-width: 1024px) {
            font-size: 12px;
          }

          @media (min-width: 1200px) {
            font-size: 14px;
          }
        }
      }
    }
  }
`;

export const PaginationWrapper = styled.div`
  padding: 20px;
  text-align: right;
`;
