import {
  EncountersListItemWrapper,
  EncountersListItemHeaderWrapper,
  EncountersListItemBodyWrapper,
  SectionDividerWrapper
} from "./styles";
import { Col, Row, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory } from "react-router";

const EncountersListItem = (props: any) => {
  const history = useHistory();

  const {
    created_at,
    id,
    status,
    symptoms,
    provider,
    diagnosis,
    complaint_history,
    recommendations,
    investigations,
    medications,
    follow_up,
    ownConsult,
    examinations
  } = props.encounter || {};
  const { id: patient_id } = props.profile || {};

  const getProviderFullName = () => {
    const { first_name, last_name } = provider || {};
    return first_name ? `${first_name} ${last_name}` : "—";
  };

  const handleEditEncounterClick = () => {
    history.push(`/encounters/${patient_id}?consultId=${id}`);
    props.setIsChatPaneVisible(true);
    props.setIsEncounterFormVisible(true);
  };

  return (
    <EncountersListItemWrapper>
      <EncountersListItemHeaderWrapper>
        <Row align='middle' justify='space-between'>
          <Col>
            <p className='date'>{moment(created_at).format("LL")}</p>
          </Col>
          <Col>
            <p className='time'>{moment(created_at).format("HH:MM")}</p>
          </Col>
          <Col>
            {ownConsult ? (
              <p className='link' onClick={handleEditEncounterClick}>
                <EditOutlined style={{ marginRight: 5 }} />
                <b>Edit Note</b>
              </p>
            ) : (
              <Tooltip
                placement='bottomRight'
                title={
                  status === "ended"
                    ? "This consult has been ended, it cannot be edited again"
                    : "You don't have access to edit this consult"
                }
              >
                <p className='link-disabled'>
                  <EditOutlined style={{ marginRight: 5 }} />
                  <b>Edit Note</b>
                </p>
              </Tooltip>
            )}
          </Col>
        </Row>
      </EncountersListItemHeaderWrapper>
      <EncountersListItemBodyWrapper>
        <SectionDividerWrapper>
          <Row>
            <Col>
              <p>
                <b>Provider:</b> {getProviderFullName()}
              </p>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col>
              <p>
                <b>Status:</b>{" "}
                <span style={{ textTransform: "capitalize" }}>{status}</span>
              </p>
            </Col>
          </Row>
        </SectionDividerWrapper>
        <SectionDividerWrapper>
          <Row gutter={12}>
            <Col>
              <p>
                <b>Reasons for encounter</b>
              </p>

              {symptoms.length === 0 ? (
                <span>No symptoms yet</span>
              ) : (
                symptoms.map((item: any, idx: number) => {
                  return (
                    <span className='tag' key={item?.id || idx}>
                      {item?.symptom}
                    </span>
                  );
                })
              )}
            </Col>
          </Row>
        </SectionDividerWrapper>
        <SectionDividerWrapper>
          <Row gutter={12}>
            <Col>
              <p>
                <b>History of presenting complaints</b>
              </p>
              <p>{complaint_history || "—"}</p>
            </Col>
          </Row>
        </SectionDividerWrapper>

        <SectionDividerWrapper>
          <Row gutter={12}>
            <Col>
              <p>
                <b>Examination findings</b>
              </p>
            </Col>
          </Row>
          {examinations.length > 0 ? (
            examinations.map((examination: any, idx: number) => {
              return (
                <Row gutter={12} key={examination?.id || idx}>
                  <Col span={8}>
                    <p style={{ wordBreak: "break-all" }}>
                      {examination?.name}
                    </p>
                  </Col>
                  <Col span={16}>
                    <p>{examination?.comment}</p>
                  </Col>
                </Row>
              );
            })
          ) : (
            <p>No exam findings yet</p>
          )}
        </SectionDividerWrapper>

        <SectionDividerWrapper>
          <Row gutter={12}>
            <Col span={24}>
              <p>
                <b>Diagnosis:</b>
              </p>
            </Col>
            <Col span={24}>
              {diagnosis.length === 0 ? (
                <span>No diagnosis yet</span>
              ) : (
                diagnosis.map((item: any, idx: number) => {
                  return (
                    <span className='tag' key={item?.id || idx}>
                      {item?.condition}
                    </span>
                  );
                })
              )}
            </Col>
          </Row>
        </SectionDividerWrapper>

        <SectionDividerWrapper>
          <Row gutter={12}>
            <Col span={24}>
              <p>
                <b>Medications:</b>
              </p>
            </Col>
            <Col span={24}>
              {medications.length === 0 ? (
                <span>No medications yet</span>
              ) : (
                medications.map((item: any, idx: number) => {
                  return (
                    <span className='tag' key={item?.id || idx}>
                      <span style={{ fontWeight: "bold" }}>
                        {item?.name} {item?.dosage && `(${item?.dosage})`}
                      </span>{" "}
                      for{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {item?.number_of_days} days
                      </span>
                    </span>
                  );
                })
              )}
            </Col>
          </Row>
        </SectionDividerWrapper>

        <SectionDividerWrapper>
          <Row gutter={12}>
            <Col span={24}>
              <p>
                <b>Investigations:</b>
              </p>
            </Col>
            <Col span={24}>
              {investigations.map((item: any, idx: number) => {
                return <p key={item?.id || idx}>{item.name}</p>;
              })}
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <p>
                <b>Recommendations:</b>
              </p>
            </Col>
            <Col span={24}>
              {recommendations?.map((item: any, idx: number) => {
                return <p key={item?.id || idx}>{item.plan}</p>;
              })}
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <p>
                <b>Follow up:</b>
              </p>
            </Col>
            <Col span={16}>
              <p>{follow_up ? moment(follow_up).format("LL") : "—"}</p>
            </Col>
          </Row>
        </SectionDividerWrapper>
      </EncountersListItemBodyWrapper>
    </EncountersListItemWrapper>
  );
};

export default EncountersListItem;
