import styled from "styled-components";
import Button from "common/Button";
import { Col } from "antd";

export const Container = styled.div`
  height: 100%;
  /* padding: 16px; */
`;

export const PaneFabToggle = styled(Button)`
  position: fixed;
  /* bottom: calc(100% - 50%);
  right: 0px;
  border-radius: 20px 0px 0px 20px;
  border-right: none; */
  box-shadow: ${({ theme }) => theme.shadows[16]};
  /* z-index: ${({ theme }) => theme.zIndex.modal + 1}; */
`;

export const Pane = styled(Col)`
  height: 100%;
  overflow: auto;
`;

export const Content = styled(Col)`
  height: 100%;
  overflow: auto;
`;
